export default {
	'this-survey-does-not-exist': 'За съжаление тази анкета не съществува.',
	'survey-closed': 'За съжаление тази анкета вече е затворена.',
	'survey-no-products': 'Тази анкета няма продукти.',
	'survey-no-products-summary':
		'Ако сте собственик на тази анкета, създайте продукти в таблото за управление, за да продължите.',
	'survey-completed': 'Вече сте попълнили тази анкета.',
	'summary-placeholder': 'Ако смятате, че това е грешка, моля, свържете се с лицето, което Ви е изпратило този линк.',
	'gone-wrong': 'Хммм, нещо не е наред.',
	'try-again': 'Опитайте отново или се върнете по-късно.',
	'thank-you': 'Благодарим Ви',
	'thank-you-summary': 'Анкетата е попълнена и Вашите резултати са изпратени.',
	'thank-you-summary-redirect': 'Ще бъдете пренасочени след няколко секунди.',
	'page-not-found': 'Нещо не е наред',
	'page-not-found-placeholder': 'Опа, повреден линк ',
	'tap-your-favorite': 'Кликнете върху отметката на Вашето любимо',
	or: 'или',
	'get-started-with-questions': 'Няколко въпроса за начало',
	continue: 'Продължете',
	retry: 'Опитайте отново',
	'tell-us-how-you-feel-about-this-product': 'Кажете ни какво мислите за този продукт',
	begin: 'Начало',
	'how-would-you-describe-this-product-to-a-friend': 'Как бихте описали този продукт на приятел?',
	'type-your-response-here': 'Въведете своя отговор тук...',
	'sum-of-total-characters': '${SUM} от ${TOTAL} символи',
	edit: 'Редактирайте',
	remove: 'Премахнете',
	'how-do-you-feel-about-this-part': 'Какво мислите за тази част?',
	'tap-on-an-area-and-choose-an-emoji': 'Кликнете върху област и изберете емотикон',
	'im-finished': 'Готово',
	maintenance: 'Поддръжка',
	'maintenance-mode': 'Upsiide в момента се поддържа. Моля, посетете ни пак скоро. ',
	'please-specify': 'Моля, пояснете',
	'monadic-split-statement':
		'На следващия екран ще Ви покажем идея. Моля, прегледайте я внимателно, тъй като ще Ви задаваме въпроси. ',
	disapproval: 'Неодобрение',
	anger: 'Гняв',
	sad: 'Тъга',
	joy: 'Радост',
	love: 'Любов',
	trust: 'Доверие',
	hope: 'Надежда',
	proud: 'Гордост',
	amazed: 'Изумление',
	surprised: 'Изненада',
	guilt: 'Вина',
	curious: 'Любопитство',
	jealous: 'Ревност',
	anticipation: 'Очакване',
	optimistic: 'Оптимизъм',
	anxious: 'Тревожност',
	fear: 'Страх',
	despair: 'Отчаяние',
	disbelief: 'Обезверение',
	cynicism: 'Цинизъм',
	regret: 'Съжаление',
	disgust: 'Отвращение',
	annoyed: 'Досада',
	aggressive: 'Агресивност',
	shocked: 'Шок',
	choose: 'Изберете {{amount}}',
	'up-to': 'Изберете до {{amount}}',
	unlimited: 'Изберете колкото желаете',
	'single-select': 'Избери едно',
	number: 'Въведете число',
	'number-between-min-max': 'Въведете число между {{min}} и {{max}}',
	'number-min': 'Въведете число равно на {{min}} или по-голямо',
	'number-max': 'Въведете число равно на {{max}} или по-малко',
	'open-ended-pledge':
		'Тази анкета съдържа въпроси, на които ще бъдете помолени да отговорите с Ваши думи. Вашите отговори ще бъдат автоматично проверени, за да се уверим че отговарят на нашите изисквания за качество. Съгласни ли сте да продължим?  ',
	'open-ended-pledge-accept': 'Да, съгласен(на) съм ',
	'open-ended-pledge-decline': 'Не, не съм съгласен(на)',
	'rh-1-label': 'Кое от следните не е спорт?',
	'rh-1-o-1': 'Баскетбол',
	'rh-1-o-2': 'Тенис',
	'rh-1-o-3': 'Бисквита',
	'rh-1-o-4': 'Хокей',
	'rh-2-label': 'Колко е 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Кое от следните е плод?',
	'rh-3-o-1': 'Мляко',
	'rh-3-o-2': 'Шоколад',
	'rh-3-o-3': 'Яйце',
	'rh-3-o-4': 'Ябълка',
	'open-end-max-length': 'Съжаляваме, въведеният отговор е твърде дълъг',
	'heatmap-click-image': `Кликнете върху изображението, за да добавите коментар`,
	'heatmap-click-image-no-comments': `Кликнете на произволно място върху изображението`,
	'heatmap-click-image-product-card': `Кликнете върху картата, за да добавите коментар`,
	'heatmap-click-image-product-card--no-comment': `Кликнете на произволно място върху картата`,
	'heatmap-type-comment': `Въведете коментар`,
	cancel: `Отмяна`,
	skip: 'Пропускане',
	ranked: 'Класиран',
	top: 'Връх',
	'tap-or-drag': 'Докоснете или плъзнете и пуснете, за да класирате',
	unranked: 'некласиран',
	reset: 'Нулиране',
	'no-more-options': 'Няма повече опции за класиране',
	'rank-list-full': 'Списъкът с ранглисти е пълен, отменете класирането на един от вашите избори',
};
