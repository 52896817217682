export default {
	'this-survey-does-not-exist': 'Xin lỗi, câu điều tra này không tồn tại.',
	'survey-closed': 'Xin lỗi, câu điều tra này đã kết thúc.',
	'survey-no-products': 'Câu điều tra này không bao gồm sản phẩm',
	'survey-no-products-summary':
		'Nếu bạn là người sở hữu và tổ chức cuộc điều tra này, xin hãy tăng thêm sản phẩm này trong trang thao tác ',
	'survey-completed': 'Bạn đã hoàn thành cuộc điều tra này',
	'summary-placeholder':
		'Nếu bạn cảm thấy cuộc điều tra có lỗi sai, xin bạn hãy liên hệ với người gửi phiếu điều tra.',
	'gone-wrong': 'Vâng, đã xuất hiện chút lỗi sai.',
	'try-again': 'Mời bạn hãy thử lại lần nữa, hoặc lát nữa quay trở lại.',
	'thank-you': 'Xin cảm ơn.',
	'thank-you-summary': 'Phiếu điều tra đã hoàn thành. Đáp án của bạn đã giao nộp.',
	'thank-you-summary-redirect': 'Trang của bạn sẽ dịch chuyển.',
	'page-not-found': 'Xuất hiện một chút lỗi sai.',
	'page-not-found-placeholder': 'Ừ, kết nối trang web bị hỏng rồi.',
	'tap-your-favorite': 'Nhấp“✔”để lựa chọn cái mà bạn thích nhất.',
	or: 'Hoặc',
	'get-started-with-questions': 'Chúng ta hãy bắt đầu bằng mấy câu điều tra.',
	continue: 'Xin hãy tiếp tục.',
	retry: 'Xin hãy thử lại lần nữa.',
	'tell-us-how-you-feel-about-this-product': 'Hãy cho chúng tôi cảm nhận của bạn về sản phẩm này',
	begin: 'Bắt đầu',
	'how-would-you-describe-this-product-to-a-friend': 'Làm thế nào bạn sẽ mô tả sản phẩm này cho một người bạn?',
	'type-your-response-here': 'Nhập câu trả lời của bạn vào đây',
	'sum-of-total-characters': '${SUM} trên ${TOTAL} ký tự',
	edit: 'Biên tập',
	remove: 'Tẩy',
	'how-do-you-feel-about-this-part': 'Bạn cảm thấy thế nào về phần này?',
	'tap-on-an-area-and-choose-an-emoji': 'Chạm vào một khu vực và chọn biểu tượng cảm xúc',
	'im-finished': 'Tôi đã hoàn thành',
	maintenance: 'Maintenance',
	'maintenance-mode': 'Upsiide is currently undergoing maintenance. Please check back soon.',
	'please-specify': 'Xin hãy chỉ ra cụ thể',
	'monadic-split-statement':
		'Trên màn hình tiếp theo, chúng tôi sẽ hiển thị cho quý vị xem một ý tưởng. Vui lòng xem kỹ vì chúng tôi sẽ có câu hỏi về nó.',
	disapproval: 'Phản đối',
	anger: 'Giận',
	sad: 'Buồn',
	joy: 'Vui',
	love: 'Yêu',
	trust: 'Tin tưởng',
	hope: 'Hy vọng',
	proud: 'Tự hào',
	amazed: 'Sửng sốt',
	surprised: 'Ngạc nhiên',
	guilt: 'Tội lỗi',
	curious: 'Tò mò',
	jealous: 'Ghen tị',
	anticipation: 'Mong chờ',
	optimistic: 'Lạc quan',
	anxious: 'Lo lắng',
	fear: 'Sợ hãi',
	despair: 'Thất vọng',
	disbelief: 'Hoài nghi',
	cynicism: 'Ngờ vực',
	regret: 'Hối tiếc',
	disgust: 'Ghê tởm',
	annoyed: 'Bực mình',
	aggressive: 'Quyết liệt',
	shocked: 'Bị sốc',
	choose: 'Chọn {{amount}}',
	'up-to': 'Chọn tối đa {{amount}}',
	unlimited: 'Chọn bao nhiêu lựa chọn tùy thích',
	'single-select': 'Chọn một',
	number: 'Nhập một số',
	'number-between-min-max': 'Nhập một số từ {{min}} đến {{max}}',
	'number-min': 'Nhập một số từ {{min}} trở lên',
	'number-max': 'Nhập một số từ {{max}} trở xuống',
	'open-ended-pledge':
		'Khảo sát này bao gồm các câu hỏi mà bạn sẽ được yêu cầu trả lời bằng từ ngữ riêng của mình. Các câu trả lời của bạn sẽ được kiểm tra tự động để đảm bảo chúng đáp ứng các yêu cầu chất lượng của chúng tôi. Bạn có đồng ý tiếp tục? ',
	'open-ended-pledge-accept': 'Có, tôi đồng ý',
	'open-ended-pledge-decline': 'Không, tôi không đồng ý',
	'rh-1-label': 'Đáp án nào sau đây không phải là môn thể thao?',
	'rh-1-o-1': 'Bóng rổ',
	'rh-1-o-2': 'Quần vợt',
	'rh-1-o-3': 'Cookie',
	'rh-1-o-4': 'Khúc côn cầu',
	'rh-2-label': '2 + 3 bằng mấy?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Đáp án nào sau đây là một loại trái cây?”',
	'rh-3-o-1': 'Sữa',
	'rh-3-o-2': 'Sô cô la',
	'rh-3-o-3': 'Trứng',
	'rh-3-o-4': 'Táo',
	'open-end-max-length': 'Xin lỗi, câu trả lời đã nhập quá dài',
	'heatmap-click-image': 'Nhấp vào hình ảnh để thêm nhận định',
	'heatmap-click-image-no-comments': 'Nhấp vào bất kỳ vị trí nào trên hình ảnh',
	'heatmap-click-image-product-card': 'Nhấp vào thẻ để thêm nhận định ',
	'heatmap-click-image-product-card--no-comment': 'Nhấp vào bất kỳ vị trí nào trên thẻ',
	'heatmap-type-comment': 'Nhập nhận định',
	cancel: 'Hủy',
	skip: 'Nhảy',
	ranked: 'Được xếp hạng',
	top: 'ở trên',
	'tap-or-drag': 'Nhấn hoặc kéo và thả để xếp hạng',
	unranked: 'Không được xếp hạng',
	reset: 'Cài lại',
	'no-more-options': 'Không còn lựa chọn nào để xếp hạng',
	'rank-list-full': 'Danh sách xếp hạng đã đầy, bỏ xếp hạng một trong những lựa chọn của bạn',
};
