import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import { getAssetVariationUrl } from 'src/utilities/misc';
import VideoPlayer from 'src/components/elements/VideoPlayer';
import QuestionLabel from '../../elements/QuestionLabel';
import ImageZoom from 'src/components/new/ImageZoom';

import './styles.scss';

const className = 'asset-with-text';
const el = name => cn(className, name);

const animation = {
	start: { y: 50, opacity: 0 },
	completed: { y: 0, opacity: 1 },
};

const AssetWithText = ({
	pose,
	title,
	summary,
	summaryIcon,
	asset = null,
	imageAlignment = null,
	study,
	answers,
	setAssetPreviewModalUrl,
	productId,
	t,
}) => (
	<div className={className}>
		{asset && (
			<>
				{asset?.mediaType === 'video' ? (
					<VideoPlayer url={asset.url} />
				) : (
					<motion.div variants={animation} initial="start" animate={pose} className={`${el('image')} ${imageAlignment ? `${imageAlignment}` : 'center'}`}>
						<ImageZoom>
							<img src={getAssetVariationUrl(asset, ['large', 'full', 'medium', 'thumbnail'])} alt={title} />
						</ImageZoom>
					</motion.div>
				)}
			</>
		)}

		<motion.div variants={animation} initial="start" animate={pose}>
			<h3 className={el('title')}>{title}</h3>
		</motion.div>
		{summaryIcon && (
			<motion.div variants={animation} initial="start" animate={pose}>
				<img src={summaryIcon} alt="" />
			</motion.div>
		)}

		<motion.div variants={animation} initial="start" animate={pose}>
			<div className={el('summary')}>
				<QuestionLabel
					study={study}
					label={summary}
					answers={answers}
					setAssetPreviewModalUrl={setAssetPreviewModalUrl}
					productId={productId}
					t={t}
				/>
			</div>
		</motion.div>
	</div>
);

AssetWithText.propTypes = {
	pose: PropTypes.any,
	title: PropTypes.string,
	summary: PropTypes.any,
	summaryIcon: PropTypes.any,
	asset: PropTypes.any,
	study: PropTypes.any,
	answers: PropTypes.any,
	setAssetPreviewModalUrl: PropTypes.func,
	productId: PropTypes.any,
	t: PropTypes.any,
	imageAlignment: PropTypes.string,
};

export default withTranslation('main')(AssetWithText);
