// React & Redux
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Localization
import { useTranslation } from 'react-i18next';

// Animation
import { motion } from 'framer-motion';

// BEM
import cn from 'src/utilities/bem-cn';

// Components
import Loader from 'src/components/elements/Loader';
import Message from 'src/components/shared/Message';

// Images
import imgUnknown from 'public/images/icon-thinking-unknown.png';
import imgComplete from 'public/images/icon-thinking-complete.png';
import imgClosed from 'public/images/icon-closed.png';
import imgInvalid from 'public/images/icon-thinking-broken.png';

import * as misc from 'src/utilities/misc';
import * as actions from '../../actions';

import './styles.scss';

const className = 'main-container';
const el = (name, mod) => cn(className, name, mod);

const loaderWrap = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
	},
};

const Start = ({studyId, uuid, rnid}) => {
	const [pose, setPose] = useState('start');
	const { state, currentSectionIndex } = useSelector(state => state.main);
	const { t } = useTranslation('main');
	const dispatch = useDispatch();

	// check if the link is redirected
	const token = misc.getUrlParameter('rid') || rnid;
	const hasRid = misc.getUrlParameter('rid');
	const hasAudienceUuid = misc.getUrlParameter('audienceUuid');
	let audienceUuid = misc.getUrlParameter('audienceUuid');
	let previewUuid = misc.getUrlParameter('previewUuid');
	let language = misc.getUrlParameter('language');

	useEffect(() => {
		setPose('completed');
		if (!(state instanceof Error)) {	
			!hasRid && dispatch(actions.fetchData(studyId, token, audienceUuid, previewUuid, currentSectionIndex, language, uuid));
			// fetching user data when user comes back from link routing
			(state !== "completed") && hasRid && hasAudienceUuid && dispatch(actions.getUserData({token, audienceUuid, studyId, uuid}));
		} 
	}, [currentSectionIndex, dispatch, token, studyId, audienceUuid, previewUuid, state]);

	if (state) {
		switch (state) {
			case 'not-found':
				return (
					<Message
						title={t('this-survey-does-not-exist')}
						image={imgInvalid}
						summary={t('summary-placeholder')}
					/>
				);

			case 'closed':
				return <Message title={t('survey-closed')} image={imgClosed} summary={t('summary-placeholder')} />;

			case 'no-products':
				return (
					<Message
						title={t('survey-no-products')}
						image={imgInvalid}
						summary={t('survey-no-products-summary')}
					/>
				);

			case 'completed':
				dispatch(actions.attemptToRedirect());
				return (
					<Message
						title={t('survey-completed')}
						image={imgComplete}
						summary={t('thank-you-summary-redirect')}
					/>
				);

			default:
				return <Message title={t('gone-wrong')} image={imgUnknown} summary={t('summary-placeholder')} />;
		}
	}

	return (
		<div className={el('loader')}>
			<motion.div animate={pose} variants={loaderWrap} initial="start">
				<Loader />
			</motion.div>
		</div>
	);
};

export default Start;
