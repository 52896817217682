import React, { useState } from 'react';

import './styles.scss';

const className = 'basic-button';

export default ({ label, loading, disabled, onClick, tabindex, dataTestId }) => (
	<button
		className={`${className} ${loading ? 'loading' : ''} ${disabled ? 'disabled' : ''}`}
		onClick={onClick}
		tabIndex={disabled || tabindex ? tabindex : 0}
		disabled={disabled}
		data-testid={dataTestId}
	>
		{!loading && label}
	</button>
);
