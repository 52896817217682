import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cn from 'src/utilities/bem-cn';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import DragIcon from '../../../../../../components/icons/DragIcon';
import UpArrow from '../../../../../../components/icons/UpArrow';
import DownArrow from '../../../../../../components/icons/DownArrow';
import RankedOptionCheckbox from '../../../../../../components/elements/RankedOptionCheckbox';
import RankedX from '../../../../../../components/icons/RankedX';

import './styles.scss';

const className = 'ranked-option';
const el = (name, mod) => cn(className, name, mod);

const RankedOption = ({
	option,
	renderImageOption,
	justDraggedOptionId,
	position = undefined,
	isOverlay = false,
	onIncreaseRanking = null,
	onDecreaseRanking = null,
	onRemoveRankedOption = null,
	unRankedOptionOnClick = null,
	overLimitErrorId = undefined,
	draggedItemIndex = null,
	swipedOptionId = null,
	setSwipedOptionId = null,
}) => {
	const { t } = useTranslation('main');
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: option?.id,
		data: { type: 'option' },
	});

	const isMobile = useIsMobile();

	const style = {
		transition,
		transform: CSS.Transform.toString(transform),
	};

	const [showRemoveButton, setShowRemoveButton] = useState(false);
	const [showSlideOutAnimation, setShowSlideOutAnimation] = useState(false);

	const [touchStart, setTouchStart] = useState(null);
	const [touchEnd, setTouchEnd] = useState(null);

	// the required distance between touchStart and touchEnd to be detected as a swipe
	const minSwipeDistance = 10;

	useEffect(() => {
		if (swipedOptionId !== option?.id && showRemoveButton) {
			setShowRemoveButton((prevState) => {
				if (prevState) {
					return !prevState;
				}
			});
		}
	}, [swipedOptionId, option?.id, showRemoveButton]);

	const onTouchStart = (e) => {
		setTouchEnd(null);
		setTouchStart(e.targetTouches[0].clientX);
		listeners.onTouchStart(e);
	};

	const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;
		if ((isLeftSwipe || isRightSwipe) && justDraggedOptionId !== option?.id) {
			if (isLeftSwipe && position) {
				setShowRemoveButton(true);
				setSwipedOptionId(option?.id);
			} else if (isRightSwipe && showRemoveButton) {
				setShowRemoveButton(false);
				setShowSlideOutAnimation(true);
				setTimeout(() => {
					setShowSlideOutAnimation(false);
				}, 250);
			}
		}
	};

	const renderPositionChange = useCallback(
		() => (
			<div
				className={`${el('position-change-arrows')} ${
					showRemoveButton && option?.id === swipedOptionId ? el('position-change-extended') : null
				}`}
			>
				{position !== 1 && (
					<div
						className={el('up-arrow')}
						onClick={() => {
							onIncreaseRanking(option);
						}}
					>
						<UpArrow />
					</div>
				)}
				<div
					className={el('down-arrow')}
					onClick={() => {
						onDecreaseRanking(option);
					}}
				>
					<DownArrow />
				</div>
				<div
					className={el('remove-option-button')}
					onClick={() => {
						onRemoveRankedOption(option);
						setShowRemoveButton(false);
						setShowSlideOutAnimation(true);
						setTimeout(() => {
							setShowSlideOutAnimation(false);
						}, 250);
					}}
				>
					<RankedX />
				</div>
			</div>
		),
		[
			position,
			onDecreaseRanking,
			onIncreaseRanking,
			option,
			showRemoveButton,
			onRemoveRankedOption,
			swipedOptionId,
		],
	);

	return (
		<div
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			className={`${className} ${isDragging ? el('is-dragging') : null} ${
				position !== undefined ? el('is-ranked') : null
			} ${isOverlay ? el('overlay') : null} ${
				showRemoveButton && position && option?.id === swipedOptionId ? el('extend-width') : null
			} ${showSlideOutAnimation ? el('exit-animation') : null} `}
			style={style}
			onClick={() => {
				if (unRankedOptionOnClick) {
					unRankedOptionOnClick(option);
				}
			}}
			onTouchStart={(e) => onTouchStart(e)}
			onTouchMove={onTouchMove}
			onTouchEnd={onTouchEnd}
			title={option?.label}
		>
			<div
				className={`${el('ranked-full-overlay')} ${
					isMobile && overLimitErrorId === option?.id ? el('overlay-active') : null
				}`}
			>
				{t('rank-list-full')}
			</div>
			<div className={el('left')}>
				<RankedOptionCheckbox position={isDragging && position ? draggedItemIndex + 1 : position} />
				{renderImageOption(option)}
				<p>{option?.label}</p>
			</div>
			{position === undefined ? <DragIcon /> : renderPositionChange()}
		</div>
	);
};

RankedOption.propTypes = {
	option: PropTypes.object,
	justDraggedOptionId: PropTypes.number,
	renderImageOption: PropTypes.func,
	position: PropTypes.number,
	isOverlay: PropTypes.bool,
	onIncreaseRanking: PropTypes.func,
	onDecreaseRanking: PropTypes.func,
	onRemoveRankedOption: PropTypes.func,
	unRankedOptionOnClick: PropTypes.func,
	overLimitErrorId: PropTypes.number,
	draggedItemIndex: PropTypes.number,
	swipedOptionId: PropTypes.number,
	setSwipedOptionId: PropTypes.func,
};

export default RankedOption;
