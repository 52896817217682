export default {
	'this-survey-does-not-exist': 'Maaf, survei ini tidak ada.',
	'survey-closed': 'Maaf, survei ini sudah ditutup.',
	'survey-no-products': 'Tidak ada produk di survei ini.',
	'survey-no-products-summary': 'Jika Anda pemilik survei ini, buat produk di dasbor untuk melanjutkan.',
	'survey-completed': 'Anda sudah menyelesaikan survei ini.',
	'summary-placeholder': 'Jika Anda yakin ini suatu kesalahan, harap hubungi orang yang mengirimi Anda tautan ini.',
	'gone-wrong': 'Hmm, tampaknya ada yang salah.',
	'try-again': 'Coba lagi atau kembali lagi nanti.',
	'thank-you': 'Terima kasih',
	'thank-you-summary': 'Survei selesai, dan hasil Anda sudah dikirim.',
	'thank-you-summary-redirect': 'Anda akan dialihkan dalam beberapa detik.',
	'page-not-found': 'Tampaknya ada yang salah',
	'page-not-found-placeholder': 'Ups, tautan rusak',
	'tap-your-favorite': 'Ketuk tanda centang di bagian favorit Anda',
	or: 'atau',
	'get-started-with-questions': 'Sejumlah pertanyaan untuk memulai',
	continue: 'Lanjutkan',
	retry: 'Coba lagi',
	'tell-us-how-you-feel-about-this-product': 'Beri tahu kami pendapat Anda tentang produk ini',
	begin: 'Mulai',
	'how-would-you-describe-this-product-to-a-friend': 'Bagaimana Anda menjelaskan produk ini kepada teman?',
	'type-your-response-here': 'Ketik tanggapan Anda di sini…',
	'sum-of-total-characters': '${SUM} dari ${TOTAL} karakter',
	edit: 'Edit',
	remove: 'Hapus',
	'how-do-you-feel-about-this-part': 'Bagaimana pendapat Anda tentang bagian ini?',
	'tap-on-an-area-and-choose-an-emoji': 'Ketuk di area dan pilih sebuah emoji',
	'im-finished': 'Saya sudah selesai',
	maintenance: 'Pemeliharaan',
	'maintenance-mode': 'Upsiide saat ini sedang menjalani pemeliharaan. Harap periksa kembali nanti.',
	'please-specify': 'Harap sebutkan',
	disapproval: 'Tidak setuju',
	anger: 'Marah',
	sad: 'Sedih',
	joy: 'Senang',
	love: 'Cinta',
	trust: 'Percaya',
	hope: 'Berharap',
	proud: 'Bangga',
	amazed: 'Kagum',
	surprised: 'Terkejut',
	guilt: 'Bersalah',
	curious: 'Penasaran',
	jealous: 'Cemburu',
	anticipation: 'Penantian',
	optimistic: 'Optimis',
	anxious: 'Gelisah',
	fear: 'Takut',
	despair: 'Putus asa',
	disbelief: 'Tidak percaya',
	cynicism: 'Sinis',
	regret: 'Menyesal',
	disgust: 'Menjijikkan',
	annoyed: 'Terganggu',
	aggressive: 'Agresif',
	shocked: 'Terkejut',
	choose: 'Pilih {{amount}}',
	'up-to': 'Pilih hingga {{amount}}',
	unlimited: 'Pilih sebanyak mungkin',
	'single-select': 'Pilih satu',
	number: 'Masukkan angka',
	'number-between-min-max': 'Masukkan angka antara {{min}} dan {{max}}',
	'number-min': 'Masukkan angka {{min}} atau lebih besar',
	'number-max': 'Masukkan angka {{max}} atau kurang',
	'open-ended-pledge':
		'Survei ini berisi pertanyaan di mana Anda akan diminta untuk menjawab dengan kata-kata Anda sendiri. Jawaban Anda akan otomatis diperiksa untuk memastikan jawaban memenuhi persyaratan kualitas kami. Apakah Anda setuju untuk melanjutkan? ',
	'open-ended-pledge-accept': 'Ya, saya setuju',
	'open-ended-pledge-decline': 'Tidak, saya tidak setuju',
	'rh-1-label': 'Manakah dari berikut ini yang bukan olahraga?',
	'rh-1-o-1': 'Basket',
	'rh-1-o-2': 'Tenis',
	'rh-1-o-3': 'Kue kering',
	'rh-1-o-4': 'Hoki',
	'rh-2-label': 'Berapa 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Manakah dari berikut ini yang merupakan buah?',
	'rh-3-o-1': 'Susu',
	'rh-3-o-2': 'Cokelat',
	'rh-3-o-3': 'Telur',
	'rh-3-o-4': 'Apel',
	'open-end-max-length': 'Maaf, jawapan yang dimasukkan terlalu panjang',
	'heatmap-click-image': `Klik gambar untuk menambahkan komentar`,
	'heatmap-click-image-no-comments': `Klik di mana saja pada gambar`,
	'heatmap-click-image-product-card': `Klik kartu untuk menambahkan komentar`,
	'heatmap-click-image-product-card--no-comment': `Klik di mana saja pada kartu`,
	'heatmap-type-comment': `Ketikkan komentar`,
	cancel: `Hapus`,
	skip: 'Melewati',
	ranked: 'Peringkat',
	top: 'Tertinggi',
	'tap-or-drag': 'Ketuk atau seret dan lepas untuk menentukan peringkat',
	unranked: 'Tidak memiliki peringkat',
	reset: 'Mengatur ulang',
	'no-more-options': 'Tidak ada lagi pilihan untuk menentukan peringkat',
	'rank-list-full': 'Daftar peringkat sudah penuh, unrank salah satu pilihan Anda',
};
