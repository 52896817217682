export default {
	'this-survey-does-not-exist': 'Het spijt ons, dit onderzoek bestaat niet.',
	'survey-closed': 'Het spijt ons, dit onderzoek is nu gesloten.',
	'survey-no-products': 'Dit onderzoek heeft geen producten.',
	'survey-no-products-summary':
		'Ben je de eigenaar van dit onderzoek, maak dan producten aan in het dashboard om door te gaan.',
	'survey-completed': 'Je hebt dit onderzoek al ingevuld.',
	'summary-placeholder':
		'Als je denkt dat dit een fout is, neem dan contact op met degene die jouw deze link heeft gestuurd.',
	'gone-wrong': 'Hm, er is iets mis.',
	'try-again': 'Probeer het nog eens of kom later terug.',
	'thank-you': 'Dank je',
	'thank-you-summary': 'Het onderzoek is compleet en je resultaten zijn ingediend.',
	'thank-you-summary-redirect': 'Je wordt over enkele seconden doorverwezen.',
	'page-not-found': 'Er is iets mis',
	'page-not-found-placeholder': 'Oeps, gebroken link',
	'tap-your-favorite': 'Klik op het vinkje op je favoriet',
	or: 'of',
	'get-started-with-questions': 'Enkele vragen om te beginnen',
	continue: 'Doorgaan',
	retry: 'Opnieuw proberen',
	'tell-us-how-you-feel-about-this-product': 'Laat ons weten wat je van dit product vindt',
	begin: 'Beginnen',
	'how-would-you-describe-this-product-to-a-friend': 'Hoe zou je dit product voor een vriend beschrijven?',
	'type-your-response-here': 'Typ hier je antwoord…',
	'sum-of-total-characters': '${SUM} van ${TOTAL} tekens',
	edit: 'Bewerken',
	remove: 'Verwijderen',
	'how-do-you-feel-about-this-part': 'Wat vind je van dit deel?',
	'tap-on-an-area-and-choose-an-emoji': 'Klik op een ruimte en kies een emoji',
	'im-finished': 'Ik ben klaar',
	maintenance: 'Onderhoud',
	'maintenance-mode': 'Upsiide is momenteel in onderhoud. Kom binnenkort weer terug.',
	'please-specify': 'Anders, namelijk:',
	'monadic-split-statement':
		'Op het volgende scherm laten we u een idee zien. Bekijk het zorgvuldig, want we stellen er later vragen over.',
	disapproval: 'Afkeuring',
	anger: 'Boosheid',
	sad: 'Verdrietig',
	joy: 'Plezier',
	love: 'Liefde',
	trust: 'Vertrouwen',
	hope: 'Hoop',
	proud: 'Trots',
	amazed: 'Verbaasd',
	surprised: 'Verrast',
	guilt: 'Schuld',
	curious: 'Nieuwsgierig',
	jealous: 'Jaloers',
	anticipation: 'Verwachtingsvol',
	optimistic: 'Optimistisch',
	anxious: 'Angstig',
	fear: 'Angst',
	despair: 'Wanhoop',
	disbelief: 'Ongeloof',
	cynicism: 'Cynisme',
	regret: 'Spijt',
	disgust: 'Afschuw',
	annoyed: 'Geïrriteerd',
	aggressive: 'Agressief',
	shocked: 'Geschokt',
	choose: 'Kies {{amount}}',
	'up-to': 'Kies maximaal {{amount}}',
	unlimited: 'Kies zoveel u wilt',
	'single-select': 'Kies een',
	number: 'Voer een getal in',
	'number-between-min-max': 'Voer een getal in tussen {{min}} en {{max}}',
	'number-min': 'Voer een getal in van {{min}} of hoger',
	'number-max': 'Voer een getal in van {{max}} of lager',
	'open-ended-pledge':
		'Deze enquête bevat vragen die u in uw eigen woorden moet beantwoorden. Uw antwoorden worden automatisch gecontroleerd om er zeker van te zijn dat ze aan onze kwaliteitseisen voldoen. Gaat u akkoord om verder te gaan? ',
	'open-ended-pledge-accept': 'Ja, ik ga akkoord',
	'open-ended-pledge-decline': 'Nee, ik ga niet akkoord',
	'rh-1-label': 'Welke van de volgende is geen sport?',
	'rh-1-o-1': 'Basketbal',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Cookie',
	'rh-1-o-4': 'Hockey',
	'rh-2-label': 'Wat is 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Welke van de volgende is fruit?',
	'rh-3-o-1': 'Melk',
	'rh-3-o-2': 'Chocolade',
	'rh-3-o-3': 'Ei',
	'rh-3-o-4': 'Appel',
	'open-end-max-length': 'Sorry, het ingevoerde antwoord is te lang',
	'heatmap-click-image': `Klik op de afbeelding om een commentaar toe te voegen`,
	'heatmap-click-image-no-comments': `Klik ergens op de afbeelding`,
	'heatmap-click-image-product-card': `Klik op de kaart om een commentaar toe te voegen`,
	'heatmap-click-image-product-card--no-comment': `Klik ergens op de kaart`,
	'heatmap-type-comment': `Typ een commentaar`,
	cancel: `Annuleer`,
	skip: 'Overslaan',
	ranked: 'Gerangschikt',
	top: 'Hoogste',
	'tap-or-drag': 'Tik of sleep en zet neer om te rangschikken',
	unranked: 'Niet gerangschikt',
	reset: 'Opnieuw zetten',
	'no-more-options': 'Geen opties meer om te rangschikken',
	'rank-list-full': 'De ranglijst is vol. Haal een van je keuzes uit de ranglijst',
};
