const prefix = 'main';

/*
 * Response ID Actions
 */

export const SET_RESPONSE_ID = `${prefix}/SET_RESPONSE_ID`;
export const setResponseId = responseId => ({
	type: SET_RESPONSE_ID,
	payload: { responseId },
});

export const SET_RESPONSE_RID = `${prefix}/SET_RESPONSE_RID`;
export const setResponseRID = RID => ({
	type: SET_RESPONSE_RID,
	payload: { RID },
});

export const TERMINATE_RESPONSE = `${prefix}/TERMINATE_RESPONSE`;
export const terminateResponse = () => ({
	type: TERMINATE_RESPONSE,
	payload: {},
});

/*
 * Sentiment Actions
 */

export const SET_SENTIMENT_TAGS = `${prefix}/SET_SENTIMENT_TAGS`;
export const setSentimentTags = tags => ({ type: SET_SENTIMENT_TAGS, payload: { tags } });

export const SET_SENTIMENT_TEXT = `${prefix}/SET_SENTIMENT_TEXT`;
export const setSentimentText = description => ({ type: SET_SENTIMENT_TEXT, payload: { description } });

/*
 * Data Actions
 */

export const FETCH_DATA = `${prefix}/FETCH_DATA`;
export const fetchData = (studyId, rnid, audienceUuid, previewUuid, currentSectionIndex, language, uuid) => ({
	type: FETCH_DATA,
	payload: { studyId, rnid, audienceUuid, previewUuid, currentSectionIndex, language, uuid },
});

export const PUSH_DATA = `${prefix}/PUSH_DATA`;
export const pushData = (force, audienceUuid) => ({ type: PUSH_DATA, payload: { force, audienceUuid } });

export const SET_DISQUALIFIED = `${prefix}/SET_DISQUALIFIED`;
export const setDisqualified = (answers, audienceUuid, reasonForDisqualification = null, submittedAnswersInQuotas = false) => ({
	type: SET_DISQUALIFIED,
	payload: { answers, audienceUuid, reasonForDisqualification, submittedAnswersInQuotas },
});

export const FETCH_DISTRIBUTED_SPLIT_PRODUCTS = `${prefix}/FETCH_DISTRIBUTED_SPLIT_PRODUCTS`;
export const fetchDistributedSplitProducts = sectionId => ({
	type: FETCH_DISTRIBUTED_SPLIT_PRODUCTS,
	payload: { sectionId },
});

export const SET_DISTRIBUTED_SPLIT_PRODUCTS_LOADING = `${prefix}/SET_DISTRIBUTED_SPLIT_PRODUCTS_LOADING`;
export const setDistributedSplitProductsLoading = distributedSplitProductsLoading => ({
	type: SET_DISTRIBUTED_SPLIT_PRODUCTS_LOADING,
	payload: { distributedSplitProductsLoading },
});

/*
 * State Actions
 */

export const SET_STATE = `${prefix}/SET_STATE`;
export const setState = state => ({ type: SET_STATE, payload: { state } });

export const SAVE_STATE = `${prefix}/SAVE_STATE`;
export const saveState = () => ({ type: SAVE_STATE });

export const LOAD_STATE = `${prefix}/LOAD_STATE`;
export const loadState = () => ({ type: LOAD_STATE });

export const REPLACE_STATE = `${prefix}/REPLACE_STATE`;
export const replaceState = state => ({
	type: REPLACE_STATE,
	payload: { state },
});

/*
 * Text AI Actions
 */

export const SET_TEXT_AI_PRODUCT_ID = `${prefix}/SET_TEXT_AI_PRODUCT_ID`;
export const setTextAiProductId = productUuids => ({ type: SET_TEXT_AI_PRODUCT_ID, payload: { productUuids } });

/*
 * Study Actions
 */

export const SET_STUDY = `${prefix}/SET_STUDY`;
export const setStudy = study => ({ type: SET_STUDY, payload: { study } });

/*
 * Answers/Results Actions
 */

export const SET_RESULTS = `${prefix}/SET_RESULTS`;
export const setResults = results => ({
	type: SET_RESULTS,
	payload: { results },
});

export const SET_ANSWERS = `${prefix}/SET_ANSWERS`;
export const setAnswers = answers => ({
	type: SET_ANSWERS,
	payload: { answers },
});

/*
 * App Navigation Actions
 */

export const ATTEMPT_TO_REDIRECT = `${prefix}/ATTEMPT_TO_REDIRECT`;
export const attemptToRedirect = () => ({ type: ATTEMPT_TO_REDIRECT });
// TODO: Consider if this can be totally replaced by SET_CURRENT_SECTION_INDEX
export const SET_STEP = `${prefix}/SET_STEP`;
export const setStep = step => ({ type: SET_STEP, payload: { step } });
// TODO: Double check how this is still being used
export const PROCEED = `${prefix}/PROCEED`;
export const proceed = (step, data) => ({
	type: PROCEED,
	payload: { step, data },
});

/*
 * Question module navigation
 */
export const SET_QUESTION_INDEX = `${prefix}/SET_QUESTION_INDEX`;
export const setQuestionIndex = questionIndex => ({
	type: SET_QUESTION_INDEX,
	payload: { questionIndex },
});

// This one finds the index of the question in it's own section
export const JUMP_TO_QUESTION = `${prefix}/JUMP_TO_QUESTION`;
export const jumpToQuestion = (questionId, sectionId) => ({
	type: JUMP_TO_QUESTION,
	payload: { questionId, sectionId },
});

/*
 * Sections and navigation
 */

export const JUMP_TO_SECTION = `${prefix}/JUMP_TO_SECTION`;
export const jumpToSection = sectionId => ({
	type: JUMP_TO_SECTION,
	payload: { sectionId },
});

export const SET_NEXT_SECTION = `${prefix}/SET_NEXT_SECTION`;
export const setNextSection = (skipTimeout = false) => ({
	type: SET_NEXT_SECTION,
	payload: { skipTimeout },
});

export const SET_CURRENT_SECTION_INDEX = `${prefix}/SET_CURRENT_SECTION_INDEX`;
export const setCurrentSectionIndex = currentSectionIndex => ({
	type: SET_CURRENT_SECTION_INDEX,
	payload: {
		currentSectionIndex,
	},
});

export const SET_SECTIONS = `${prefix}/SET_SECTIONS`;
export const setSections = sections => ({
	type: SET_SECTIONS,
	payload: {
		sections,
	},
});

export const SET_ASSET_PREVIEW_MODAL_URL = `${prefix}/SET_ASSET_PREVIEW_MODAL_URL`;
export const setAssetPreviewModalUrl = assetPreviewModalUrl => ({
	type: SET_ASSET_PREVIEW_MODAL_URL,
	payload: {
		assetPreviewModalUrl,
	},
});

export const SET_FOCUS_START_TIME = `${prefix}/SET_FOCUS_START_TIME`;
export const setInputFocusStartTime = time => ({
	type: SET_FOCUS_START_TIME,
	payload: { focusStartTime: time },
});

export const SET_FOCUS_END_TIME = `${prefix}/SET_FOCUS_END_TIME`;
export const setInputFocusEndTime = time => ({
	type: SET_FOCUS_END_TIME,
	payload: { focusEndTime: time },
});

/*
 * Serverless functions
 */

export const SAVE_DATA_TO_SERVERLESS = `${prefix}/SAVE_DATA_TO_SERVERLESS`;
export const saveUserData = payload => ({
	type: SAVE_DATA_TO_SERVERLESS,
	payload,
});

export const GET_USER_DATA = `${prefix}/GET_USER_DATA`;
export const getUserData = data => ({
	type: GET_USER_DATA,
	payload: data,
});

export const SET_QUALIFIERS = `${prefix}/SET_QUALIFIERS`;
export const setQualifiers = qualifiers => ({
	type: SET_QUALIFIERS,
	payload: { qualifiers },
});

export const VALIDATE_RESPONDENT = `${prefix}/VALIDATE_RESPONDENT`;
export const validateRespondent = (study, audienceUuid, testMode, rnid) => ({
	type: VALIDATE_RESPONDENT,
	payload: { study, audienceUuid, testMode, rnid },
});

export const SET_VALIDATE_RESPONDENT_LOADING = `${prefix}/SET_VALIDATE_RESPONDENT_LOADING`;
export const setValidateResponseLoading = (validateRespondentLoading) => ({
	type: SET_VALIDATE_RESPONDENT_LOADING,
	payload: { validateRespondentLoading },
});

/*
 * Replacing main store
 */

export const SET_MAIN_STORE = `${prefix}/SET_MAIN_STORE`;
export const setMainStore = main => ({
	type: SET_MAIN_STORE,
	payload: { main },
});

export const CHECK_QUOTAS = `${prefix}/CHECK_QUOTAS`
export const checkQuotas = payload => ({
	type: CHECK_QUOTAS,
	payload,
})