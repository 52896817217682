import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const GlobalScrollIndicator = ({ show, backgroundBlur }) => {
	return (
		<>
			{show && (
				<div className={`global-scroll-indicator ${backgroundBlur ? 'background-blur' : null}`}>
					<div className="global-scroll-indicator-icon-container">
						<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M7.5 0C7.77615 0 8 0.223858 8 0.5V16.67L12.44 12.22C12.5339 12.1253 12.6617 12.0721 12.795 12.0721C12.9283 12.0721 13.0561 12.1253 13.15 12.22L13.85 12.93C13.9447 13.0239 13.9979 13.1517 13.9979 13.285C13.9979 13.4183 13.9447 13.5461 13.85 13.64L7.72001 19.78C7.57949 19.9207 7.38885 19.9998 7.19 20H6.81C6.61159 19.9977 6.42173 19.9189 6.28 19.78L0.150005 13.64C0.0553486 13.5461 0.00210571 13.4183 0.00210571 13.285C0.00210571 13.1517 0.0553486 13.0239 0.150005 12.93L0.860005 12.22C0.952168 12.1259 1.07832 12.0729 1.21 12.0729C1.34169 12.0729 1.46784 12.1259 1.56 12.22L6 16.67V0.5C6 0.223858 6.22386 0 6.5 0H7.5Z"
								fill="#666666"
							/>
						</svg>
					</div>
				</div>
			)}
		</>
	);
};

GlobalScrollIndicator.propTypes = {
	show: PropTypes.bool,
	backgroundBlur: PropTypes.bool,
};

export default GlobalScrollIndicator;
