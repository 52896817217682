import axios from "axios";

const TOKEN_KEY = "UPSIIDE_USER_TOKEN";

/* take auth token from localStorage */
const token = JSON.parse(localStorage.getItem(TOKEN_KEY) || "null");

/* Update headers with auth token from storage */
if (token) {
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export default {
	...axios,
	/* Update the token */
	setToken: (token) => {
		// /* Update cookie */
		localStorage.getItem(TOKEN_KEY, JSON.stringify(token));
		/* Set in axios headers */
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	}
};
