import React from 'react';

export default () => (
	<svg
		version="1.1"
		id="Capa_1"
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 512 512"
	>
		<circle fill="#FFE17D" cx="256" cy="256" r="256" />
		<path
			fill="#AA7346"
			d="M256,381.935c-35.524,0-69.234-13.597-90.177-36.367c-3.863-4.194-3.589-10.726,0.605-14.585
	c4.194-3.859,10.734-3.589,14.589,0.609C198.121,350.19,226.153,361.29,256,361.29s57.879-11.101,74.984-29.698
	c3.855-4.198,10.395-4.468,14.589-0.609c4.194,3.859,4.468,10.391,0.605,14.585C325.234,368.339,291.524,381.935,256,381.935z"
		/>
		<path
			fill="#7D5046"
			d="M169.29,256L169.29,256c-11.402,0-20.645-9.243-20.645-20.645v-16.516
	c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645v16.516C189.935,246.757,180.692,256,169.29,256z"
		/>
		<path
			fill="#9C6846"
			d="M169.29,198.194c-1.414,0-2.794,0.145-4.129,0.416v28.487c0,6.841,5.546,12.387,12.387,12.387
	s12.387-5.546,12.387-12.387v-8.258C189.935,207.436,180.693,198.194,169.29,198.194z"
		/>
		<path
			fill="#7D5046"
			d="M342.71,256L342.71,256c-11.402,0-20.645-9.243-20.645-20.645v-16.516
	c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645v16.516C363.355,246.757,354.112,256,342.71,256z"
		/>
		<path
			fill="#9C6846"
			d="M342.71,198.194c-1.414,0-2.794,0.145-4.129,0.416v28.487c0,6.841,5.546,12.387,12.387,12.387
	s12.387-5.546,12.387-12.387v-8.258C363.355,207.436,354.112,198.194,342.71,198.194z"
		/>
		<path
			fill="#FFD164"
			d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.376-161.463
	C36.863,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.626-94.538
	C410.573,453.317,354.389,474.839,293.161,474.839z"
		/>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
	</svg>
);
