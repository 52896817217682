import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'ranked-question-banner';
const el = (name, mod) => cn(className, name, mod);

const RankedQuestionBanner = ({ show, topN, rankedOptions }) => {
	const { t } = useTranslation('main');
	const progressBarPercentage = Math.round((rankedOptions.length / topN) * 100);

	if (!show) {
		return null;
	}
	return (
		<div className={className}>
			<p className={el('banner-title')}>{t('tap-or-drag')}</p>
			<div className={el('progress-container')}>
				<div className={el('progress-bar')}>
					<div className={el('grey-bar')} />
					<div
						className={el('green-bar')}
						style={{
							width: `${progressBarPercentage}%`,
						}}
					/>
				</div>
				<div className={el('progress-count')}>
					{rankedOptions?.length}/{topN}
				</div>
			</div>
		</div>
	);
};

RankedQuestionBanner.propTypes = {
	show: PropTypes.bool,
	topN: PropTypes.number,
	rankedOptions: PropTypes.array,
};

export default RankedQuestionBanner;
