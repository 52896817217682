const questions = [
	{
		label: 'rh-1-label',
		validAnswers: ['rh-1-o-3'],
		invalidAnswers: ['rh-1-o-1', 'rh-1-o-2', 'rh-1-o-4'],
	},
	{
		label: 'rh-2-label',
		validAnswers: ['rh-2-o-2'],
		invalidAnswers: ['rh-2-o-1', 'rh-2-o-3', 'rh-2-o-4'],
	},
	{
		label: 'rh-3-label',
		validAnswers: ['rh-3-o-4'],
		invalidAnswers: ['rh-3-o-1', 'rh-3-o-2', 'rh-3-o-3'],
	},
];

module.exports = questions;
