import Aggressive from 'src/components/icons/Emojis/aggressive.svg';
import Amazed from 'src/components/icons/Emojis/amazed.svg';
import Anger from 'src/components/icons/Emojis/anger.svg';
import Annoyed from 'src/components/icons/Emojis/annoyed.svg';
import Anticipation from 'src/components/icons/Emojis/anticipation.svg';
import Anxious from 'src/components/icons/Emojis/anxious.svg';
import Curious from 'src/components/icons/Emojis/curious.svg';
import Cynicism from 'src/components/icons/Emojis/cynicism.svg';
import Despair from 'src/components/icons/Emojis/despair.svg';
import Disapproval from 'src/components/icons/Emojis/disapproval.svg';
import Disbelief from 'src/components/icons/Emojis/disbelief.svg';
import Disgust from 'src/components/icons/Emojis/disgust.svg';
import Fear from 'src/components/icons/Emojis/fear.svg';
import Guilt from 'src/components/icons/Emojis/guilt.svg';
import Hope from 'src/components/icons/Emojis/hope.svg';
import Jealous from 'src/components/icons/Emojis/jealous.svg';
import Joy from 'src/components/icons/Emojis/joy.svg';
import Love from 'src/components/icons/Emojis/love.svg';
import Optimistic from 'src/components/icons/Emojis/optimistic.svg';
import Proud from 'src/components/icons/Emojis/proud.svg';
import Regret from 'src/components/icons/Emojis/regret.svg';
import Sad from 'src/components/icons/Emojis/sad.svg';
import Surprised from 'src/components/icons/Emojis/surprised.svg';
import Trust from 'src/components/icons/Emojis/trust.svg';
import Shocked from 'src/components/icons/Emojis/shocked.svg';

export function getEmoji(label) {
	switch (label) {
		case 'aggressive':
			return Aggressive;
		case 'annoyed':
			return Annoyed;
		case 'disgust':
			return Disgust;
		case 'regret':
			return Regret;
		case 'disapproval':
			return Disapproval;
		case 'cynicism':
			return Cynicism;
		case 'disbelief':
			return Disbelief;
		case 'fear':
			return Fear;
		case 'anxious':
			return Anxious;
		case 'optimistic':
			return Optimistic;
		case 'jealous':
			return Jealous;
		case 'curious':
			return Curious;
		case 'anticipation':
			return Anticipation;
		case 'guilt':
			return Guilt;
		case 'surprised': // THis is spelt wrong in the emoji creation branch (Spelt "suprised")
			return Surprised;
		case 'despair':
			return Despair;
		case 'proud':
			return Proud;
		case 'sad':
			return Sad;
		case 'anger':
			return Anger;
		case 'joy':
			return Joy;
		case 'love':
			return Love;
		case 'amazed':
			return Amazed;
		case 'hope':
			return Hope;
		case 'trust':
			return Trust;
		case 'shocked':
			return Shocked;	
		default:
			return null;
	}
}
