import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import { motion } from 'framer-motion';
import ProductDescriptionForm from 'src/components/elements/ProductDescriptionForm';
import TextAiCard from 'src/domains/main/containers/TextAI/TextAiCard';
import TextAiUtils from 'src/utilities/text-ai';
import * as selectors from '../../../selectors';
import * as actions from '../../../actions';
import './styles.scss';

const className = 'text-ai-product-description';
const el = (name, mod) => cn(className, name, mod);

const animation = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
		transition: {
			staggerChildren: 100,
		}
	},
};

const TextAIProductDescription = ({ t, onNext, textAiProduct, setSentimentText }) => {
	const [pose, setPose] = useState('start');
	const [expanded, setExpanded] = useState(false);
	useEffect(() => setPose('completed'), []);
	useEffect(() => {
		adjustCardHeight();
		window.addEventListener('resize', () => adjustCardHeight(), false);
		if ('onorientationchange' in window) {
			window.addEventListener('orientationchange', function() {
				window.dispatchEvent(new Event('resize'));
			});
		}
	});
	const productPrompt = t('how-would-you-describe-this-product-to-a-friend');
	const handleSubmit = description => {
		setSentimentText(description);
		onNext();
	};

	const adjustCardHeight = () => {
		// 250ms is the time it takes to have the expandable text area return to it's collapsed size
		setTimeout(() => {
			TextAiUtils.adjustCardHeight();
		}, 250);
		TextAiUtils.adjustCardHeightCludge(expanded);
	};
	return (
		<motion.div animate={pose} initial="start" variants={animation} className={className}>
			<div className={expanded ? 'text-ai-card expanded' : 'text-ai-card'}>
				<TextAiCard
					textAiProduct={textAiProduct}
					product={TextAiUtils.prepareProductCardContentForStack(textAiProduct)}
				/>
			</div>
			<div className={el('footer ai-footer')}>
				<div className={el('footer-cta')}>{productPrompt}</div>
				<ProductDescriptionForm
					onFocus={() => setExpanded(true)}
					onBlur={() => setExpanded(false)}
					handleSubmit={handleSubmit}
					productData={textAiProduct}
					t={t}
				/>
			</div>
		</motion.div>
	);
};

const mapStateToProps = state => ({
	study: selectors.getStudy(state),
	textAIProductId: selectors.getTextAIProductId(state),
});

const mapDispatchToProps = dispatch => ({
	proceed: (step, results) => dispatch(actions.proceed(step, results)),
	setSentimentText: description => dispatch(actions.setSentimentText(description)),
});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(TextAIProductDescription));
