export const adjustCardHeight = () => {

    // Get the footer height
    let footerHeight = 0;
    const footer = document.getElementsByClassName('ai-footer')[0];
    if (footer) {
        const rect = footer.getBoundingClientRect();
        footerHeight = rect.height;
    }

    // Get the header height
    let headerHeight = 0;
    const header = document.getElementsByClassName('top-header')[0];
    if (header) {
        const rect = header.getBoundingClientRect();
        headerHeight = rect.height;
    }

    // Set the max height of the card view product
    const cardViewProduct = document.getElementsByClassName('card-view-product')[0];
    if (cardViewProduct) {
        const isExpanded = cardViewProduct.classList.contains('expanded');
        const maxHeight = `${window.innerHeight - (headerHeight + footerHeight)}px`;
        cardViewProduct.style.maxHeight = maxHeight;
        cardViewProduct.style.height = isExpanded ? window.innerHeight+'px !important' : maxHeight+'px !important';
    }
};

export const adjustCardHeightCludge = expanded => {
    // Set the max height of the new card section images
    const newCardSectionImages = document.getElementsByClassName('new-card-section-images')[0];
    if (newCardSectionImages) {
        newCardSectionImages.style.maxHeight = (expanded) ? '100%' : 'calc(67% - 0px)'; // TODO: This percentage should be calculated.
    }
};

export const hasDetails = product => product.title || product.description || product.price || product.subtitle;

export const getDetails = product => ({
    type: 'Details',
    content: {
        title: product.title,
        summary: product.description,
        price: product.price,
        subtitle: product.subtitle
    },
    options: {}
});

export const getFieldSection = (type, content, options) =>
    type === 'html'
        ? { type: 'Text', content: content, options }
        : {
                type: 'Images',
                content: (content || []).map(({ url }) => url),
                options
            };

export const prepareProductCardContentForStack = product => ({
    type: 'Fixed',

    ...(product.layout === 'classic'
        ? {
                sections: [
                    {
                        type: 'Images',
                        content: (product && product.fieldOne && product.fieldOne.map ? product.fieldOne : []).map(({ url }) => url),
                        options: product.fieldOneOptions || {}
                    },
                    ...(hasDetails(product) ? [getDetails(product)] : [])
                ]
          }
        : {}),

    ...(product.layout === 'one-field'
        ? {
                sections: [
                    getFieldSection(
                        product.fieldOneType,
                        product.fieldOne,
                        product.fieldOneOptions || {}
                    ),
                    ...(hasDetails(product) ? [getDetails(product)] : [])
                ]
          }
        : {}),

    ...(product.layout === 'two-field'
        ? {
                sections: [
                    getFieldSection(
                        product.fieldOneType,
                        product.fieldOne,
                        product.fieldOneOptions || {}
                    ),
                    getFieldSection(
                        product.fieldTwoType,
                        product.fieldTwo,
                        product.fieldTwoOptions || {}
                    ),
                    ...(hasDetails(product) ? [getDetails(product)] : [])
                ]
          }
        : {})
});

export default {
    adjustCardHeight,
    adjustCardHeightCludge,
    getDetails,
    getFieldSection,
    hasDetails,
    prepareProductCardContentForStack
};
