import React from "react";
import PropTypes from "prop-types";
import cn from "src/utilities/bem-cn";
import "./Page.scss";

const className = "page";
const el = name => cn(className, name);

class Page extends React.Component {
	static propTypes = {
		children: PropTypes.any
	};

	static defaultProps = {
        /* */
    };

	render() {

        const { children } = this.props;

        return (
            <div className={className}>
                {children}
            </div>
		);
	}
}

export default Page;