import React from 'react';

export default () => (
	<svg
		version="1.1"
		id="Capa_1"
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 512 512"
	>
		<circle fill="#BEE86E" cx="256" cy="256" r="256" />
		<path
			fill="#AFD755"
			d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.376-161.463
	C36.863,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.626-94.538
	C410.573,453.317,354.389,474.839,293.161,474.839z"
		/>
		<g>
			<path
				fill="#6E5A64"
				d="M123.879,200.258c-3.96,0-7.734-2.282-9.444-6.129c-2.315-5.21,0.032-11.315,5.242-13.629
		L194,147.468c5.234-2.331,11.315,0.032,13.629,5.242c2.315,5.21-0.032,11.315-5.242,13.629l-74.323,33.032
		C126.702,199.976,125.274,200.258,123.879,200.258z"
			/>
			<path
				fill="#6E5A64"
				d="M297.282,362.54c-1.395,0-2.806-0.282-4.169-0.879c-21.75-9.637-52.46-9.661-74.266-0.097
		c-5.242,2.315-11.315-0.097-13.597-5.306c-2.29-5.226,0.089-11.315,5.306-13.597c27.04-11.847,63.968-11.79,90.911,0.113
		c5.21,2.306,7.573,8.403,5.266,13.621C305.024,360.25,301.25,362.54,297.282,362.54z"
			/>
		</g>
		<path
			fill="#5A4650"
			d="M169.29,256L169.29,256c-11.402,0-20.645-9.243-20.645-20.645v-2.409c0-3.67,2.422-6.9,5.946-7.928
	l24.774-7.226c5.285-1.542,10.57,2.422,10.57,7.928v9.634C189.935,246.757,180.692,256,169.29,256z"
		/>
		<path
			fill="#6E5A64"
			d="M388.121,200.258c-1.395,0-2.823-0.282-4.185-0.887l-74.323-33.032
	c-5.21-2.315-7.556-8.419-5.242-13.629c2.315-5.21,8.395-7.573,13.629-5.242l74.323,33.032c5.21,2.315,7.556,8.419,5.242,13.629
	C395.855,197.976,392.081,200.258,388.121,200.258z"
		/>
		<path
			fill="#5A4650"
			d="M342.71,256L342.71,256c11.402,0,20.645-9.243,20.645-20.645v-2.409c0-3.67-2.422-6.9-5.946-7.928
	l-24.774-7.226c-5.285-1.542-10.57,2.422-10.57,7.928v9.634C322.065,246.757,331.308,256,342.71,256z"
		/>
		<g>
			<path
				fill="#8CC855"
				d="M181.677,409.21c-4.25,0-8.234-2.645-9.734-6.879c-1.903-5.371,0.911-11.266,6.29-13.177
		c15.645-5.54,26.153-20.387,26.153-36.935c0-16.556-10.508-31.403-26.153-36.935c-5.371-1.903-8.194-7.806-6.29-13.177
		c1.903-5.371,7.831-8.202,13.177-6.29c23.871,8.452,39.911,31.113,39.911,56.403c0,25.274-16.04,47.944-39.911,56.403
		C183.984,409.024,182.823,409.21,181.677,409.21z"
			/>
			<path
				fill="#8CC855"
				d="M330.323,409.21c-1.145,0-2.306-0.185-3.444-0.589c-23.871-8.46-39.911-31.129-39.911-56.403
		c0-25.29,16.04-47.952,39.911-56.403c5.355-1.911,11.266,0.911,13.177,6.29c1.903,5.371-0.919,11.274-6.29,13.177
		c-15.645,5.532-26.153,20.379-26.153,36.935c0,16.548,10.508,31.395,26.153,36.935c5.379,1.911,8.194,7.806,6.29,13.177
		C338.556,406.565,334.573,409.21,330.323,409.21z"
			/>
		</g>
		<g>
			<path
				fill="#6E5A64"
				d="M362.897,230.258c-0.856-2.482-2.863-4.474-5.488-5.239l-18.829-5.492v7.571
		c0,6.841,5.546,12.387,12.387,12.387C356.71,239.484,361.493,235.557,362.897,230.258z"
			/>
			<path
				fill="#6E5A64"
				d="M179.365,217.792l-14.204,4.143v5.162c0,6.841,5.546,12.387,12.387,12.387
		s12.387-5.546,12.387-12.387v-1.377C189.935,220.215,184.65,216.251,179.365,217.792z"
			/>
		</g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
	</svg>
);
