import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import X from 'src/components/icons/X';
import cn from 'src/utilities/bem-cn';
import * as selectors from '../../../domains/main/selectors';
import * as actions from '../../../domains/main/actions';
import './styles.scss';

const className = 'asset-preview-modal';
const el = (name) => cn(className, name);

// ? don't use redux, pass url as prop
// ? position absolute
const AssetPreviewModal = ({ handleOnClose, setAssetPreviewModalUrl, study }) => {
	if (!study) return null;
	const { assetPreviewModalUrl } = study;
	if (!assetPreviewModalUrl) return null;

	const onClose = () => {
		setAssetPreviewModalUrl('');
		handleOnClose();
	};

	const onClickCapture = (e) => {
		const isTargetBackground = e.target.classList.contains('asset-preview-modal__content');
		if (isTargetBackground) {
			setAssetPreviewModalUrl('');
			handleOnClose();
		}
	};

	return (
		<div className={className}>
			<button type="button" className={el('close')} onClick={() => onClose()}>
				<X color="#fff" width={2} />
			</button>
			<div className={el('content')} onClickCapture={onClickCapture}>
				<img src={assetPreviewModalUrl} alt="preview" />
			</div>
		</div>
	);
};

AssetPreviewModal.propTypes = {
	study: PropTypes.object,
	setAssetPreviewModalUrl: PropTypes.func,
	handleOnClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
	study: selectors.getStudy(state),
});

const mapDispatchToProps = (dispatch) => ({
	setAssetPreviewModalUrl: (assetPreviewModalUrl) => dispatch(actions.setAssetPreviewModalUrl(assetPreviewModalUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetPreviewModal);
