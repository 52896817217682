// React & Redux
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';

// Localization
import { withTranslation } from 'react-i18next';

// BEM
import cn from 'src/utilities/bem-cn';

// Animation
import { motion } from 'framer-motion';

// Components
import AssetWithText from 'src/components/new/AssetWithText';
import Button from 'src/components/new/Button';
import { CONSTANTS } from 'src/utilities/constants';
// Actions
import * as actions from '../../actions';

// Styles
import './styles.scss';

const buttonHeight = 68;
const className = 'welcome-container';
const el = (name, mod) => cn(className, name, mod);
const containerHeightBuffer = 10;

const animation = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
		transition: {
			staggerChildren: 100,
		}
	},
}

const Welcome = ({ answers, productId, t }) => {
	const [pose, setPose] = useState('start');
	const [scrolled, setScrolled] = useState(false);

	const study = useSelector(state => state.main.study);
	const previousSectionsAnswers = useSelector(state => state.main.answers);
	const sections = useSelector(state => state.main.sections);
	const currentSectionIndex = useSelector(state => state.main.currentSectionIndex);
	const currentSection = sections[currentSectionIndex];
	const [containerHeight, setContainerHeight] = useState(window.innerHeight - containerHeightBuffer);
	const [justifyContent, setJustifyContent] = useState(false);
	const elementRef = useRef(true);

	const dispatch = useDispatch();
	useEffect(() => {
		setPose('completed');
		window.addEventListener('resize', updateContainerHeight);
		window.addEventListener('orientationchange', updateContainerHeight);
		return () => {
			window.removeEventListener('resize', updateContainerHeight);
			window.removeEventListener('orientationchange', updateContainerHeight);
		}
	}, []);

	const [imageAlignment, setImageAlignment] = useState(null);

	useEffect(() => {
		if (currentSection && currentSection.settings) {
			const imageAlignSetting = currentSection.settings.find(
				s => (s.label = CONSTANTS.imageAlignmentSettings.statementSection),
			);
			if (imageAlignSetting) {
				setImageAlignment(imageAlignSetting.value);
			}
		}
	}, [currentSection]);
	if (!currentSection) {
		return null;
	}

	useEffect(() => {
		// Wait for render cycle to check client height
		setTimeout(() => {
			const isScrolled = (elementRef?.current?.clientHeight - buttonHeight/2) > window.innerHeight;
			setScrolled(isScrolled);
			setJustifyContent(isScrolled);
		}, 100);
	}, [currentSection]);

	// TODO - sort if we need to support welcome messages still, or migrate them over.
	// const { introText } = study;
	const { text, asset, buttonText } = currentSection?.statements[0];

	const handleClick = () => dispatch(actions.setNextSection());
	const setAssetPreviewModalUrl = assetPreviewModalUrl =>
		dispatch(actions.setAssetPreviewModalUrl(assetPreviewModalUrl));

	const updateContainerHeight = () => {
		setContainerHeight(window.innerHeight - containerHeightBuffer);
		setTimeout(() => {
			const isScrolled = elementRef.current.clientHeight > window.innerHeight;
			setScrolled(isScrolled);
			setJustifyContent(isScrolled);
		}, 100);
	}

	// Combines current section answers with the answers from all previous sections
	const allAnswers = Object.assign(answers || {}, previousSectionsAnswers || {});

	return (
		<motion.div
			animate={pose}
			initial="start"
			variants={animation} 
			className={className} 
			style={{height: `${containerHeight}px`,
					maxHeight: `${containerHeight}px`, 
					minHeight: `${containerHeight}px`,
					justifyContent: `${justifyContent ? 'flex-start' : 'center'}`}}>
			<div className={el('content')} ref={elementRef}>
				<AssetWithText
					summary={text}
					asset={asset}
					imageAlignment={imageAlignment}
					study={study}
					answers={allAnswers}
					setAssetPreviewModalUrl={setAssetPreviewModalUrl}
					productId={productId}
					t={t}
					pose={pose}
				/>
				<div className={el('inline-button')}>
					<Button label={buttonText || t('continue')} onClick={handleClick} dataTestId="continue-button" />
				</div>
				<GlobalScrollIndicator show={scrolled} />
			</div>
		</motion.div>
	);
};

export default withTranslation('main')(Welcome);
