export default {
	'this-survey-does-not-exist': 'Üzgünüz, bu anket mevcut değil.',
	'survey-closed': 'Üzgünüz, bu anket şimdi kapalı.',
	'survey-no-products': 'Bu ankette ürün yok.',
	'survey-no-products-summary': 'Bu anketin sahibiyseniz devam etmek için panoda ürün oluşturun.',
	'survey-completed': 'Bu anketi zaten tamamladınız.',
	'summary-placeholder':
		'Bunun bir hata olduğunu düşünüyorsanız lütfen bu bağlantıyı size gönderen kişiyle irtibata geçin.',
	'gone-wrong': 'Hımmm, bir terslik var.',
	'try-again': 'Tekrar deneyin veya daha sonra tekrar gelin.',
	'thank-you': 'Teşekkür ederiz',
	'thank-you-summary': 'Anket tamamlandı ve sonuçlarınız gönderildi.',
	'thank-you-summary-redirect': 'Birkaç saniye içinde yeniden yönlendirileceksiniz.',
	'page-not-found': 'Bir terslik var.',
	'page-not-found-placeholder': 'Maalesef, bağlantı bozuk.',
	'tap-your-favorite': 'Favorinizdeki onay işaretine dokunun',
	or: 'veya',
	'get-started-with-questions': 'Başlamak için birkaç soru kaldı',
	continue: 'Devam',
	retry: 'Tekrar Deneyin',
	'tell-us-how-you-feel-about-this-product': 'Bu ürünle ilgili düşüncelerinizi bizimle paylaşın',
	begin: 'Başla',
	'how-would-you-describe-this-product-to-a-friend': 'Bu ürünü bir arkadaşınıza nasıl tarif ederdiniz?',
	'type-your-response-here': 'Yanıtınızı buraya yazın',
	'sum-of-total-characters': '${SUM} / ${TOTAL} karakter',
	edit: 'Düzenle',
	remove: 'Sil',
	'how-do-you-feel-about-this-part': 'Bu bölümle ilgili ne düşünüyorsunuz?',
	'tap-on-an-area-and-choose-an-emoji': 'Bir yere dokunun ve bir ifade seçin',
	'im-finished': 'Bitti',
	maintenance: 'Bakım',
	'maintenance-mode': 'Upsiide şu anda bakımda. Lütfen az sonra tekrar deneyin.',
	'please-specify': 'Lütfen belirtin',
	'monadic-split-statement':
		'Sıradaki ekranda size bir fikir göstereceğiz. Bu fikirle ilgili sorular soracağımız için lütfen dikkatle inceleyin.',
	disapproval: 'Hoşnutsuzluk',
	anger: 'Kızgınlık',
	sad: 'Üzüntü',
	joy: 'Keyif',
	love: 'Sevgi',
	trust: 'Güven',
	hope: 'Umut',
	proud: 'Gurur',
	amazed: 'Hayret',
	surprised: 'Şaşkınlık',
	guilt: 'Suçluluk',
	curious: 'Merak',
	jealous: 'Kıskançlık',
	anticipation: 'Beklenti',
	optimistic: 'İyimserlik',
	anxious: 'Endişe',
	fear: 'Korku',
	despair: 'Umutsuzluk',
	disbelief: 'İnançsızlık',
	cynicism: 'Şüphe',
	regret: 'Pişmanlık',
	disgust: 'Tiksinti',
	annoyed: 'Rahatsızlık',
	aggressive: 'Saldırganlık',
	shocked: 'Şok olmuş',
	choose: '{{amount}} şık seçin', // todo revisit translation
	'up-to': 'En fazla {{amount}} şık seçin', // todo revisit translation
	unlimited: 'İstediğiniz kadar seçin',
	'single-select': 'Birini seç',
	number: 'Bir rakam girin',
	'number-between-min-max': 'Şu sayılar arasında bir rakam girin: {{min}} – {{max}}',
	'number-min': '{{min}} veya daha büyük bir rakam girin',
	'number-max': '{{max}} veya daha küçük bir rakam girin',
	'open-ended-pledge':
		'Bu ankette kendi sözcüklerinizle yanıt vermeniz beklenen sorular yer almaktadır. Verdiğiniz yanıtlar, kalite gereksinimlerimizin karşılandığından emin olmak için otomatik olarak kontrol edilecektir. Devam etmeyi onaylıyor musunuz? ',
	'open-ended-pledge-accept': 'Evet, onaylıyorum',
	'open-ended-pledge-decline': 'Hayır, onaylamıyorum',
	'rh-1-label': 'Aşağıdakilerden hangisi bir spor dalı değildir?',
	'rh-1-o-1': 'Basketbol',
	'rh-1-o-2': 'Tenis',
	'rh-1-o-3': 'Kurabiye',
	'rh-1-o-4': 'Hokey',
	'rh-2-label': '2+3 kaçtır?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Aşağıdakilerden hangisi bir meyvedir?',
	'rh-3-o-1': 'Süt',
	'rh-3-o-2': 'Çikolata',
	'rh-3-o-3': 'Yumurta',
	'rh-3-o-4': 'Elma',
	'open-end-max-length': 'Üzgünüm, girilen cevap çok uzun',
	'heatmap-click-image': 'Yorum bırakmak için görsele tıklayın',
	'heatmap-click-image-no-comments': 'Görselin üzerinde herhangi bir yere tıklayın',
	'heatmap-click-image-product-card': 'Yorum bırakmak için karta tıklayın ',
	'heatmap-click-image-product-card--no-comment': 'Kartın üzerinde herhangi bir yere tıklayın',
	'heatmap-type-comment': 'Yorum yazın',
	cancel: 'İptal et',
	skip: 'Atlamak',
	ranked: 'Dereceli',
	top: 'En iyi',
	'tap-or-drag': 'Sıralamaya dokunun veya sürükleyip bırakın',
	unranked: 'Rütbesiz',
	reset: 'Sıfırla',
	'no-more-options': 'Sıralama için başka seçenek yok',
	'rank-list-full': 'Sıralama listesi dolu, tercihlerinizden birinin sırasını kaldırın',
};
