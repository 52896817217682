import React from 'react';

export default () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M12.5001 2C12.7762 2 13.0001 2.22386 13.0001 2.5V18.67L17.4401 14.22C17.534 14.1253 17.6618 14.0721 17.7951 14.0721C17.9284 14.0721 18.0562 14.1253 18.1501 14.22L18.8501 14.93C18.9448 15.0239 18.998 15.1517 18.998 15.285C18.998 15.4183 18.9448 15.5461 18.8501 15.64L12.7201 21.78C12.5796 21.9207 12.3889 21.9998 12.1901 22H11.8101C11.6117 21.9977 11.4218 21.9189 11.2801 21.78L5.1501 15.64C5.05544 15.5461 5.0022 15.4183 5.0022 15.285C5.0022 15.1517 5.05544 15.0239 5.1501 14.93L5.8601 14.22C5.95226 14.1259 6.07841 14.0729 6.2101 14.0729C6.34179 14.0729 6.46793 14.1259 6.5601 14.22L11.0001 18.67V2.5C11.0001 2.22386 11.224 2 11.5001 2H12.5001Z"
			fill="#666666"
		/>
	</svg>
);
