export default {
	'this-survey-does-not-exist': 'К сожалению, этот опрос не существует.',
	'survey-closed': 'К сожалению, этот опрос закрыт.',
	'survey-no-products': 'В этом опросе отсутствуют продукты.',
	'survey-no-products-summary':
		'Если Вы являетесь владельцем этого опроса, выберите продукты на панели инструментов, чтобы продолжить.',
	'survey-completed': 'Вы уже прошли этот опрос.',
	'summary-placeholder': 'Если Вы считаете, что произошла ошибка, обратитесь к лицу, отправившему Вам эту ссылку.',
	'gone-wrong': 'Похоже, что-то пошло не так.',
	'try-again': 'Повторите попытку или вернитесь позже.',
	'thank-you': 'Спасибо!',
	'thank-you-summary': 'Опрос завершен, и Ваши ответы отправлены.',
	'thank-you-summary-redirect': 'Вы будете переадресованы через несколько секунд.',
	'page-not-found': 'Что-то пошло не так',
	'page-not-found-placeholder': 'К сожалению, ссылка не работает',
	'tap-your-favorite': 'Поставьте галочку в «Избранном»',
	or: 'или',
	'get-started-with-questions': 'Для начала у нас несколько вопросов',
	continue: 'Продолжить',
	retry: 'Повторить',
	'tell-us-how-you-feel-about-this-product': 'Расскажите, что Вы думаете об этом продукте',
	begin: 'Начать',
	'how-would-you-describe-this-product-to-a-friend': 'Как бы Вы описали этот продукт другу или подруге?',
	'type-your-response-here': 'Впишите свой ответ здесь...',
	'sum-of-total-characters': 'Символов: ${SUM} из ${TOTAL}',
	edit: 'Изменить',
	remove: 'Удалить',
	'how-do-you-feel-about-this-part': 'Что Вы думаете об этой части?',
	'tap-on-an-area-and-choose-an-emoji': 'Нажмите на любой участок и выберите смайлик',
	'im-finished': 'Готово',
	maintenance: 'Обслуживание',
	'maintenance-mode': 'В настоящее время проводится обслуживание Upsiide Возвращайтесь к нам немного позднее.',
	'please-specify': 'Укажите',
	'monadic-split-statement':
		'На следующем экране Вы увидите идею. Пожалуйста, внимательно ознакомьтесь с этой идеей, поскольку далее последуют вопросы о ней.',
	disapproval: 'Неодобрение',
	anger: 'Гнев',
	sad: 'Грусть',
	joy: 'Радость',
	love: 'Любовь',
	trust: 'Доверие',
	hope: 'Надежда',
	proud: 'Гордость',
	amazed: 'Изумление',
	surprised: 'Удивление',
	guilt: 'Вина',
	curious: 'Любопытство',
	jealous: 'Зависть',
	anticipation: 'Предвкушение',
	optimistic: 'Оптимизм',
	anxious: 'Беспокойство',
	fear: 'Страх',
	despair: 'Отчаяние',
	disbelief: 'Недоверие',
	cynicism: 'Скептицизм',
	regret: 'Сожаление',
	disgust: 'Отвращение',
	annoyed: 'Раздражение',
	aggressive: 'Агрессия',
	shocked: 'Потрясение',
	choose: 'Выберите {{amount}}',
	'up-to': 'Выберите до {{amount}}',
	unlimited: 'Выберите все подходящие варианты',
	'single-select': 'Выберите один',
	number: 'Введите число',
	'number-between-min-max': 'Введите число от {{min}} до {{max}}',
	'number-min': 'Введите число не меньше {{min}}',
	'number-max': 'Введите число не больше {{max}}',
	'open-ended-pledge':
		'В этом исследовании содержатся вопросы, на которые Вам нужно будет ответить своими словами. Ваши ответы будут автоматически проверены на предмет соответствия нашим требованиям качества. Вы соглашаетесь продолжить? ',
	'open-ended-pledge-accept': 'Да, соглашаюсь',
	'open-ended-pledge-decline': 'Нет, не соглашаюсь',
	'rh-1-label': 'Что из перечисленного не является видом спорта?',
	'rh-1-o-1': 'Баскетбол',
	'rh-1-o-2': 'Теннис',
	'rh-1-o-3': 'Печенье',
	'rh-1-o-4': 'Хоккей',
	'rh-2-label': 'Сколько будет 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Что из перечисленного является фруктом?',
	'rh-3-o-1': 'Молоко',
	'rh-3-o-2': 'Шоколад',
	'rh-3-o-3': 'Яйцо',
	'rh-3-o-4': 'Яблоко',
	'open-end-max-length': 'Извините, введенный ответ слишком длинный',
	'heatmap-click-image': 'Нажмите изображение, чтобы добавить комментарий',
	'heatmap-click-image-no-comments': 'Нажмите где-либо на изображении',
	'heatmap-click-image-product-card': 'Нажмите карту, чтобы добавить комментарий ',
	'heatmap-click-image-product-card--no-comment': 'Нажмите где-либо на карте',
	'heatmap-type-comment': 'Введите комментарий',
	cancel: 'Отмена',
	skip: 'Пропускать',
	ranked: 'Pанжированный',
	top: 'верхний',
	'tap-or-drag': 'Нажмите или перетащите, чтобы оценить',
	unranked: 'Без рейтинга',
	reset: 'Перезагрузить',
	'no-more-options': 'Больше нет вариантов ранжирования',
	'rank-list-full': 'Список рангов заполнен, снимите рейтинг с одного из выбранных вами вариантов',
};
