export default {
	'this-survey-does-not-exist': 'Sajnáljuk, ez a felmérés nem létezik.',
	'survey-closed': 'Sajnáljuk, ez a felmérés lezárult.',
	'survey-no-products': 'Ez a felmérés nem tartalmaz termékeket.',
	'survey-no-products-summary':
		'Ha Te vagy a felmérés tulajdonosa, a folytatáshoz hozz létre termékeket az irányítópulton.',
	'survey-completed': 'Ezt a felmérést már kitöltötted.',
	'summary-placeholder': 'Ha úgy gondolod, hogy ez tévedés, fordulj ahhoz a személyhez, aki elküldte ezt a linket.',
	'gone-wrong': 'Hmmm, valami nem stimmel.',
	'try-again': 'Próbáld újra, vagy gyere vissza később.',
	'thank-you': 'Köszönjük',
	'thank-you-summary': 'A felmérés kitöltve, az eredményeidet beküldtük.',
	'thank-you-summary-redirect': 'Néhány másodpercen belül átirányítunk.',
	'page-not-found': 'Valami nem stimmel',
	'page-not-found-placeholder': 'Hoppá, törött link',
	'tap-your-favorite': 'Koppints a jelölőnégyzetre a kedvencedre',
	or: 'vagy',
	'get-started-with-questions': 'Néhány kérdés a kezdéshez',
	continue: 'Folytatás',
	retry: 'Újrapróbálkozás',
	'tell-us-how-you-feel-about-this-product': 'Mondd el, mit gondolsz erről a termékről',
	begin: 'Kezdés',
	'how-would-you-describe-this-product-to-a-friend': 'Hogyan jellemeznéd ezt a terméket egy barátodnak?',
	'type-your-response-here': 'Ide írd be a válaszodat',
	'sum-of-total-characters': '${SUM} / ${TOTAL} karakter',
	edit: 'Szerkesztés',
	remove: 'Eltávolítás',
	'how-do-you-feel-about-this-part': 'Mit gondolsz erről a részről?',
	'tap-on-an-area-and-choose-an-emoji': 'Koppints egy területre, és válassz hangulatjelet',
	'im-finished': 'Befejeztem',
	maintenance: 'Karbantartás',
	'maintenance-mode': 'Az Upsiide jelenleg karbantartás alatt áll. Nézz vissza hamarosan.',
	'please-specify': 'Kérjük részletezd',
	'monadic-split-statement':
		'A következő oldalon egy ötletet mutatunk. Kérjük, alaposan nézd át, mert kérdésünk lesz ezzel kapcsolatban.',
	disapproval: 'Helytelenítés',
	anger: 'Düh',
	sad: 'Szomorú',
	joy: 'Öröm',
	love: 'Szeretet',
	trust: 'Bizalom',
	hope: 'Remény',
	proud: 'Büszke',
	amazed: 'Lenyűgözött',
	surprised: 'Meglepett',
	guilt: 'Bűntudat',
	curious: 'Kíváncsi',
	jealous: 'Féltékeny',
	anticipation: 'Várakozás',
	optimistic: 'Optimista',
	anxious: 'Nyugtalan',
	fear: 'Félelem',
	despair: 'Kétségbeesés',
	disbelief: 'Hitetlenség',
	cynicism: 'Cinizmus',
	regret: 'Megbánás',
	disgust: 'Undor',
	annoyed: 'Bosszús',
	aggressive: 'Agresszív',
	shocked: 'Döbbent',
	choose: 'Válassz {{amount}}',
	'up-to': 'Válassz legfeljebb {{amount}}',
	unlimited: 'Válassz annyit amennyit szeretnél',
	'single-select': 'Válasszon',
	number: 'Adj meg egy számot',
	'number-between-min-max': 'Adj meg egy számot {{min}} és {{max}} között',
	'number-min': 'Adj meg egy számot ami nagyobb mint {{min}}',
	'number-max': 'Adj meg egy számot ami kevesebb mint {{max}}',
	'open-ended-pledge':
		'Ez a felmérés olyan kérdéseket tartalmaz, amelyekre a saját szavaival kell válaszolnia. Válaszait automatikusan ellenőrizzük, hogy megbizonyosodjunk arról, hogy megfelelnek minőségi követelményeinknek. Beleegyezik a folytatásba? ',
	'open-ended-pledge-accept': 'Igen, egyetértek',
	'open-ended-pledge-decline': 'Nem, nem értek egyet',
	'rh-1-label': 'Az alábbiak közül melyik nem sportág?',
	'rh-1-o-1': 'Kosárlabda',
	'rh-1-o-2': 'Tenisz',
	'rh-1-o-3': 'Süti',
	'rh-1-o-4': 'Jéghoki',
	'rh-2-label': 'Mennyi 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Az alábbiak közül melyik gyümölcs?',
	'rh-3-o-1': 'Tej',
	'rh-3-o-2': 'Csokoládé',
	'rh-3-o-3': 'Tojás',
	'rh-3-o-4': 'Alma',
	'open-end-max-length': 'Elnézést, a beírt válasz túl hosszú',
	'heatmap-click-image': `Kattintson a képre, ha hozzászólást szeretne hozzáfűzni`,
	'heatmap-click-image-no-comments': `Kattintson a képre bárhol`,
	'heatmap-click-image-product-card': `Kattintson a kártyára, ha hozzászólást szeretne hozzáfűzni `,
	'heatmap-click-image-product-card--no-comment': `Kattintson a kártyára bárhol`,
	'heatmap-type-comment': `Írja le a hozzászólást`,
	cancel: `Mégsem`,
	skip: 'Kihagyás',
	ranked: 'Rangsorolt',
	top: 'Legjobb',
	'tap-or-drag': 'Koppintson vagy húzza át a rangsoroláshoz',
	unranked: 'Nem rangsorolt',
	reset: 'Visszaállítás',
	'no-more-options': 'Nincs több lehetőség a rangsorolásra',
	'rank-list-full': 'A ranglista megtelt, szüntesse meg a rangsorolást valamelyik választása közül',
};
