import React, { useState } from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const ButtonFixed = ({ label, status, onClick }) => {
  const [addClass, setAddClass] = useState(false);
  const className = `button-fixed ${addClass ? "loading" : ""} ${status}`;

  const toggle = () => {
    setAddClass(!addClass);
    onClick && onClick();
  };

  return (
    <button type="button" className={className} onClick={toggle}>
      {label}
    </button>
  );
};

ButtonFixed.propTypes = {
  label: PropTypes.string,
  status: PropTypes.oneOf(["active", "loading", "disabled"]),
  onClick: PropTypes.func,
};

ButtonFixed.defaultProps = {
  label: "Button",
  status: "active",
  onClick: null,
};

export default ButtonFixed;
