import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';

const className = 'commitment-card-stack';
const el = (name) => cn(className, name);
const heightBuffer = 50;
const animationTimer = 1500; // 2s animation time

class Stage extends React.Component {
	static propTypes = {
		render: PropTypes.func.isRequired,
		data: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.any,
			}),
		).isRequired,
	};

	// Initiate pose animations
	state = { isReady: false };

	componentDidMount() {
		setTimeout(this.setState({ isReady: !this.state.isReady }), 200);
		window.addEventListener('resize', this.updateContainerHeight);
		window.addEventListener('orientationchange', this.updateContainerHeight);

		const commitmentAnimationNotPlayed = document.querySelector('.commitment-card-stack-wrapper.animate');
		if (commitmentAnimationNotPlayed) {
			setTimeout(() => {
				if (this.commitmentCardRef && this.commitmentCardRef.current) {
					this.setState({
						showScrollIndicator:
							this.commitmentCardRef.current.scrollHeight - heightBuffer >
							this.commitmentCardRef.current.clientHeight,
					});
				}
			}, animationTimer);
		} else if (this.commitmentCardRef && this.commitmentCardRef.current) {
			this.setState({
				showScrollIndicator:
					this.commitmentCardRef.current.scrollHeight - heightBuffer >
					this.commitmentCardRef.current.clientHeight,
			});
		}
	}

	constructor({ data }) {
		super(...arguments);
		this.animateRef = React.createRef(false);
		this.commitmentCardRef = React.createRef(null);
		this.state = {
			data,
			containerHeight: window.innerHeight,
			showScrollIndicator: false,
		};
	}

	updateContainerHeight = () => {
		this.setState({ containerHeight: window.innerHeight });
	};

	getCommitmentText = () => {
		const { t, currentSection, study } = this.props;
		const { currentLanguage } = study;
		const commitmentTextList = currentSection.settings
			.filter((item) => item.label === 'commitment_text') // * Find the commitment text settings
			.filter((setting) => setting.languageCode) // * Filter out any settings that don't have a language code
			.filter((setting) => setting.value); // * Filter out any settings that do have a language code, but don't have a value
		if (commitmentTextList) {
			const settingsIndex = commitmentTextList.findIndex((item) => item.languageCode === currentLanguage);
			if (commitmentTextList[settingsIndex]) {
				return commitmentTextList[settingsIndex].value;
			}
		}
		return t('tap-your-favorite');
	};

	render() {
		const { t } = this.props;
		const { data, isReady, containerHeight, showScrollIndicator } = this.state;

		if (!this.animateRef.current) {
			setTimeout(() => {
				this.animateRef.current = true;
				this.forceUpdate();
			}, 30);
		}

		return (
			<div
				className={className}
				ref={this.commitmentCardRef}
				style={{
					minHeight: `${containerHeight - heightBuffer}px`,
					maxHeight: `${containerHeight - heightBuffer}px`,
					height: `${containerHeight - heightBuffer}px`,
					opacity: `${this.animateRef.current ? 1 : 0}`,
				}}
			>
				<div className="commitment-card-text-container">
					<h3>{this.getCommitmentText()}</h3>
				</div>
				<div className="commitment-card-item">{data[0].element}</div>
				<div className="commitment-card-separator">
					<div className={el('separator')}>
						<span>{t('or')}</span>
					</div>
				</div>
				<div className="commitment-card-item">{data[1].element}</div>
				<GlobalScrollIndicator show={showScrollIndicator} />
			</div>
		);
	}
}

export default withTranslation('main')(Stage);
