import { motion } from 'framer-motion';
import cn from 'src/utilities/bem-cn';
import cards from '@upsiide/product-cards';
import './styles.scss';

const className = 'text-ai-card';
const el = (name, mod) => cn(className, name, mod);

const textAIPose = {
	off: { width: '90%', height: () => `${window.innerHeight - 206}px`, flip: true },
	on: { width: '100%', height: () => window.innerHeight, flip: true }
};

class TextAICard extends cards.StackCard {
	constructor(props) {
		super(props);
		this.initPosedStackProduct();
	}
	initPosedStackProduct = () => {
		// Set the max height of the card view product
		this.PosedStackProduct = textAIPose;
	};
};

export default TextAICard;
