import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import Button from 'src/components/new/Button';

import './styles.scss';

const className = 'ranked-reset-button';
const el = (name, mod) => cn(className, name, mod);

const RankedResetButton = ({ handleReset, disabled, question }) => {
	const { t } = useTranslation('main');
	return (
		<div className="ranked-reset-button">
			<div className={`${el('inline-button')} ${el('ranked-reset-button')}`}>
				<Button
					disabled={disabled}
					label={t('reset')}
					onClick={() => handleReset(question)}
					tabindex={disabled ? '-1' : 0}
					dataTestId="reset-button"
				/>
			</div>
		</div>
	);
};

RankedResetButton.propTypes = {
	question: PropTypes.object,
	disabled: PropTypes.bool,
	handleReset: PropTypes.func,
};

export default RankedResetButton;
