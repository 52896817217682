import * as Sentry from '@sentry/browser';
import api from 'src/utilities/api';
import * as researchDefenderApi from 'src/utilities/researchDefenderApi';
import { datadogRum } from '@datadog/browser-rum';
import * as misc from 'src/utilities/misc';
import { shuffle } from 'lodash';
import * as selectors from '../selectors';
import * as actions from '../actions';
import i18n from '../../../i18n';

/**
 * Testing
 */

const nopeFunction = (v) => v;

/**
 * Response Effects
 */

const checkResponse = (response) => {
	if (response.complete || response.endTime) {
		if (response.redirectUrl) {
			// TODO - add a "overquota" reject type
			setTimeout(() => {
				window.location.href = response.redirectUrl;
			}, 500);
		}
		return Promise.reject('completed');
	}
	if (response.redirectUrl) {
		// TODO - add a "overquota" reject type
		setTimeout(() => {
			window.location.href = response.redirectUrl;
		}, 10);
	} else {
		return response;
	}
};

/** user is disqualified, survey terminated */
const setDisqualified = (store, action) => {
	if (action.type === actions.SET_DISQUALIFIED) {
		const state = store.getState();
		const { answers, audienceUuid, reasonForDisqualification, submittedAnswersInQuotas } = action.payload;
		const responseId = selectors.getResponseId(state);
		const urlParams = misc.getAllUrlParams();
		const qualifiers = (urlParams && urlParams.q) || [];
		const testMode = selectors.getPreviewUiid(state);
		const study = selectors.getStudy(state);

		(testMode
			? Promise.resolve()
			: api.createDisqualificationResponse(
					responseId,
					!submittedAnswersInQuotas ? answers : [],
					audienceUuid,
					!submittedAnswersInQuotas ? qualifiers : [],
					study,
					reasonForDisqualification,
			  )
		)
			.then((response) => {
				if (response.redirectUrl) {
					const search = window.location.search.substring(1);
					const urlParams = search
						? JSON.parse(
								`{"${decodeURI(search)
									.replace(/"/g, '\\"')
									.replace(/&/g, '","')
									.replace(/=/g, '":"')}"}`,
						  )
						: {};
					urlParams.RID = selectors.getResponseRID(store.getState());
					const newPreparedUrl = Object.keys(urlParams).reduce(
						(str, key) => str.replace(`{${key}}`, urlParams[key]),
						response.redirectUrl,
					);
					window.location.replace(newPreparedUrl);
				} else {
					store.dispatch(actions.setStep('terminated'));
				}
			})
			.catch((error) => {
				store.dispatch(actions.setState(0));
				Sentry.captureException(error);
			});
	}
};

const terminateResponse = (store, action) => {
	if (action.type === actions.TERMINATE_RESPONSE) {
		const study = selectors.getStudy(store.getState());
		const responseId = selectors.getResponseId(store.getState());
		const responseRID = selectors.getResponseRID(store.getState());
		api.terminateResponse(
            responseId,
            {
                studyUuid: study.uuid,
                audienceUuid: study.audienceUuid,
                token: responseRID,
            },
            study.useServerlessSurvey,
        ).then((response) => {
				if (response.data.redirectUrl) {
					const search = window.location.search.substring(1);
					const urlParams = search
						? JSON.parse(
								`{"${decodeURI(search)
									.replace(/"/g, '\\"')
									.replace(/&/g, '","')
									.replace(/=/g, '":"')}"}`,
						  )
						: {};
					urlParams.RID = responseRID;
					const newPreparedUrl = Object.keys(urlParams).reduce(
						(str, key) => str.replace(`{${key}}`, urlParams[key]),
						response.data.redirectUrl,
					);
					window.location.replace(newPreparedUrl);
				} else {
					store.dispatch(actions.setStep('terminated'));
				}
			},
		);
	}
};

/**
 * Study Effects
 */

const checkStudy = (study) => {
	if (study.status !== 'active') {
		return Promise.reject('closed');
	}

	// if (!study.products || !study.products.length) {
	//   return Promise.reject("no-products");
	// }

	return study;
};

/**
 * Question Effects
 */

const jumpToQuestion = (store, action) => {
	if (action.type === actions.JUMP_TO_QUESTION) {
		const { questionId, sectionId } = action.payload;
		const sections = selectors.getSections(store.getState());
		const currentSection = selectors.getCurrentSection(store.getState());

		let sectionIndex = -1;
		let section = currentSection;
		if (currentSection.id !== sectionId) {
			sectionIndex = sections.findIndex((section) => sectionId === section.id);
			section = sections[sectionIndex];
			if (sectionIndex > -1) {
				store.dispatch(actions.setCurrentSectionIndex(sectionIndex));
			}
		} else {
			console.warn('SAME SECTION');
		}

		const previewUiid = selectors.getPreviewUiid(store.getState());
		let { questions } = section;
		if (previewUiid) {
			questions = questions.filter((question) => question.audienceQuestionId === undefined);
		}
		if (questions.length) {
			const questionIndex = questions.findIndex((question) => questionId === question.id);
			store.dispatch(actions.setQuestionIndex(questionIndex));
			if (sectionIndex > -1) {
				store.dispatch(actions.setStep('loading'));
				store.dispatch(actions.setCurrentSectionIndex(sectionIndex));
			} else {
				console.warn('not moving sections');
			}
		} else {
			store.dispatch(actions.setNextSection(true));
		}
	}
};

/**
 * Section Effects
 */

const jumpToSection = (store, action) => {
	if (action.type === actions.JUMP_TO_SECTION) {
		const { sectionId } = action.payload;
		const sections = selectors.getSections(store.getState());
		const sectionIndex = sections.findIndex((section) => sectionId === section.id);
		if (sectionIndex > -1) {
			store.dispatch(actions.setStep('loading'));

			// setCurrentSectionIndex includes an actions.setStep of its own
			// this timeout is to ensure React does not batch these two state updates together
			// as components are relying on the loading state between sections
			setTimeout(() => {
				store.dispatch(actions.setCurrentSectionIndex(sectionIndex));
			}, 0);
		}
	}
};

const setNextSection = (store, action) => {
	if (action.type === actions.SET_NEXT_SECTION) {
		const currentSectionIndex = selectors.getCurrentSectionIndex(store.getState());
		const sections = selectors.getSections(store.getState());
		const { skipTimeout } = action.payload;
		if (currentSectionIndex < sections.length - 1) {
			store.dispatch(actions.setStep('loading'));
			if (skipTimeout) {
				store.dispatch(actions.setCurrentSectionIndex(currentSectionIndex + 1));
			} else {
				setTimeout(() => {
					store.dispatch(actions.setCurrentSectionIndex(currentSectionIndex + 1));
				}, 0);
			}
		} else {
			store.dispatch(actions.setStep('end'));
		}
	}
};

const setCurrentSectionIndex = (store, action) => {
	if (action.type === actions.SET_CURRENT_SECTION_INDEX) {
		const sections = selectors.getSections(store.getState());
		const currentSection = sections[action.payload.currentSectionIndex];
		switch (currentSection.type) {
			case 'monadic_split':
				// Make sure we have questions, otherwise skip
				const { questions: monadicQuestions, products: monadicProducts } = currentSection;

				if (!monadicQuestions || !monadicQuestions.length || !monadicProducts || !monadicProducts.length) {
					store.dispatch(actions.setNextSection(true));
				} else {
					store.dispatch(actions.fetchDistributedSplitProducts(currentSection.id));
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'custom':
				// Make sure we have questions, otherwise skip
				const customQuestions = currentSection.questions;
				if (!customQuestions || !customQuestions.length) {
					console.warn('EMPTY QUESTIONS, SKIPPING', currentSection);
					const study = selectors.getStudy(store.getState());
					const rid = selectors.getResponseRID(store.getState());
					store.dispatch(actions.checkQuotas({ study, rid }));
				} else {
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'statement':
				const { statements } = currentSection;
				if (!statements || !statements.length || !statements[0].text) {
					console.warn('EMPTY STATEMENT, SKIPPING', currentSection);
					store.dispatch(actions.setNextSection(true));
				} else {
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'questions':
				// Make sure we have questions, otherwise skip
				const { questions } = currentSection;

				if (!questions || !questions.length) {
					console.warn('EMPTY QUESTIONS, SKIPPING', currentSection);
					store.dispatch(actions.setNextSection(true));
				} else {
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'swipe':
				// Make sure we have questions, otherwise skip
				const { products } = currentSection;

				if (!products || !products.length) {
					console.warn('EMPTY PRODUCTS, SKIPPING', currentSection);
					store.dispatch(actions.setNextSection(true));
				} else {
					store.dispatch(actions.setStep('sections'));
				}
				break;
			case 'text-ai':
				const textAiResults = action.payload.data;
				if (textAiResults) {
					store.dispatch(actions.setResults(textAiResults));
				}
				store.dispatch(actions.setStep('text-ai'));
				break;
			case 'open-ended-pledge':
				store.dispatch(actions.setStep('sections'));
				break;
			case 'red-herring':
				store.dispatch(actions.setStep('sections'));
				break;
			case 'link_routing':
				store.dispatch(actions.setStep('sections'));
				break;
			// case 'end':
			// 	const endResults = action.payload.data;
			// 	if (endResults) {
			// 		store.dispatch(actions.setResults(endResults));
			// 	}
			// 	store.dispatch(actions.setStep('end'));
			// 	break;
			default:
				// todo - add fallback to error
				console.error('Invalid section type');
				store.dispatch(actions.setStep('error'));
				return null;
		}
	}
};

/**
 * Data Effects
 */

const getRedHerringSections = (sections, testMode, provider) => {
	const redHerringSections = [];
	if (sections && !testMode && provider === 'marketplace') {
		const containsOpenEndedQuestions = sections.some(
			(section) => section.questions && section.questions.some((question) => question.style === 'open-ended'),
		);
		if (containsOpenEndedQuestions) {
			redHerringSections.push({
				name: 'Open ended Pledge',
				type: 'open-ended-pledge',
			});
		}

		redHerringSections.push({
			name: 'Red Herring',
			type: 'red-herring',
		});
	}
	return redHerringSections;
};

const fetchData = (store, action) => {
	if (action.type === actions.FETCH_DATA) {
		const { studyId, previewUuid, currentSectionIndex, audienceUuid, language, uuid } = action.payload;
		const urlParams = misc.getAllUrlParams();
		const qualifiers = urlParams && urlParams.q ? urlParams.q : [];
		store.dispatch(actions.setQualifiers(qualifiers));
		let { rnid } = action.payload;
		Promise.resolve()

			/* Fetch study */
			.then(() => api.getStudy(studyId, language, audienceUuid, previewUuid, uuid))

			/* Check it for being open and have products */
			.then((study) => (study.previewUuid ? nopeFunction(study) : checkStudy(study)))

			/* Set title */
			// .then((study) => ((document.title = study.name), study))

			/* for single deploy studies, generate RID */
			.then((study) => {
				if (!rnid && (previewUuid || study.previewUuid)) {
					rnid = `upsiide-preview-${Math.floor(Math.random() * 10000)}-${Date.now()}`;
				}
				if (
					!rnid &&
					(!!study.settings.allowSingleDeploy ||
						!!study.audienceAllowSingleDeploy ||
						!previewUuid ||
						!study.previewUuid)
				) {
					rnid = `upsiide-${Math.floor(Math.random() * 100)}-${Date.now()}`;
				}
				if (!rnid) {
					console.error('no RID');
					throw new Error('INVALID_RNID');
				}
				store.dispatch(actions.setResponseRID(rnid));
				datadogRum.setUser({
					id: rnid,
				});
				store.dispatch(actions.setStudy(study));
				return study;
			})

			/* Create a response */
			.then((study) =>
				study.previewUuid
					? study
					: api
							.createResponse(study, rnid, study.audienceUuid, uuid)

							/* Check the response for whether it's already complete */
							.then((study) => (study.previewUuid ? nopeFunction(study) : checkResponse(study)))
							.then((response) => {
								if (response && response.redirectUrl) {
									console.warn('createResponse has redirect');
									setTimeout(() => {
										window.location.href = response.redirectUrl;
									}, 50);
								} else {
									console.warn('no createResponse redirect');
								}
								store.dispatch(actions.setResponseId(response.id));
								return study;
							}),
			)

			/* Set sections and proceed to first one */
			.then((study) => {
				const { sections } = study;
				if (sections && !study.previewUuid && study.provider === 'marketplace') {
					const redHerringSections = getRedHerringSections(sections, study.previewUuid, study.provider);
					study.sections = [...redHerringSections, ...sections];
				}
				//* ****** */
				store.dispatch(actions.setSections(study.sections));
				const urlParams = misc.getAllUrlParams();
				const previewUuidExists = selectors.getPreviewUiid(store.getState());
				const sectionIdExists = urlParams.hasOwnProperty('sectionId');
				const questionIdExists = urlParams.hasOwnProperty('questionId');
				if (previewUuidExists && sectionIdExists) {
					const urlParamsSectionId = Number(urlParams.sectionId);
					if (questionIdExists) {
						const urlParamsQuestionId = Number(urlParams.questionId);
						store.dispatch(actions.jumpToQuestion(urlParamsQuestionId, urlParamsSectionId));
					} else {
						store.dispatch(actions.jumpToSection(urlParamsSectionId));
					}
				} else {
					store.dispatch(actions.setCurrentSectionIndex(currentSectionIndex));
				}
				return study;
			})
			.then((study) => {
				if (study.previewUuid || study.provider !== 'marketplace') return study;
				store.dispatch(actions.validateRespondent(study, audienceUuid, study.previewUuid, rnid));
				return study;
			})
			/* Put error to the state */
			.catch((error) => {
				console.log(error);
				store.dispatch(actions.setState(error));

				Sentry.withScope((scope) => {
					if (error?.response?.data?.message === 'INVALID_AUDIENCE_STATUS' || error === 'completed') {
						scope.setLevel('info');
						const errorMessage = error === 'completed' ? 'Audience already completed' : 'Invalid Audience';
						const newError = new Error(errorMessage);
						newError.cause = error;
						Sentry.captureException(newError);
					} else {
						Sentry.captureException(error);
					}
				});
			});
	}
};

const RESEARCH_DEFENDER_IGNORED_ERRORS = ['ETIMEDOUT', 'ERR_NETWORK'];

const validateRespondent = async (store, action) => {
	if (action.type === actions.VALIDATE_RESPONDENT) {
		try {
			store.dispatch(actions.setValidateResponseLoading(true));
			const { study, audienceUuid, testMode, rnid } = action.payload;
			if (testMode || study.provider !== 'marketplace') return;
			let tokenData = null;

			try {
				const {
					data: {
						results: [result],
					},
				} = await researchDefenderApi.getToken();

				const token = result ? result.token : null;

				tokenData = token;
			} catch (e) {
				Sentry.captureException(e);
				if (!RESEARCH_DEFENDER_IGNORED_ERRORS.includes(e.code)) {
					throw Error(e);
				}
			}

			let activityStatistics = [
				{
					Activity: {
						Token: { Count: undefined, Average: undefined },
					},
				},
			];

			try {
				const {
					data: { Statistics },
				} = await researchDefenderApi.activity(audienceUuid, rnid);

				activityStatistics = Statistics;
			} catch (e) {
				Sentry.captureException(e);
				if (!RESEARCH_DEFENDER_IGNORED_ERRORS.includes(e.code)) {
					throw Error(e);
				}
			}

			const [
				{
					Activity: {
						Token: { Count: ipCount, Average: ipAverage },
					},
				},
			] = activityStatistics;

			const responseId = selectors.getResponseId(store.getState());
			const respondentToken = selectors.getResponseRID(store.getState());
			const data = {
				token: tokenData,
				ipCount: ipCount || 0,
				ipAverage: ipAverage || 0,
				...(study.useServerlessSurvey ? {} : { responseId }),
				actionTaken: 'NO_ACTION',
				fullUrl: window?.location?.href,
			};

			const response = await api.responseQualityLogger(study, audienceUuid, respondentToken, data);
			if (response?.data?.redirectUrl) {
				window.location.replace(response?.data?.redirectUrl);
			}
			store.dispatch(actions.setValidateResponseLoading(false));
		} catch (error) {
			console.log('catch', error);
			store.dispatch(actions.setValidateResponseLoading(false));
			store.dispatch(actions.setState('error'));
			store.dispatch(actions.setStep('terminated'));

			Sentry.captureException(error);
		}
	}
};

const pushData = async (store, action) => {
	if (action.type === actions.PUSH_DATA) {
		const { force } = action.payload;
		let { audienceUuid } = action.payload;
		const state = store.getState();
		const responseId = selectors.getResponseId(state);
		const results = selectors.getResults(state);
		const answers = selectors.getAnswers(state);
		const study = selectors.getStudy(state);
		const sentimentText = selectors.getSentimentText(state);
		const sentimentTags = selectors.getSentimentTags(state);
		const qualifiers = selectors.getQualifiers(state);
		const { settings } = study;
		const winningProductId = settings.sendWinningProduct ? results.best : undefined;
		const { interests, commitments } = results;
		const data = {
			studyUuid: study.uuid,
			interests,
			commitments,
			answers,
			winningProductId,
			sentimentText,
			sentimentTags,
		};
		const urlParams = misc.getAllUrlParams();

		audienceUuid = audienceUuid || study.audienceUuid;
		(study.previewUuid ? Promise.resolve() :
			api.closeResponse(responseId, data, audienceUuid, qualifiers, study))
			.then((data) => {
				store.dispatch(actions.setState(true));

				if (data && data.redirectUrl) {
					console.warn('Response has redirect');
					setTimeout(() => {
						window.location.href = data.redirectUrl;
					}, 3000);
				} else {
					// Typical engineered redirection
					console.warn('attempt redirect');
					store.dispatch(actions.attemptToRedirect());
				}
			})
			.catch((error) => {
				store.dispatch(actions.setState(force ? 1 : 0));

				Sentry.withScope((scope) => {
					if (error?.response?.data?.message === 'INVALID_AUDIENCE_STATUS') {
						scope.setLevel('info');
						const newError = new Error('Invalid Audience');
						newError.cause = error;
						Sentry.captureException(newError);
					} else {
						Sentry.captureException(error);
					}
				});
			});
	}
};

const fetchDistributedSplitProducts = async (store, action) => {
	if (action.type === actions.FETCH_DISTRIBUTED_SPLIT_PRODUCTS) {
		try {
			store.dispatch(actions.setDistributedSplitProductsLoading(true));
			const { sectionId } = action.payload;
			const state = store.getState();
			const study = selectors.getStudy(state);
			const language = study.currentLanguage;
			const { audienceUuid, useServerlessSurvey } = study;
			const responseId = selectors.getResponseId(state);
			const rid = selectors.getResponseRID(state);
			const answers = selectors.getAnswers(state);
			const urlParams = misc.getAllUrlParams();
			const qualifiers =
				(urlParams && urlParams.q)?.map((qualifier) => ({
					questionId: Number(qualifier.name),
					value: qualifier.value,
				})) || [];

			const quotaAnswerObject = [];

			Object.keys(answers).forEach((questionId) => {
				// Array is always MS and we can ignore these
				const answer = answers[questionId];
				if (Array.isArray(answer?.value) || answer?.sectionId) return;

				const splitQuestionId = questionId.split('-')[0];
				quotaAnswerObject.push({
					questionId: Number(splitQuestionId),
					type: answer?.type,
					value: Number(answer?.value),
					attributeId: answer?.attributeId,
				});
			});

			/* Fetch distributed Idea Split products */
			const { data: distributedSplitProducts } = await api.getResponseSplitSectionProducts(
				study,
				sectionId,
				responseId,
				language,
				audienceUuid || null,
				rid,
				quotaAnswerObject?.length && useServerlessSurvey ? quotaAnswerObject : [],
				qualifiers?.length && useServerlessSurvey ? qualifiers : [],
			);

			const newStudySections = study.sections;
			const splitSectionId = newStudySections.findIndex(
				(section) => section.id === distributedSplitProducts.sectionId,
			);

			if (useServerlessSurvey) {
				// Serverless survey only returns the list of IDs so we need to use that as the filter instead of replacing content.
				newStudySections[splitSectionId].products = shuffle(
					newStudySections[splitSectionId]?.products?.filter((product) =>
						distributedSplitProducts?.products?.includes(product.id),
					),
				);
			} else {
				newStudySections[splitSectionId].products = shuffle(distributedSplitProducts.products);
			}

			study.sections = newStudySections;

			/* Set study products */
			const studyProducts = [];
			study.sections.forEach((section) => {
				if (!section.products || !section.products.length) {
					return;
				}

				section.products.forEach((product) => {
					const alreadyInProductsList = studyProducts.find((p) => p.id === product.id);
					if (!alreadyInProductsList) {
						studyProducts.push(product);
					}
				});
			});
			study.products = studyProducts;

			/* Put study to the store */
			store.dispatch(actions.setStudy(study));
			store.dispatch(actions.setDistributedSplitProductsLoading(false));
		} catch (error) {
			store.dispatch(actions.setDistributedSplitProductsLoading(false));
			store.dispatch(actions.setState('error'));
			store.dispatch(actions.setStep('terminated'));
			Sentry.captureException(error);
		}
	}
};

/**
 * Redirect Effects
 */

const attemptToRedirect = (store, action) => {
	if (action.type === actions.ATTEMPT_TO_REDIRECT) {
		const state = store.getState();
		const RID = selectors.getResponseRID(state);
		const study = selectors.getStudy(state);
		const results = selectors.getResults(state);
		const { settings } = study;
		const { redirectUrl } = settings;

		const allProducts = study.sections.reduce((currentValue, section) => {
			if (section?.products) {
				return [...currentValue, ...section.products];
			}

			return currentValue;
		}, []);

		const winningProductId =
			settings.sendWinningProduct && results && results.best
				? (allProducts.filter((product) => product.id == results.best).pop() || {}).localProductId
				: 0;
		const likedProducts =
			settings.sendLikedProducts && results && results.interests && results.interests.length
				? results.interests
						.filter(({ interest }) => interest)
						.map(({ id }) => id)
						// Transform IDs to LOCAL PRODUCT IDs
						.map((id) => (allProducts.filter((product) => product.id == id).pop() || {}).localProductId)
						.filter((localProductId) => !!localProductId)
						.join(',') || 0 // 0 returned if interests but all dislikes
				: 0;

		if (redirectUrl) {
			const search = location.search.substring(1);

			const urlParams = search
				? JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`)
				: {};
			urlParams.RID = RID;

			let newPreparedUrl = Object.keys(urlParams).reduce(
				(str, key) => str.replace(`{${key}}`, urlParams[key]),
				redirectUrl,
			);

			// If {winner} present replace it regardless of if there was a winner
			if (newPreparedUrl.includes('{winner}')) {
				newPreparedUrl = newPreparedUrl.replace('{winner}', winningProductId);
			} else if (winningProductId) {
				// Only append if there is a winner
				if (!newPreparedUrl.includes('?')) {
					newPreparedUrl = `${newPreparedUrl}?winner=${winningProductId}`;
				} else {
					newPreparedUrl = `${newPreparedUrl}&winner=${winningProductId}`;
				}
			}

			// If {liked} present replace it regardless of if there was a winner
			if (newPreparedUrl.includes('{liked}')) {
				newPreparedUrl = newPreparedUrl.replace('{liked}', likedProducts);
			} else if (likedProducts) {
				// Only append if there is liked Products
				if (!newPreparedUrl.includes('?')) {
					newPreparedUrl = `${newPreparedUrl}?liked=${likedProducts}`;
				} else {
					newPreparedUrl = `${newPreparedUrl}&liked=${likedProducts}`;
				}
			}

			window.location.href = newPreparedUrl;
		}
	}
};

/**
 * State Effects
 */

const saveState = (store, action) => {
	if (action.type === actions.SAVE_STATE) {
		const state = selectors.getMainState(store.getState());
		localStorage.setItem('saved-state', JSON.stringify(state));
	}
};

const loadState = (store, action) => {
	if (action.type === actions.LOAD_STATE) {
		const savedState = localStorage.getItem('saved-state');
		if (savedState) {
			store.dispatch(actions.replaceState(JSON.parse(savedState)));
		}
	}
};

const saveAndLoadUserData = (store, action) => {
	if (action.type === actions.SAVE_DATA_TO_SERVERLESS) {
		const { token, audienceUuid, studyUuid, data, redirectUrl } = action.payload;

		const {
			RID,
			answers,
			currentSectionIndex,
			questionIndex,
			responseId,
			results,
			sentimentTags,
			sentimentText,
			state,
			step,
			qualifiers,
			study,
		} = data.main;

		const newData = {
			main: {
				RID,
				answers,
				currentSectionIndex,
				questionIndex,
				responseId,
				results,
				sentimentTags,
				sentimentText,
				state,
				step,
				qualifiers,
				language: study.currentLanguage,
			},
		};
		Promise.all([
			api.saveUserData({ token, audienceUuid, studyUuid: studyUuid.toString(), data: newData }),
			new Promise((resolve) => setTimeout(resolve, 5000)),
		])
			.then(() => {
				window.location.replace(redirectUrl);
			})
			.catch(() => {
				store.dispatch(actions.setState('error'));
				store.dispatch(actions.setStep('terminated'));
				Sentry.captureException(new Error('SERVERLESS POST API FAILURE'));
			});
	}
};

const getAndLoadUserData = async (store, action) => {
	if (action.type === actions.GET_USER_DATA) {
		const { token, audienceUuid, studyId, uuid } = action.payload;
		api.getUserData({ token, audienceUuid })
			.then(async ({ data }) => {
				const nextStore = data?.data?.main;
				const study = await api.getStudy(studyId, nextStore.language, audienceUuid, previewUuid, uuid);
				const { currentLanguage, previewUuid } = study;
				// localization
				i18n.changeLanguage(currentLanguage);
				window.dispatchEvent(new Event('changeLanguage'));
				const initializeNextStore = () => {
					let { currentSectionIndex } = nextStore;
					let { sections, previewUuid } = study;

					const redHearringSections = getRedHerringSections(sections, previewUuid, study.provider);
					sections = [...redHearringSections, ...sections];
					if (currentSectionIndex < sections?.length - 1) {
						currentSectionIndex += 1;
						const currentStore = store.getState().main;
						store.dispatch(
							actions.setMainStore({
								...currentStore,
								...nextStore,
								sections,
								study,
							}),
						);
						store.dispatch(actions.setCurrentSectionIndex(currentSectionIndex));
					} else {
						const currentStore = store.getState().main;
						store.dispatch(
							actions.setMainStore({
								...currentStore,
								...nextStore,
								sections,
								study,
								currentSectionIndex,
								step: 'end',
							}),
						);
					}
				};
				// checking if the response is already submitted in survey mode
				if (previewUuid) {
					initializeNextStore();
				} else {
					api.createResponse(study, token, audienceUuid)
						.then(previewUuid ? nopeFunction : checkResponse)
						.then((response) => {
							initializeNextStore();
							if (response && response.redirectUrl) {
								console.warn('createResponse has redirect');
								setTimeout(() => {
									window.location.href = response.redirectUrl;
								}, 50);
							} else {
								console.warn('no createResponse redirect');
							}
							store.dispatch(actions.setResponseId(response.id));
						})
						.catch((err) => {
							// setting study to attempt redirect when survey is already submitted
							console.log(err);
							store.dispatch(actions.setStudy(study));
							store.dispatch(actions.setState(err));
						});
				}
			})
			.catch((er) => {
				console.log(er);
				const currentSectionIndex = selectors.getCurrentSectionIndex(store.getState());
				const previewUiid = selectors.getPreviewUiid(store.getState());
				store.dispatch(actions.fetchData(studyId, token, audienceUuid, previewUiid, currentSectionIndex));
				Sentry.captureException(new Error('SERVERLESS GET API FAILURE'));
			});
	}
};

const checkQuotas = async (store, action) => {
	if (action.type === actions.CHECK_QUOTAS) {
		const { study, rid } = action.payload;
		const urlParams = misc.getAllUrlParams();
		const qualifiers =
			(urlParams && urlParams.q)?.map((qualifier) => ({
				questionId: Number(qualifier.name),
				value: qualifier.value,
			})) || [];

		if (!qualifiers?.length || !study?.hasQuotas || !study?.enforceSampleLimit)
			return store.dispatch(actions.setNextSection(true));

		const response = await api.validateQuestionsQuota(study, study.audienceUuid, [], rid, qualifiers);

		if (response?.data?.isOverQuota == true) {
			store.dispatch(actions.setDisqualified([], study.audienceUuid, 'quota-reached', true));
			store.dispatch(actions.setStep('terminated'));
			if (response?.data?.redirectUrl) {
				// setSec // seta a section pra "terminated"
				return setTimeout(() => {
					window.location.replace(response?.data?.redirectUrl);
				}, 3000);
			}
		} else {
			// Set answers as empty array so we don't save them twice!
			if (!study.useServerlessSurvey) {
				store.dispatch(actions.setAnswers([]));
				store.dispatch(actions.setQualifiers([]));
			}
			store.dispatch(actions.setNextSection(true));
		}
	}
};

export default [
	// Data
	fetchData,
	pushData,
	fetchDistributedSplitProducts,
	setDisqualified,

	// State
	saveState,
	loadState,

	// Redirects
	attemptToRedirect,

	// Section
	jumpToSection,
	jumpToQuestion,
	setCurrentSectionIndex,
	setNextSection,

	// Response
	terminateResponse,
	validateRespondent,

	saveAndLoadUserData,
	getAndLoadUserData,

	checkQuotas,
];
