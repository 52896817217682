import React, { useEffect } from "react";
import { RadioGroup, RadioButton } from '@upsiide/ui-components';
import EmojiButton from './EmojiButton';
import "./styles.scss";

const pointColor = "#01C39D";
const rootColor = "#A2ABB8";

const toggle = (c, v) =>
	c.indexOf(v) > -1 ? c.filter($v => $v !== v) : c.concat([v]);

export default ({ t, disabled, value, options, onChange, multipleSelect }) => {
	const handleChange = v => onChange(toggle(value || [], v));
	const checked = (option) => (value || []).indexOf(option.id.toString()) > -1;

	useEffect(() => {
		const pageContainer = document.querySelector('.scroll-container');
		if (pageContainer) {
			pageContainer.style.scrollBehavior = 'auto';
			pageContainer.scrollTo(0, 0);
		}
		// Reset scroll container 
		const scrollContainer = document.querySelector(`[class*='scroll-container']`);
		if (scrollContainer) {
			scrollContainer.classList.remove('scrolled-to-bottom');
			scrollContainer.classList.remove('remove-scroll-indicator');
		}
	}, []);

	return (
		<RadioGroup className={`question-emoji-select custom-radio-group`}>
			{options.map(option => (
				<RadioButton
					onClick={multipleSelect ? handleChange : onChange}
					disabled={disabled}
					checked={checked(option)}
					key={option.id}
					value={option.id.toString()}
				>
					<div className={checked(option) ? 'selected-emoji-item' : 'emoji-item'}>
						<div className='emoji-container'>
							<EmojiButton t={t} label={option.label || option.value} unchecked={!checked(option)} />
						</div>
					</div>
				</RadioButton>
			))}
		</RadioGroup>
	);
};
