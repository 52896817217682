import React from 'react';

export default () => (
	<svg
		version="1.1"
		id="Capa_1"
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 512 512"
	>
		<circle fill="#FFE17D" cx="256" cy="256" r="256" />
		<path
			fill="#FFD164"
			d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.376-161.463
	C36.863,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.626-94.538
	C410.573,453.317,354.389,474.839,293.161,474.839z"
		/>
		<path
			fill="#9C6846"
			d="M255.941,319.79c-46.603,0-91.087-5.012-131.899-14.073c-5.286-1.174-9.842,3.942-8.212,9.273
	c18.821,61.549,74.376,106.199,140.11,106.199s121.289-44.65,140.11-106.199c1.63-5.332-2.925-10.447-8.212-9.273
	C347.028,314.778,302.544,319.79,255.941,319.79z"
		/>
		<path
			fill="#7D5046"
			d="M256.019,355.097c43.239,0,85.891-4.091,127.861-11.415c4.934-9.049,9.09-18.613,12.171-28.69
	c1.631-5.332-2.925-10.447-8.212-9.274c-40.811,9.06-85.295,14.073-131.898,14.073s-91.088-5.012-131.899-14.073
	c-5.286-1.173-9.842,3.942-8.212,9.274c3.078,10.066,7.229,19.621,12.156,28.662C170.011,350.997,212.721,355.097,256.019,355.097z"
		/>
		<path
			fill="#E6646E"
			d="M180.694,247.512c14.037-17.648,47.271-65.028,28.317-97.235
	c-7.327-12.45-21.745-19.554-36.098-17.914c-11.657,1.332-21.01,7.456-26.878,15.882c-2.406,3.454-6.764,4.881-10.746,3.52
	c-9.716-3.32-20.88-2.724-31.066,3.099c-12.542,7.17-19.962,21.427-18.503,35.799c3.774,37.179,58.6,55.715,80.358,61.638
	C171.461,253.765,177.221,251.879,180.694,247.512z"
		/>
		<path
			id="SVGCleanerId_0"
			fill="#E6646E"
			d="M331.306,247.512c-14.037-17.648-47.271-65.028-28.317-97.235
	c7.327-12.45,21.745-19.554,36.098-17.914c11.657,1.332,21.01,7.456,26.878,15.882c2.406,3.454,6.764,4.881,10.746,3.52
	c9.716-3.32,20.88-2.724,31.066,3.099c12.542,7.17,19.962,21.427,18.503,35.799c-3.774,37.179-58.6,55.715-80.358,61.638
	C340.539,253.765,334.779,251.879,331.306,247.512z"
		/>
		<path
			fill="#DC4655"
			d="M187.895,230.736c-21.758-5.923-76.584-24.459-80.358-61.638c-0.589-5.801,0.329-11.558,2.386-16.863
	c-1.921,0.725-3.83,1.559-5.7,2.629c-12.542,7.169-19.962,21.427-18.503,35.799c3.774,37.179,58.6,55.715,80.358,61.638
	c5.383,1.465,11.143-0.421,14.616-4.788c3.228-4.058,7.48-9.74,11.871-16.398C191.016,231.228,189.446,231.158,187.895,230.736z"
		/>
		<g>
			<path
				id="SVGCleanerId_0_1_"
				fill="#E6646E"
				d="M331.306,247.512c-14.037-17.648-47.271-65.028-28.317-97.235
		c7.327-12.45,21.745-19.554,36.098-17.914c11.657,1.332,21.01,7.456,26.878,15.882c2.406,3.454,6.764,4.881,10.746,3.52
		c9.716-3.32,20.88-2.724,31.066,3.099c12.542,7.17,19.962,21.427,18.503,35.799c-3.774,37.179-58.6,55.715-80.358,61.638
		C340.539,253.765,334.779,251.879,331.306,247.512z"
			/>
		</g>
		<path
			fill="#DC4655"
			d="M359.037,242.464c-14.037-17.648-47.271-65.029-28.317-97.235c2.93-4.979,7.025-9.052,11.766-12.104
	c-1.145-0.234-2.205-0.626-3.4-0.762c-14.353-1.64-28.771,5.464-36.098,17.914c-18.954,32.207,14.28,79.588,28.317,97.235
	c3.473,4.366,9.233,6.253,14.616,4.788c4.814-1.31,11.288-3.279,18.428-5.889C362.334,245.515,360.465,244.26,359.037,242.464z"
		/>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
	</svg>
);
