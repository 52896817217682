export default {
	'this-survey-does-not-exist': 'Vi beklagar, undersökningen finns inte.',
	'survey-closed': 'Vi beklagar, undersökningen är inte längre öppen.',
	'survey-no-products': 'Undersökningen har inga produkter.',
	'survey-no-products-summary': 'Om du äger den här undersökningen kan du skapa produkter i instrumentpanelen.',
	'survey-completed': 'Du har redan slutfört undersökningen.',
	'summary-placeholder': 'Om du misstänker att något är fel ska du kontakta avsändaren till den här länken.',
	'gone-wrong': 'Hmmm, något är fel.',
	'try-again': 'Försök igen eller prova senare.',
	'thank-you': 'Tack',
	'thank-you-summary': 'Undersökningen är slutförd och dina svar har skickats in.',
	'thank-you-summary-redirect': 'Du omdirigeras om några sekunder.',
	'page-not-found': 'Något är fel',
	'page-not-found-placeholder': 'Oj, länken fungerar inte',
	'tap-your-favorite': 'Klicka på bockmarkeringen i dina favoriter',
	or: 'eller',
	'get-started-with-questions': 'En fråga för att komma igång',
	continue: 'Fortsätt',
	retry: 'Försök igen',
	'tell-us-how-you-feel-about-this-product': 'Berätta vad du tycker om den här produkten',
	begin: 'Börja',
	'how-would-you-describe-this-product-to-a-friend': 'Hur skulle du beskriva produkten för en vän?',
	'type-your-response-here': 'Skriv in ditt svar härâ€¦',
	'sum-of-total-characters': '${SUM} av ${TOTAL} tecken',
	edit: 'Redigera',
	remove: 'Ta bort',
	'how-do-you-feel-about-this-part': 'Vad tycker du om den här delen?',
	'tap-on-an-area-and-choose-an-emoji': 'Klicka på ett område och välj en emoji',
	'im-finished': 'Jag är färdig',
	maintenance: 'Underhåll',
	'maintenance-mode': 'Upsiide genomgår för närvarande underhåll. Prova igen snart.',
	'please-specify': 'Specificera',
	'monadic-split-statement':
		'På nästa skärm visar vi en idé. Granska den noga eftersom vi kommer att ställa frågor om den.',
	disapproval: 'Ogillande',
	anger: 'Ilska',
	sad: 'Ledsen',
	joy: 'Glädje',
	love: 'Kärlek',
	trust: 'Tillit',
	hope: 'Hopp',
	proud: 'Stolt',
	amazed: 'Förbluffad',
	surprised: 'Förvånad',
	guilt: 'Skuld',
	curious: 'Nyfiken',
	jealous: 'Svartsjuk',
	anticipation: 'Förväntan',
	optimistic: 'Optimistisk',
	anxious: 'Orolig',
	fear: 'Rädsla',
	despair: 'Hopplöshet',
	disbelief: 'Misstro',
	cynicism: 'Cynism',
	regret: 'Ånger',
	disgust: 'Avsky',
	annoyed: 'Irriterad',
	aggressive: 'Aggressiv',
	shocked: 'Chockad',
	choose: 'Välj {{amount}}',
	'up-to': 'Välj upp till {{amount}}',
	unlimited: 'Välj så många som du vill',
	'single-select': 'Välj en',
	number: 'Ange en siffra',
	'number-between-min-max': 'Ange en siffra mellan {{min}} och {{max}}',
	'number-min': 'Ange en siffra, {{min}} eller högre',
	'number-max': 'Ange en siffra, {{max}} eller lägre',
	'open-ended-pledge':
		'Den här undersökningen innehåller frågor där du ombeds att svara med dina egna ord. Dina svar kontrolleras automatiskt för att tillse att de uppfyller våra kvalitetskrav. Samtycker du till att fortsätta? ',
	'open-ended-pledge-accept': 'Ja, jag samtycker',
	'open-ended-pledge-decline': 'Nej, jag samtycker inte',
	'rh-1-label': 'Vilket av följande är inte en sport?',
	'rh-1-o-1': 'Basket',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Kaka',
	'rh-1-o-4': 'Ishockey',
	'rh-2-label': 'Vad är 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Vilket av följande är en frukt?',
	'rh-3-o-1': 'Mjölk',
	'rh-3-o-2': 'Choklad',
	'rh-3-o-3': 'Ägg',
	'rh-3-o-4': 'Äpple',
	'open-end-max-length': 'Tyvärr, det angivna svaret är för långt',
	'heatmap-click-image': 'Klicka på bilden för att lägga till en kommentar',
	'heatmap-click-image-no-comments': 'Klicka var som helst på bilden',
	'heatmap-click-image-product-card': 'Klicka på kortet för att lägga till en kommentar ',
	'heatmap-click-image-product-card--no-comment': 'Klicka var som helst på kortet',
	'heatmap-type-comment': 'Skriv en kommentar',
	cancel: 'Avbryt',
	skip: 'Hoppa',
	ranked: 'Placerad',
	top: 'Topp',
	'tap-or-drag': 'Tryck eller dra och släpp för att rangordna',
	unranked: 'Orankad',
	reset: 'Återställa',
	'no-more-options': 'Inga fler alternativ att rangordna',
	'rank-list-full': 'Ranglistan är full, ta bort ett av dina val',
};
