import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import cn from 'src/utilities/bem-cn';
import useDebounceCallback from 'src/utilities/debounceCallback';
import Button from 'src/components/new/Button';
import ProgressBar from 'src/components/new/ProgressBar';
import QuestionHelperText from 'src/components/shared/QuestionHelperText';
import ImageZoom from 'src/components/new/ImageZoom';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';
import Input from 'src/components/shared/Input';
import * as misc from 'src/utilities/misc';

// Localization

import MultiSelect from '../MultiSelect';
import SingleSelect from '../SingleSelect';
import './styles.scss';

// create your forceUpdate hook
function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue((value) => value + 1); // update state to force render
	// A function that increment 👆🏻 the previous state like here
	// is better than directly setting `setValue(value + 1)`
}
const attributeVariants = {
	initial: {
		x: 300,
		opacity: 0,
		transition: {
			duration: 0,
		},
	},
	completed: {
		x: 0,
		opacity: 1,
		staggerChildren: 100,
		transition: {
			duration: 0.25,
		},
	},
};

const className = 'grid-question';
const el = (name, mod) => cn(className, name, mod);

const Grid = ({
	question,
	renderAsset,
	t,
	answers,
	setAnswers,
	currentSection,
	handleResults,
	productId,
	productOrder,
	getHtmlLabel,
	onImageLoad,
}) => {
	const { attributes, style, options, hasOtherSpecifyOption, hasNoneOfTheAboveOption, randomizeOptions } = question;

	const scrollContainerClass = 'scroll-container';
	const [contentOverflow, setContentOverflow] = useState(false);
	const [orderedAttributes, setOrderedAttributes] = useState(attributes);
	const [buttonEnabled, setButtonEnabled] = useState(false);
	// Attribuets
	const [totalAttributeCount, setTotalAttributeCount] = useState(attributes.length || 0);
	const [activeAttributeCount, setActiveAttributeCount] = useState(0);
	const [currentAttribute, setCurrentAttribute] = useState(orderedAttributes[activeAttributeCount] || null);
	const currentAttributeRef = useRef(orderedAttributes[activeAttributeCount] || null);

	const [answerKey, setAnswerKey] = useState(
		`${question.id}-${currentAttribute.id}${productId !== undefined ? productId : ''}`,
	);
	const [value, setValue] = useState(answers && answers[answerKey] ? answers[answerKey].value : 0);

	// None of the above
	const [noneOptionId, setNoneOptionId] = useState(0);

	// Other
	const [otherOptionId, setOtherOptionId] = useState(0);
	const [otherValue, setOtherValue] = useState('');
	const [isOtherSelected, setisOtherSelected] = useState(false);
	const [otherElementRef, setOtherElementRef] = useState(false);

	// cached options for randomization
	const [orderedOptions, setOrderedOptions] = useState([]);

	// Debounce settings
	const [updateDebounceTimer, debounceCallback] = useDebounceCallback();

	// Settings
	const multipleSelect = misc.getQuestionSetting(question, 'multi-select') === 'true';
	const selectLimitRange = misc.getQuestionSetting(question, 'limit-type');
	const selectLimit = misc.getQuestionSetting(question, 'limit');

	const randomizeAttributes = misc.getQuestionSetting(question, 'randomize_attributes') === 'true';

	const imageOnly = misc.getQuestionSetting(question, 'attribute_image_only') === 'true';
	const imageCrop = misc.getQuestionSetting(question, 'attribute_image_cropping');
	const imageLayout = misc.getQuestionSetting(question, 'attribute_layout');
	const buttonHeight = 68;
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		setTimeout(() => {
			const baseClassName = productId ? 'monadic-split-container' : 'questions-container';
			const container = document.querySelector(`.${baseClassName}`);
			if (container) {
				let isContentOverflowing = container.clientHeight - buttonHeight / 2 > window.innerHeight;
				isContentOverflowing =
					isContentOverflowing || container.scrollHeight - buttonHeight / 2 > window.innerHeight;
				if (productId) {
					isContentOverflowing = container.scrollHeight - buttonHeight / 2 > window.innerHeight;
				}
				setContentOverflow(isContentOverflowing);
			}
		}, 300);
	}, [productId, question, currentAttribute]);

	useEffect(() => {
		setAnswerKey(`${question.id}-${currentAttribute.id}${productId !== undefined ? productId : ''}`);
	}, [productId, question, currentAttribute]);

	useEffect(() => {
		updateDebounceTimer();
		setValue(answers && answers[answerKey] ? answers[answerKey].value : 0);
	}, [answers, answerKey]);

	useEffect(() => {
		setOtherValue('');
		setisOtherSelected(false);
		setButtonEnabled(false);
	}, [currentAttribute]);

	useEffect(() => {
		setCurrentAttribute(orderedAttributes[activeAttributeCount]);
		currentAttributeRef.current = orderedAttributes[activeAttributeCount];
	}, [orderedAttributes, activeAttributeCount]);

	useEffect(() => {
		const pageContainer = document.querySelector('.scroll-container');
		if (pageContainer) {
			pageContainer.style.scrollBehavior = 'auto';
			pageContainer.scrollTo(0, 0);
		}
		// Reset scroll container
		const scrollContainer = document.querySelector(`[class*='scroll-container']`);
		if (scrollContainer) {
			scrollContainer.classList.remove('scrolled-to-bottom');
			scrollContainer.classList.remove('remove-scroll-indicator');
		}

		const buttonContainer = document.querySelector('.footer-helper');
		let displayStyle = '';
		if (buttonContainer) {
			displayStyle = buttonContainer.style.display;
			buttonContainer.style.display = 'none';
		}
		window.addEventListener('resize', updateOtherElementRef);
		return () => {
			if (buttonContainer) buttonContainer.style.display = displayStyle;
			window.removeEventListener('resize', updateOtherElementRef);
		};
	}, []);

	const updateOtherElementRef = () => {
		setTimeout(() => {
			const otherElement = document.querySelector('#other-please-specify');
			if (otherElement) {
				const scrollContainer = document.querySelector(`.scroll-container`);
				const { top, width, x: left } = otherElement.getBoundingClientRect();
				setOtherElementRef({ top: top + scrollContainer.scrollTop, width, left });
				// Let render cycle happen to inject input field into DOM
				setTimeout(() => {
					const otherInput = document.querySelector('#other-input-field');
					if (otherInput) {
						otherInput.focus();
					}
				});
			}
		}, 100);
	};

	useEffect(() => {
		// Randomize the order of the attributes if turned on
		let sortedAttributes = attributes;
		if (randomizeAttributes) {
			sortedAttributes = [...attributes];

			// Get all fixed indexes, store, take out of the array
			const fixed = [];
			let i;
			for (i = sortedAttributes.length; i--; ) {
				if (sortedAttributes[i].lockOrder) {
					fixed.unshift({ index: i, attribute: sortedAttributes[i] });
					sortedAttributes.splice(i, 1);
				}
			}

			// randomize remaining items
			sortedAttributes.sort(() => Math.random() - 0.5); // Perform your sort

			// Jam fixed ones back in
			for (i = 0; i < fixed.length; i += 1) {
				sortedAttributes.splice(fixed[i].index, 0, fixed[i].attribute);
			}
		} else {
			// console.warn('not randomized', attributes)
		}
		setOrderedAttributes(sortedAttributes);
	}, [attributes, randomizeAttributes]);

	useEffect(() => {
		if (hasOtherSpecifyOption) {
			const otherOption = options.find((option) => option.isOtherSpecify);
			if (otherOption) {
				setOtherOptionId(otherOption.id.toString());
			}
		}

		if (hasNoneOfTheAboveOption) {
			const noneOption = options.find((option) => option.isNoneOfTheAbove);
			if (noneOption) {
				setNoneOptionId(noneOption.id.toString());
			}
		}

		// Randomize the order of the options if turned on
		let sortedOptions = options;
		if (randomizeOptions) {
			sortedOptions = [...options];

			// Get all fixed indexes, store, take out of the array
			const fixed = [];
			let i;
			for (i = sortedOptions.length; i--; ) {
				if (sortedOptions[i].lockOrder) {
					fixed.unshift({ index: i, option: sortedOptions[i] });
					sortedOptions.splice(i, 1);
				}
			}

			// randomize remaining items
			sortedOptions.sort(() => Math.random() - 0.5); // Perform your sort

			// Jam fixed ones back in
			for (i = 0; i < fixed.length; i += 1) {
				sortedOptions.splice(fixed[i].index, 0, fixed[i].option);
			}
		} else {
			// console.warn('not randomized', options)
		}
		setOrderedOptions(sortedOptions);
	}, [hasNoneOfTheAboveOption, hasOtherSpecifyOption, options, question, randomizeOptions]);

	useEffect(() => {
		if (!question) return;
		const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';

		if (!isOptional) return;

		setButtonEnabled(true);
	}, [question, currentAttribute]);

	const scrollToTop = useCallback(() => {
		const pageContainer = document.querySelector('.scroll-container');
		pageContainer.style.scrollBehavior = 'auto';
		pageContainer.scrollTo(0, 0);
	});

	const isSelectQuestionOptional = useCallback(
		(q) => {
			const isOptional = misc.getQuestionSetting(q, 'optional') === 'true';
			if (!isOptional) return;

			const isMultiSelect = misc.getQuestionSetting(q, 'multi-select') === 'true';

			const hasValue = value?.length || (isOtherSelected && otherValue?.length);
			if (hasValue && isMultiSelect) {
				const hasValidUserInputAnswer = misc.checkForValidInputs(q, value, q.style, noneOptionId, otherValue);
				return hasValidUserInputAnswer;
			}

			return isOtherSelected ? otherValue?.length && isOptional : isOptional;
		},
		[value, otherValue],
	);

	useEffect(() => {
		if (!question) return;
		const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
		if (isOptional) {
			const isOptionalQuestion = isSelectQuestionOptional(question);
			setButtonEnabled(isOptionalQuestion);
		}
	}, [answers, isSelectQuestionOptional, question]);

	const userCanSelectAnotherInput = (value) =>
		(!multipleSelect && value.length <= 1 && Array.isArray(value)) ||
		(!multipleSelect && value.length > 0 && !Array.isArray(value)) || // Multi-select setting false - Single Select
		(multipleSelect && !selectLimitRange) || // Multi-select setting true with no limit-type setting and no limit setting - Unlimited multi-select
		(multipleSelect && selectLimitRange === 'unlimited') || // Multi-select setting true with limit-type set to unlimited - Unlimited multi-select
		(multipleSelect && selectLimitRange && !selectLimit && value.length <= 1) || // Multi-select with limit-type setting but no limit setting - Multi-select default limit of 1
		(multipleSelect && selectLimitRange && selectLimit && value.length <= selectLimit); // Multi-select with limit-type setting and limit setting - Multi-select with set select limit
	const handleChange = (value, style, otherValue, otherOptionId, noneIndex) => {
		forceUpdate();
		const attributeId = currentAttribute.id;
		const { id: questionId, style: type } = question;
		const { id: sectionId } = currentSection;

		const newAnswers = {
			...answers,
			[answerKey]: {
				value, // * Any text that is manually input
				attributeId,
				type, // * single-select, multi-select, open-ended
				sectionId, // * Id of the current section
				otherValue,
				otherOptionId,
				productId, // * Id of the current product
				productOrder, // * Order that the product was presented in
			},
		};

		setAnswers(newAnswers);

		let hasValidUserInputAnswer = false;

		if (question.style === 'open-ended') {
			hasValidUserInputAnswer = misc.checkForValidOpenEnded(question, value);
		} else {
			hasValidUserInputAnswer = misc.checkForValidInputs(
				question,
				newAnswers[answerKey].value,
				question.style,
				noneIndex,
				otherValue,
			);
		}

		debounceCallback(() => {
			if (currentAttribute?.id === currentAttributeRef?.current?.id) {
				setButtonEnabled(hasValidUserInputAnswer);
			}
		});
	};

	const change = (value) => {
		let noneIndex = false;

		if (multipleSelect) {
			if (noneOptionId && value.length === 1 && value.includes(noneOptionId)) {
				noneIndex = true;
			} else if (noneOptionId && value.length > 1 && value.includes(noneOptionId)) {
				noneIndex = value.indexOf(noneOptionId);
				if (noneIndex === 0) {
					// Didn't select None
					// was selected, remove it
					value = value.splice(1);
				} else {
					// Selected None
					noneIndex = true;
					value = [noneOptionId];
				}
			}
		}

		if (userCanSelectAnotherInput(value)) {
			let sendOther = isOtherSelected;
			if (otherOptionId && value.includes(otherOptionId)) {
				setisOtherSelected(true);
				if (!isOtherSelected) {
					updateOtherElementRef();
				}
				sendOther = true;
			} else if (otherOptionId) {
				setisOtherSelected(false);
				sendOther = false;
			}
			handleChange(value, style, sendOther ? otherValue : '', sendOther ? otherOptionId : 0, noneIndex);
		} else {
			console.log('Hit max number of selects');
		}
	};

	const currentAttributeTranslation = (attribute) =>
		(attribute.translations && attribute.translations[0].label) || attribute.label || '';

	const onOtherChange = (otherValue) => {
		setOtherValue(otherValue);
		handleChange(value, style, otherValue, otherOptionId);
	};

	const resetScrollContainerClasses = () => {
		setTimeout(() => {
			const scrollContainer = document.querySelector(`[class*='${scrollContainerClass}']`);
			if (scrollContainer) {
				scrollContainer.classList.remove('scrolled-to-bottom');
				scrollContainer.classList.remove('remove-scroll-indicator');
			}
		}, 100);
	};

	const onHandleClick = () => {
		if (activeAttributeCount < totalAttributeCount - 1) {
			setActiveAttributeCount(activeAttributeCount + 1);
			setTimeout(() => {
				scrollToTop();

				resetScrollContainerClasses();
				const baseClassName = productId ? 'monadic-split-container' : 'questions-container';
				const container = document.querySelector(`.${baseClassName}`);
				const isContentOverflowing = container.clientHeight > window.innerHeight;
				setContentOverflow(isContentOverflowing);
			}, 300);
		}
		// Move to next attribute
		else {
			setButtonEnabled(false);
			handleResults(); // Move to next question
			resetScrollContainerClasses();
		}
	};

	return (
		<>
			<div className={isOtherSelected ? el('other-selected') : ''}>
				<div className={el('question-label-image-container')}>
					{getHtmlLabel()}
					{renderAsset()}
				</div>
				<div className="question">
					<ProgressBar activeItem={activeAttributeCount} totalItemsCount={totalAttributeCount} />
					<div className={el('attribute-container')}>
						{!currentAttribute.asset && (
							<div className={el('attribute-solo')}>
								<motion.div
									key={`attribute-animation-${currentAttribute.id}`}
									initial="initial"
									animate="completed"
									variants={attributeVariants}
								>
									{currentAttributeTranslation(currentAttribute)}
								</motion.div>
							</div>
						)}
						{currentAttribute.asset && (
							<motion.div
								className={`${el('attribute-image-container')} ${
									imageLayout === 'sideBySide' && !imageOnly ? el('sideBySide') : ''
								}`}
								key={`attribute-animation-${currentAttribute.id}`}
								initial="initial"
								animate="completed"
								variants={attributeVariants}
							>
								<div className="question-image">
									{imageLayout === 'sideBySide' && !imageOnly && (
										<div className={el('attribute-label')}>
											{currentAttributeTranslation(currentAttribute)}
										</div>
									)}
									<ImageZoom>
										<img
											className={`${el('attribute-image')} ${
												imageCrop === 'crop' ? el('attribute-image-crop') : ''
											}${imageOnly ? ' image-only' : ''}`}
											alt={currentAttributeTranslation(currentAttribute)}
											src={misc.getAssetVariationUrl(currentAttribute, [
												'large',
												'full',
												'medium',
												'thumbnail',
											])}
											onLoad={() => {
												if (typeof onImageLoad === 'function') {
													onImageLoad();
												}
											}}
										/>
									</ImageZoom>
									{imageLayout !== 'sideBySide' && !imageOnly && (
										<div className={el('attribute-label')}>
											{currentAttributeTranslation(currentAttribute)}
										</div>
									)}
								</div>
							</motion.div>
						)}
					</div>
					<QuestionHelperText
						style={question.style}
						multipleSelect={multipleSelect}
						selectLimitRange={selectLimitRange}
						selectLimit={selectLimit}
					/>
					{multipleSelect && (
						<MultiSelect
							question={question}
							value={value}
							onChange={change}
							options={orderedOptions}
							otherSelected={isOtherSelected}
							onOtherChange={onOtherChange}
							currentAttribute={currentAttribute}
						/>
					)}
					{!multipleSelect && (
						<SingleSelect
							question={question}
							value={value}
							onChange={change}
							answerKey={answerKey}
							currentAttribute={currentAttribute}
							currentAttributeRef={currentAttributeRef}
							options={orderedOptions}
							otherSelected={isOtherSelected}
							onOtherChange={onOtherChange}
						/>
					)}
				</div>
			</div>
			<div className={`${el('button-outer-container')} no-overflow`}>
				<div className={`${el('button-container-inline')}`}>
					<div className={el('button')}>
						<Button
							label={t('continue')}
							disabled={!buttonEnabled}
							onClick={onHandleClick}
							dataTestId="continue-button"
						/>
					</div>
				</div>
			</div>
			<GlobalScrollIndicator show={contentOverflow} />
		</>
	);
};

Grid.propTypes = {
	question: PropTypes.any,
	renderAsset: PropTypes.any,
	t: PropTypes.any,
	answers: PropTypes.any,
	setAnswers: PropTypes.any,
	currentSection: PropTypes.any,
	handleResults: PropTypes.any,
	productId: PropTypes.any,
	productOrder: PropTypes.any,
	getHtmlLabel: PropTypes.any,
	onImageLoad: PropTypes.func,
};

export default Grid;
