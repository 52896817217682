import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import { motion } from 'framer-motion';

import Button from 'src/components/new/Button';
import { getWpmData } from 'src/utilities/wpm';
import useDebounceCallback from 'src/utilities/debounceCallback';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import * as misc from 'src/utilities/misc';
import { size } from 'lodash';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';
import RankedResetButton from '../../../../components/shared/RankedResetButton';
import Question from '../../components/Question';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import './styles.scss';

const className = 'questions-container';
const el = (name, mod) => cn(className, name, mod);
const globalButtonHeight = 68;

const animation = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
		transition: {
			staggerChildren: 100,
		},
	},
};

const questionAnimation = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
	},
};

const Questions = ({
	t,
	study = {},
	RID,
	setNextSection,
	setSectionAnswers,
	currentSection,
	sections,
	jumpToSection,
	jumpToQuestion,
	questionIndex,
	setStep,
	setQuestionIndex,
	swipeResults,
	allAnswers,
	terminateResponse,
	focusEndTime,
	focusStartTime,
	clearInputFocusStartTime,
}) => {
	const { questions } = currentSection;
	const { previewUuid } = useSelector((state) => state);
	const isMobile = useIsMobile();

	// Filter through currentSection.questions and remove any questions with no question label, any questions with no answer/option labels, or Grid questions with no attribute labels
	const questionsWithLabels = questions.filter((q) => {
		const questionLabel = q.label.replace(/<\/?p[^>]*>/g, '');
		// remove questions with no labels
		if (
			!questionLabel ||
			!questionLabel.length ||
			questionLabel === 'Type question here...' ||
			questionLabel === 'Type option here...'
		) {
			return false;
		}
		// remove questions with no answer/option labels
		if (['single-select', 'multi-select', 'grid', 'emoji'].includes(q.style)) {
			const hasNonEmptyOptionLabels = q.options.some((o) => {
				if (o.label) {
					return true;
				}

				if (o.maskedOptions.length) {
					return true;
				}

				return false;
			});

			if (!q.options || q.options.length < 1 || !hasNonEmptyOptionLabels) {
				return false;
			}
		}
		// remove Grid type questions with no attributes
		if (q.style === 'grid') {
			const hasAttributeLabels =
				q.attributes.length &&
				q.attributes.filter(
					(a) => a.translations.findIndex((translation) => translation.label.length !== 0) !== -1,
				).length;
			const hasOptionLabels = q.options.length && q.options.filter((o) => o.label.length).length;
			if (!hasAttributeLabels || !hasOptionLabels) return false;
		}
		return q;
	});

	const [answers, setAnswers] = useState({});
	const [contentOverflow, setContentOverflow] = useState(false);
	const [justifyContent, setJustifyContent] = useState(false);
	const [pose, setPose] = useState('start');
	const [heatmapOptional, setHeatmapOptional] = useState(false);
	// const [questionIndex, setQuestionIndex] = useState(0);
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const [resetRankedOptions, setResetRankedOptions] = useState(false);
	const [updateDebounceTimer, debounceCallback] = useDebounceCallback();
	const scrollContainerClass = 'scroll-container';

	const runFirstSectionLogicCheck = useRef(true);

	useEffect(() => {
		setPose('completed');
		window.addEventListener('resize', updateQuestionMinHeight);
		window.addEventListener('orientationchange', updateQuestionMinHeight);
		return () => {
			window.removeEventListener('resize', updateQuestionMinHeight);
			window.removeEventListener('orientationchange', updateQuestionMinHeight);
		};
	}, []);

	const preQuestion = useRef(true);
	const elementRef = useRef(true);

	// For centering smaller questions vertically
	const [questionMinHeight, setQuestionMinHeight] = useState(window.innerHeight);
	useEffect(() => {
		// Update debounce timer
		updateDebounceTimer();
		// Wait for render cycle to check client height
		setTimeout(() => {
			setQuestionMinHeight(window.innerHeight);
			const continueButtonBuffer = globalButtonHeight / 2;
			const isContentOverflowing =
				elementRef &&
				elementRef.current &&
				elementRef.current.clientHeight - continueButtonBuffer > window.innerHeight;
			setContentOverflow(isContentOverflowing);
		}, 100);
		setTimeout(() => {
			setJustifyContent(elementRef && elementRef.current && elementRef.current.clientHeight > window.innerHeight);
		}, 30);
	}, [questionIndex]);

	const updateQuestionMinHeight = () => {
		setQuestionMinHeight(window.innerHeight);
		// Wait for render cycle to check client height
		setTimeout(() => {
			const continueButtonBuffer = globalButtonHeight / 2;
			const isContentOverflowing =
				elementRef &&
				elementRef.current &&
				elementRef.current.clientHeight - continueButtonBuffer > window.innerHeight;
			setContentOverflow(isContentOverflowing);
			setJustifyContent(elementRef && elementRef.current && elementRef.current.clientHeight > window.innerHeight);
		}, 100);
	};

	const isGridQuestion = questionsWithLabels[questionIndex]?.style === 'grid';
	const isRankedQuestion = questionsWithLabels[questionIndex]?.style === 'ranked';

	const isOpenEndedOptional = useCallback(
		(question) => {
			const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
			if (!isOptional) return;

			const isNumericOnly = misc.getQuestionSetting(question, 'numeric') === 'true';
			const answer = answers[questionsWithLabels[questionIndex].id];
			const hasValue = answer && answer.value;

			if (hasValue && isNumericOnly) {
				const hasValidUserInputAnswer = misc.checkForValidOpenEnded(question, answer ? answer.value : '');
				return hasValidUserInputAnswer;
			}

			return isOptional;
		},
		[answers, questionIndex, questionsWithLabels],
	);

	const isSelectQuestionOptional = useCallback(
		(question) => {
			const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
			if (!isOptional) return;

			const isMultiSelect = misc.getQuestionSetting(question, 'multi-select') === 'true';
			const answer = answers[questionsWithLabels[questionIndex].id];
			const hasValue = answer?.value?.length;
			if (isOptional && answer?.otherOptionId && answer?.otherValue?.length === 0) return false;
			if (hasValue && isMultiSelect) {
				const hasValidUserInputAnswer = misc.checkForValidInputs(
					question,
					answer?.otherOptionId ? answer?.otherValue : answer?.value,
				);
				return hasValidUserInputAnswer;
			}

			return isOptional;
		},
		[answers, questionIndex, questionsWithLabels],
	);

	useEffect(() => {
		const question = questionsWithLabels[questionIndex];
		if (!question) return;
		const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
		if (isOptional && question.style === 'open-ended') {
			const isOptionalOE = isOpenEndedOptional(question);
			setButtonEnabled(isOptionalOE);
		}
		if (isOptional && question.style === 'heatmap') {
			const value = answers[question?.id]?.value;
			const hasValidUserInputAnswer = value?.length >= 1;
			if (!hasValidUserInputAnswer) setHeatmapOptional(true);
		} else {
			setHeatmapOptional(false);
		}

		if (question.style === 'ranked') {
			const settings = question?.settings;
			const topNSet = settings?.find((s) => s?.label === 'top-n')?.value === 'true';
			const topN = topNSet
				? Math.min(
						parseInt(settings?.find((s) => s?.label === 'top-n-limit')?.value) || 1,
						answers[question.id]?.availableOptions?.length || 0,
				  )
				: Math.min(answers[question.id]?.availableOptions?.length || 0, 10);

			const answerLength = answers[question.id]?.value?.length || 0;
			const isEnabled = isOptional ? answerLength === 0 || answerLength >= topN : answerLength >= topN;
			setButtonEnabled(isEnabled);
		}

		if (isOptional && ['multi-select', 'emoji', 'grid', 'heatmap'].includes(question.style)) {
			const isOptionalQuestion = isSelectQuestionOptional(question);
			setButtonEnabled(isOptionalQuestion);
		}
	}, [questionsWithLabels, questionIndex, answers, isOpenEndedOptional, isSelectQuestionOptional]);

	const checkForLogicConditionMatch = (triggerOption, answerSet) => {
		if (answerSet?.type === 'ranked') {
			const answerRank = answerSet.value.findIndex((id) => id === triggerOption?.id) + 1;
			const isOptionRanked = answerSet?.value?.includes(triggerOption?.id);
			if (triggerOption?.position === 'top') {
				return isOptionRanked && answerRank <= triggerOption.rank;
			}
			if (triggerOption?.position === 'bottom') {
				return isOptionRanked && answerSet.value.length - answerRank < triggerOption.rank;
			}
		}
		if (triggerOption.attributeId)
			return (
				triggerOption.id &&
				answerSet &&
				answerSet.value &&
				answerSet.value.includes(triggerOption.id.toString()) &&
				answerSet.attributeId === triggerOption.attributeId
			);
		return (
			triggerOption.id && answerSet && answerSet.value && answerSet.value.includes(triggerOption.id.toString())
		);
	};

	const getPreLogicAnswerSet = (triggerOption, triggerItemId) => {
		let answerSet = null;
		if (triggerOption.attributeId && answers[`${triggerItemId}-${triggerOption.attributeId}`]) {
			// Current section with attribute ID trigger
			answerSet = answers[`${triggerItemId}-${triggerOption.attributeId}`];
		} else if (triggerOption.attributeId && allAnswers[`${triggerItemId}-${triggerOption.attributeId}`]) {
			// previous section answers with attribute id trigger
			answerSet = allAnswers[`${triggerItemId}-${triggerOption.attributeId}`];
		} else if (answers[triggerItemId]) {
			// Check current section answers first
			answerSet = answers[triggerItemId];
		} else {
			// Previous section questions will be here.
			answerSet = allAnswers[triggerItemId];
		}

		return answerSet;
	};

	// eslint-disable-next-line
	const doPreLogic = (index) => {
		const question = questionsWithLabels[index];

		if (!question) return true; // * Skip the question if it is not included into the list of valid questions
		console.log('pre logic question: ', question);
		const { id: questionId, logic = [] } = question ?? {};
		// Get all items related to this question - aka post-question logic
		const preLogic = logic.filter(
			(logicItem) =>
				logicItem.triggerItemId !== questionId &&
				logicItem.actionType === 'skip' &&
				logicItem?.triggerOptions?.length > 0,
		);
		if (preLogic.length) {
			// using classic for-loop here so we can short-circuit as soon as a condition is met.
			for (let i = 0; i < preLogic.length; i++) {
				const logicItem = preLogic[i];
				const { triggerOptions, triggerType, operand, operator, triggerItemId } = logicItem;
				let logicConditionsMatched = false;
				const cleanedTriggerOptions = triggerOptions.filter((triggerOption) => {
					if (triggerOption && Object.keys(triggerOption).length) {
						return triggerOption;
					}
					return false;
				});
				if (triggerType === 'questions') {
					console.log('triggerItemId: ', triggerItemId);
					console.log('Do pre-logic allAnswers: ', allAnswers);
					console.log('Do pre-logic answers: ', answers);
					// console.log("Do pre-logic Answer set: ", answerSet);
					if (operand === 'and') {
						if (operator === 'is') {
							logicConditionsMatched = cleanedTriggerOptions.every((triggerOption) =>
								checkForLogicConditionMatch(
									triggerOption,
									getPreLogicAnswerSet(triggerOption, triggerItemId),
								),
							);
						} else if (operator === 'is_not') {
							logicConditionsMatched = !cleanedTriggerOptions.every((triggerOption) =>
								checkForLogicConditionMatch(
									triggerOption,
									getPreLogicAnswerSet(triggerOption, triggerItemId),
								),
							);
						}
					} else if (operand === 'or') {
						if (operator === 'is') {
							logicConditionsMatched = cleanedTriggerOptions.some((triggerOption) =>
								checkForLogicConditionMatch(
									triggerOption,
									getPreLogicAnswerSet(triggerOption, triggerItemId),
								),
							);
						} else if (operator === 'is_not') {
							logicConditionsMatched = !cleanedTriggerOptions.some((triggerOption) =>
								checkForLogicConditionMatch(
									triggerOption,
									getPreLogicAnswerSet(triggerOption, triggerItemId),
								),
							);
						}
					}
				} else if (triggerType === 'swipe') {
					const { interests } = swipeResults;
					const productInterest = interests.find((interest) => interest.localProductId === triggerItemId);
					if (productInterest) {
						// TODO - should only support `is`
						if (operator === 'is') {
							logicConditionsMatched = cleanedTriggerOptions.some(
								(triggerOption) => triggerOption && productInterest.interest == triggerOption.value,
							);
						} else if (operator === 'is_not') {
							logicConditionsMatched = cleanedTriggerOptions.every(
								(triggerOption) => triggerOption && productInterest.interest != triggerOption.value,
							);
						}
					}
				}
				// If this logic is matching, send it
				if (logicConditionsMatched) {
					return true;
				}
			}
		}
		return false;
	};

	const getPostLogicAnswerSet = (triggerOption, questionId) => {
		let answerSet = null;

		if (triggerOption.attributeId && answers[`${questionId}-${triggerOption.attributeId}`])
			// Current section with attribute ID trigger
			answerSet = answers[`${questionId}-${triggerOption.attributeId}`];
		// Check current section answers first
		else answerSet = answers[questionId];

		return answerSet;
	};

	const doPostLogic = () => {
		const question = questionsWithLabels[questionIndex];
		console.log('post logic question: ', question);
		const { id: questionId, logic = [] } = question ?? {};
		// Get all items related to this question - aka post-question logic
		const postLogic = logic.filter(
			(logicItem) => logicItem.triggerItemId === questionId && logicItem?.triggerOptions?.length,
		);
		console.log('postLogic: ', postLogic);
		if (postLogic.length) {
			// using classic for-loop here so we can short-circuit as soon as a condition is met.
			for (let i = 0; i < postLogic.length; i++) {
				const logicItem = postLogic[i];
				const { triggerOptions, operand, operator, actionType, actionSectionId, actionItemId } = logicItem;
				let logicConditionsMatched = false;
				const cleanedTriggerOptions = triggerOptions.filter((triggerOption) => {
					if (triggerOption && Object.keys(triggerOption).length) {
						return triggerOption;
					}
					return false;
				});

				console.log('triggerOptions: ', triggerOptions);
				console.log('answers: ', answers);

				// const answerSet = answers[questionId];
				// console.log('questionId: ', questionId);
				// console.log('triggerOptions.attributeId: ', triggerOptions[0].attributeId);
				// console.log("Do post-logic Answer set: ", answerSet)

				if (operand === 'and') {
					if (operator === 'is') {
						logicConditionsMatched = cleanedTriggerOptions.every((triggerOption) =>
							checkForLogicConditionMatch(
								triggerOption,
								getPostLogicAnswerSet(triggerOption, questionId),
							),
						);
					} else if (operator === 'is_not') {
						logicConditionsMatched = !cleanedTriggerOptions.every((triggerOption) =>
							checkForLogicConditionMatch(
								triggerOption,
								getPostLogicAnswerSet(triggerOption, questionId),
							),
						);
					}
				} else if (operand === 'or') {
					if (operator === 'is') {
						logicConditionsMatched = cleanedTriggerOptions.some((triggerOption) =>
							checkForLogicConditionMatch(
								triggerOption,
								getPostLogicAnswerSet(triggerOption, questionId),
							),
						);
					} else if (operator === 'is_not') {
						logicConditionsMatched = !cleanedTriggerOptions.some((triggerOption) =>
							checkForLogicConditionMatch(
								triggerOption,
								getPostLogicAnswerSet(triggerOption, questionId),
							),
						);
					}
				}
				// TODO - if actionType is terminate - kill em
				// If this logic is matching, send it
				if (logicConditionsMatched) {
					if (actionType === 'terminate') {
						return [false, false, true];
					}
					return evaluatePostLogicReturn([actionSectionId, actionItemId, false]);
				}
			}
		}
		return [false, false, false];
	};

	const evaluatePostLogicReturn = (returnArray) => {
		const [sectionId, questionId, terminate] = returnArray;
		const currentQuestion = questionsWithLabels[questionIndex];
		const foundSection = sections?.find(
			(section) => section?.id && section?.id === sectionId && section?.order >= currentSection?.order,
		);
		const foundQuestion = foundSection?.questions?.find((question) => question?.id && question?.id === questionId);

		if (
			(foundSection && questionId && !foundQuestion && !terminate) ||
			(!foundSection && !foundQuestion && !terminate)
		) {
			return [false, false, false];
		}
		return returnArray;
	};

	const resetScrollContainerClasses = () => {
		setTimeout(() => {
			// Reset scroll container
			const scrollContainer = document.querySelector(`[class*='${scrollContainerClass}']`);
			if (scrollContainer) {
				scrollContainer.classList.remove('scrolled-to-bottom');
				scrollContainer.classList.remove('remove-scroll-indicator');
			}
		}, 100);
	};

	// determine the next section to go to
	const findNext = useCallback(
		(indexToCheck) => {
			const checkIndex = indexToCheck + 1;
			const skip = doPreLogic(checkIndex);
			if (skip) {
				const noMoreQuestions = checkIndex + 1 >= questions.length;

				if (noMoreQuestions) {
					return {
						index: checkIndex,
						noMoreQuestions,
					};
				}

				return findNext(checkIndex);
			}

			return {
				index: checkIndex,
			};
		},
		[doPreLogic, questions.length],
	);

	const handleSetNextSection = useCallback(() => {
		setNextSection();
		runFirstSectionLogicCheck.current = true;
	}, [setNextSection]);

	const handleResults = (currentQuestion) => {
		setButtonEnabled(false);
		resetScrollContainerClasses();

		const [sectionId, questionId, terminate] = doPostLogic();
		const answer = answers[currentQuestion?.id];
		if (answer && answer.type === 'open-ended') {
			const wpmData = getWpmData({ answer, focusStartTime, focusEndTime });
			const answerWithWpmData = {
				...answer,
				...wpmData,
			};
			answers[currentQuestion.id] = answerWithWpmData;
			setAnswers(answers);
		}
		if (currentQuestion && currentQuestion.style === 'heatmap') {
			const isOptional = misc.getQuestionSetting(currentQuestion, 'optional') === 'true';
			if (isOptional && !(answer?.value?.length > 0)) {
				const updatedAnswer = {
					...answer,
					type: 'heatmap',
					skipped: true,
				};
				answers[currentQuestion.id] = updatedAnswer;
				setAnswers(answers);
			}
		}
		clearInputFocusStartTime();
		if (sectionId || questionId || terminate) {
			if (terminate) {
				const testMode = !!previewUuid;
				if (testMode) {
					setStep('terminated');
				} else {
					terminateResponse();
				}
			} else if (questionId) {
				preQuestion.current = true;
				jumpToQuestion(questionId, sectionId);
			} else if (sectionId !== currentSection.id) {
				setSectionAnswers(answers);
				setQuestionIndex(0);
				jumpToSection(sectionId);
			}
		} else {
			// Check if all the questions have been asked
			const endOfQuestions = questionIndex + 1 === questionsWithLabels.length;
			if (endOfQuestions) {
				// If it is the end of the questions then set the currentSectionIndex to the survey index
				// const surveyIndex = sections.findIndex(section => section === 'survey');
				setQuestionIndex(0);
				let wpmAgr = 0;
				let openEndedAnswersLength = 0;
				for (const key in answers) {
					const currentAnswer = answers[key];
					if (currentAnswer.type === 'open-ended') {
						wpmAgr += currentAnswer.wpm;
						openEndedAnswersLength++;
						console.log(
							`id:${key}, total typing time: ${answers[key].totalTypingTime} sec, total no of chars typed: ${answers[key].noOfChars}, wpm: ${answers[key].wpm}`,
						);
					}
				}
				console.log(`Average WPM: ${Math.ceil(wpmAgr / openEndedAnswersLength)}`);
				setSectionAnswers(answers);
				handleSetNextSection();
			} else {
				// Otherwise set the questionIndex and and stay on the same currentSectionIndex (questions)
				preQuestion.current = true;

				const { index, noMoreQuestions } = findNext(questionIndex);
				if (noMoreQuestions) {
					setQuestionIndex(0);
					setSectionAnswers(answers);
					handleSetNextSection();
				} else {
					setQuestionIndex(index);
				}
			}
		}
	};

	useEffect(() => {
		if (runFirstSectionLogicCheck.current && questions.length && questionIndex === 0) {
			runFirstSectionLogicCheck.current = false;

			const { index, noMoreQuestions } = findNext(questionIndex - 1);

			if (noMoreQuestions) {
				setQuestionIndex(0);
				setSectionAnswers(answers);
				handleSetNextSection();
			} else if (index !== 0) {
				setQuestionIndex(index);
			}
		}
	}, [answers, findNext, handleSetNextSection, questionIndex, questions.length, setQuestionIndex, setSectionAnswers]);

	const handleChange =
		(question) =>
		(value, type, otherValue = '', otherOptionId = 0, noneOfTheAboveSelected = false) => {
			// * Store the answers in a local state variable
			setAnswers(prev => {
				if (!value && type === 'open-ended') {
					delete prev[question.id];
					return prev;
				}
				return {
					...answers,
					[question.id]: {
						...answers[question.id],
						value, // * optionId
						type, // * Type of question
						otherValue, // ? Why is this always blank
						otherOptionId, // ? Why is this always 0
						sectionId: currentSection.id, // * ID of the current section
					},
				}
			});


			let hasValidUserInputAnswer = false;

			if (question.style === 'heatmap') {
				hasValidUserInputAnswer = misc.checkForValidHeatmap(question, value);
				if (hasValidUserInputAnswer) {
					setHeatmapOptional(false);
					setAnswers({
						...answers,
						[question.id]: {
							...answers[question.id],
							value, // * optionId
							type, // * Type of question
							sectionId: currentSection.id, // * ID of the current section
						},
					});
				}
			} else if (question.style === 'open-ended') {
				hasValidUserInputAnswer = misc.checkForValidOpenEnded(question, value);
			} else {
				hasValidUserInputAnswer = misc.checkForValidInputs(
					question,
					value,
					question.style,
					noneOfTheAboveSelected,
					otherValue,
				);
			}

			debounceCallback(() => setButtonEnabled(hasValidUserInputAnswer));
		};

	const handleReset = (question) => {
		setAnswers({
			...answers,
			[question.id]: {
				...answers[question.id],
				value: [],
			},
		});
		const scrollContainer = document.querySelector(`[class*='${scrollContainerClass}']`);
		scrollContainer.scrollTo(0, 0, { behavior: 'smooth' });
		setResetRankedOptions(true);
		setTimeout(() => {
			setResetRankedOptions(false);
		}, 50);
	};

	// If the list of questions with labels is empty proceed to the next section
	if (questionsWithLabels.length === 0) {
		handleSetNextSection();
		return false;
	}

	return (
		<motion.div
			animate={pose}
			variants={animation}
			initial="start"
			className={className}
			style={{
				minHeight: `${questionMinHeight - 10}px`,
				maxHeight: `${questionMinHeight - 10}px`,
				height: `${questionMinHeight - 10}px`,
				justifyContent: `${justifyContent ? 'flex-start' : 'center'}`,
			}}
		>
			{/* <h3 className={el('heading')}>{t('get-started-with-questions')}</h3> */}
			<div className={`${el('questions')}`} ref={elementRef}>
				<motion.div
					key={questionsWithLabels[questionIndex]?.id}
					animate={pose}
					variants={questionAnimation}
					initial="start"
				>
					<Question
						study={study}
						language={study.language}
						question={questionsWithLabels[questionIndex]}
						value={
							size(answers) > 0 && answers[questionsWithLabels[questionIndex]?.id]
								? answers[questionsWithLabels[questionIndex]?.id]?.value
								: 0
						}
						onChange={handleChange(questionsWithLabels[questionIndex])}
						answers={answers}
						setAnswers={setAnswers}
						currentSection={currentSection}
						handleResults={() => handleResults(questionsWithLabels[questionIndex])}
						onImageLoad={updateQuestionMinHeight}
						resetRankedOptions={resetRankedOptions}
					/>
				</motion.div>
				{!isGridQuestion && (
					<div className={`${el('inline-button-container')}${!contentOverflow ? ' no-overflow' : ''}`}>
						<div className={el('inline-button')}>
							<Button
								disabled={!buttonEnabled}
								label={t(heatmapOptional ? 'skip' : 'continue')}
								onClick={() => handleResults(questionsWithLabels[questionIndex])}
								tabindex={!buttonEnabled ? '-1' : 0}
								dataTestId="continue-button"
							/>
						</div>
					</div>
				)}
				{isRankedQuestion && isMobile && (
					<RankedResetButton
						handleReset={handleReset}
						disabled={answers[questionsWithLabels[questionIndex]?.id]?.value?.length < 1}
						question={questionsWithLabels[questionIndex]}
					/>
				)}
			</div>
			{!isGridQuestion && (
				<GlobalScrollIndicator
					show={contentOverflow}
					backgroundBlur={questionsWithLabels[questionIndex]?.style === 'ranked'}
				/>
			)}
		</motion.div>
	);
};

const mapStateToProps = (state) => ({
	study: selectors.getStudy(state),
	sections: selectors.getSections(state),
	currentSection: selectors.getCurrentSection(state),
	questionIndex: selectors.getQuestionIndex(state),
	allAnswers: selectors.getAnswers(state),
	swipeResults: selectors.getResults(state),
	RID: selectors.getResponseRID(state),
	focusStartTime: selectors.getFocusStartTime(state),
	focusEndTime: selectors.getFocusEndTime(state),
});

const mapDispatchToProps = (dispatch) => ({
	setSectionAnswers: (answers) => dispatch(actions.setAnswers(answers)),
	setStep: (step) => dispatch(actions.setStep(step)),
	setNextSection: () => dispatch(actions.setNextSection()),
	setQuestionIndex: (index) => dispatch(actions.setQuestionIndex(index)),
	jumpToQuestion: (questionId, sectionId) => dispatch(actions.jumpToQuestion(questionId, sectionId)),
	jumpToSection: (sectionId) => dispatch(actions.jumpToSection(sectionId)),
	terminateResponse: () => dispatch(actions.terminateResponse()),
	clearInputFocusStartTime: () => dispatch(actions.setInputFocusStartTime(null)),
});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(Questions));
