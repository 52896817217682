import React from 'react';

export default () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M12.4999 22H11.4999C11.2237 22 10.9999 21.7761 10.9999 21.5V5.33L6.55985 9.78C6.46597 9.87466 6.33817 9.9279 6.20485 9.9279C6.07153 9.9279 5.94374 9.87466 5.84985 9.78L5.14985 9.07C5.0552 8.97612 5.00195 8.84832 5.00195 8.715C5.00195 8.58168 5.0552 8.45388 5.14985 8.36L11.2799 2.22C11.4204 2.07931 11.611 2.00018 11.8099 2H12.1899C12.3883 2.0023 12.5781 2.08112 12.7199 2.22L18.8499 8.36C18.9445 8.45388 18.9978 8.58168 18.9978 8.715C18.9978 8.84832 18.9445 8.97612 18.8499 9.07L18.1399 9.78C18.0477 9.87406 17.9215 9.92707 17.7899 9.92707C17.6582 9.92707 17.532 9.87406 17.4399 9.78L12.9999 5.33V21.5C12.9999 21.7761 12.776 22 12.4999 22Z"
			fill="#666666"
		/>
	</svg>
);
