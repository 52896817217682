import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'src/utilities/bem-cn';
import X from 'src/components/icons/X';
import * as misc from 'src/utilities/misc';
import './styles.scss';

const className = 'device-preview-header';
const el = (name) => cn(className, name);
const autoDismissInSeconds = 2.5;

const DevicePreviewHeader = () => {
	const urlParams = misc.getAllUrlParams();
	const { study } = useSelector((state) => state.main);
	const [showDevicePreviewHeader, setShowDevicePreviewHeader] = useState(
		urlParams.hasOwnProperty('previewUuid') && !urlParams.hasOwnProperty('disablePreviewPrompt'),
	);

	useEffect(() => {
		setTimeout(() => {
			setShowDevicePreviewHeader(false);
		}, autoDismissInSeconds * 1000);
	});

	useEffect(() => {
		setShowDevicePreviewHeader(study?.previewUuid && !study?.disablePreviewPrompt);
	}, [study?.previewUuid, study?.disablePreviewPrompt]);

	const generateClasses = () => {
		if (showDevicePreviewHeader === undefined) {
			return className;
		}

		return showDevicePreviewHeader ? `${className} ${className}__hidden` : `${className}__hidden disabled`;
	};
	if (showDevicePreviewHeader === undefined) {
		return <></>;
	}

	const text = 'Upsiide Study Preview - Your response will not be counted.';
	return (
		<div
			className={`${generateClasses()} ${
				urlParams.hasOwnProperty('previewUuid') ? 'mobile-device-preview' : ''
			} `}
		>
			<p className={el('text')}>{text}</p>
			<button
				className={el('close')}
				onClick={() => {
					setShowDevicePreviewHeader(false);
				}}
			>
				<X />
			</button>
		</div>
	);
};

export default DevicePreviewHeader;
