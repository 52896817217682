import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'ranked-option-checkbox';
const el = (name, mod) => cn(className, name, mod);

const RankedOptionCheckbox = ({ position = undefined }) => (
	<div className={`${className} ${position !== undefined ? el('has-position') : null}`}>
		{position !== undefined && <div className={el('position')}>{position}</div>}
	</div>
);

RankedOptionCheckbox.propTypes = {
	position: PropTypes.number,
};

export default RankedOptionCheckbox;
