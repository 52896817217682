import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../actions';

import './styles.scss';

const mainClassName = 'questionnaire-textarea';

const OpenEnded = ({ disabled, value, onChange, settings }) => {
	const inputRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(()=>{
		const pageContainer = document.querySelector('.scroll-container');
		if (pageContainer) {
			pageContainer.style.scrollBehavior = 'auto';
			pageContainer.scrollTo(0, 0);
		}
		// Reset scroll container 
		const scrollContainer = document.querySelector(`[class*='scroll-container']`);
		if (scrollContainer) {
			scrollContainer.classList.remove('scrolled-to-bottom');
			scrollContainer.classList.remove('remove-scroll-indicator');
		}
	},[]);

	const { focusStartTime } = useSelector(state => state.main);
	const [numericValueOnly, setNumericValueOnly] = useState(
		settings && settings.find(s => s.label === 'numeric')
			? settings.find(s => s.label === 'numeric').value === 'true'
			: null,
	);

	const adjustHeight = () => {
		const { current } = inputRef;
		current.style.height = `1px`;
		current.style.height = `${current.scrollHeight}px`;
	};

	const preventEnter = e => {
		const { key } = e;

		if (key === 'Enter') {
			e.preventDefault();
		}
	};

	const cleanContent = text => {
		const invalidChars = /[^0-9\.\-]/gi;

		if (numericValueOnly && invalidChars.test(text)) {
			text = text.replace(invalidChars, '');
		}
		return text;
	};

	const change = e => {
		const { key } = e;

		if (key === 'Enter') {
			e.preventDefault();
		} else {
			adjustHeight();
			onChange(cleanContent(e.target.value));
		}
	};

	const handleFocusIn = () => {
		if (!focusStartTime) {
			dispatch(actions.setInputFocusStartTime(new Date()));
		}
	};

	const handleFocusOut = () => {
		dispatch(actions.setInputFocusEndTime(new Date()));
	};

	useEffect(() => {
		adjustHeight();
	});

	return (
		<textarea
			inputMode={numericValueOnly ? 'numeric' : 'text'}
			ref={inputRef}
			className={mainClassName}
			disabled={disabled}
			value={value || ''}
			onKeyDown={preventEnter}
			onChange={change}
			onFocus={handleFocusIn}
			onBlur={handleFocusOut}
		/>
	);
};

export default OpenEnded;
