import React from 'react';
import { Typography } from '@upsiide/ui-components';
import { useTranslation } from 'react-i18next';

export const CLICK_LIMIT_TYPES = {
	EXACTLY: 'exact',
	RANGE: 'range',
};

export const HelperMessages = (
	allowMultipleClicks,
	clickLimitType,
	clickLimit,
	commentsOptional,
	clickOnProductCard,
) => {
	const { t } = useTranslation('main');

	let numberOfClicksRequired = null;
	let commentsRequiredText = commentsOptional ? t('heatmap-click-image-no-comments') : t('heatmap-click-image');
	if (clickOnProductCard) {
		commentsRequiredText = commentsOptional
			? t('heatmap-click-image-product-card--no-comment')
			: t('heatmap-click-image-product-card');
	}
	if (allowMultipleClicks !== false && clickLimit) {
		if (clickLimitType === CLICK_LIMIT_TYPES.EXACTLY) {
			numberOfClicksRequired = t('choose', { amount: clickLimit });
		} else if (clickLimitType === CLICK_LIMIT_TYPES.RANGE) {
			numberOfClicksRequired = t('up-to', { amount: clickLimit });
		}
	} else if (allowMultipleClicks === false) {
		numberOfClicksRequired = null;
	}
	return (
		<div>
			{commentsRequiredText && <Typography variant="bodyBold">{commentsRequiredText}</Typography>}
			{numberOfClicksRequired && <Typography variant="bodyBold">{numberOfClicksRequired}</Typography>}
		</div>
	);
};
