import React from 'react';
import cn from 'src/utilities/bem-cn';
import AssetPreviewModal from '../../shared/AssetPreviewModal';

import './styles.scss';

const mainClassName = 'body';
const el = name => cn(mainClassName, name);

export default ({ children, className }) => (
	<div className={`${mainClassName} ${className}`}>
		<div id="app-content" className={el('content')}>
			{children}
		</div>
		<AssetPreviewModal
			handleOnClose={() => {
				document.getElementById('app-content').style.filter = ''; // ? use refs instead
			}}
		/>
	</div>
);
