export default {
	'this-survey-does-not-exist': '抱歉，此调查不存在。',
	'survey-closed': '抱歉，本次调查已结束。',
	'survey-no-products': '此问卷尚未添加调研产品。',
	'survey-no-products-summary': '如果您是此问卷的创建者，请前往控制面板添加调研产品。',
	'survey-completed': '您已完成本次调查。',
	'summary-placeholder': '如果您认为这是一个错误，请联系此调查链接的提供者以获取帮助。',
	'gone-wrong': '糟糕，出错啦。',
	'try-again': '请稍后重试。',
	'thank-you': '谢谢',
	'thank-you-summary': '本次调查已完成，您的回答已提交。',
	'thank-you-summary-redirect': '链接重定向中，请稍后。',
	'page-not-found': '出错啦。',
	'page-not-found-placeholder': '抱歉，链接出错',
	'tap-your-favorite': '请勾选您更偏好的产品概念',
	or: '还是',
	'get-started-with-questions': '请先回答几个问题',
	continue: '继续',
	retry: '重试',
	'tell-us-how-you-feel-about-this-product': '告诉我们您对此产品的感觉',
	begin: '开始',
	'how-would-you-describe-this-product-to-a-friend': '您如何将此产品描述给朋友？',
	'type-your-response-here': '在此处输入您的回复…',
	'sum-of-total-characters': '${SUM}个字符（共${TOTAL}个字符）',
	edit: '编辑',
	remove: '去掉',
	'how-do-you-feel-about-this-part': '您对这部分感觉如何？',
	'tap-on-an-area-and-choose-an-emoji': '点击一个区域并选择一个表情符号',
	'im-finished': '我完成了',
	maintenance: 'Maintenance',
	'maintenance-mode': 'Upsiide is currently undergoing maintenance. Please check back soon.',
	'please-specify': '请明确说明',
	'monadic-split-statement': '在下一个屏幕上，我们将向您展示一个想法。请仔细查看，因为我们将就此向您提问。',
	disapproval: '不以为然',
	anger: '愤怒',
	sad: '沮丧',
	joy: '欢乐',
	love: '喜爱',
	trust: '信任',
	hope: '希望',
	proud: '自豪',
	amazed: '惊叹',
	surprised: '惊讶',
	guilt: '内疚',
	curious: '好奇',
	jealous: '嫉妒',
	anticipation: '期盼',
	optimistic: '乐观',
	anxious: '焦虑',
	fear: '害怕',
	despair: '绝望',
	disbelief: '怀疑',
	cynicism: '玩世不恭',
	regret: '后悔',
	disgust: '厌恶',
	annoyed: '恼怒',
	aggressive: '积极进取',
	shocked: '震惊',
	choose: '选择 {{amount}}',
	'up-to': '选择的数量最多不超过 {{amount}}',
	unlimited: '选择任意多项',
	'single-select': '选择一个',
	number: '输入数字',
	'number-between-min-max': '输入一个介于 {{min}} 和 {{max}} 之间的数字',
	'number-min': '输入一个不小于 {{min}} 的数字',
	'number-max': '输入一个不大于 {{max}} 的数字',
	'open-ended-pledge':
		'本调查包含一些问题，您需要用自己的语言回答这些问题。我们将自动检查您的回答，以确保其符合我们的质量要求。您是否同意继续？ ',
	'open-ended-pledge-accept': '是，我同意',
	'open-ended-pledge-decline': '否，我不同意',
	'rh-1-label': '以下哪一项不是体育运动？',
	'rh-1-o-1': '篮球',
	'rh-1-o-2': '网球',
	'rh-1-o-3': '曲奇饼',
	'rh-1-o-4': '曲棍球',
	'rh-2-label': '2+3 等于几？',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': '以下哪一项是水果？',
	'rh-3-o-1': '牛奶',
	'rh-3-o-2': '巧克力',
	'rh-3-o-3': '鸡蛋',
	'rh-3-o-4': '苹果',
	'open-end-max-length': '抱歉，输入的答案太长',
	'heatmap-click-image': '点击图像以添加备注',
	'heatmap-click-image-no-comments': '点击图像中的任何地方',
	'heatmap-click-image-product-card': '点击卡片以添加备注',
	'heatmap-click-image-product-card--no-comment': '点击卡片中的任何地方',
	'heatmap-type-comment': '输入备注',
	cancel: '取消',
	skip: '跳过',
	ranked: '排名',
	top: '顶部选项',
	'tap-or-drag': '点击或拖放即可排名',
	unranked: '未排名',
	reset: '重置',
	'no-more-options': '没有更多选项可供放置',
	'rank-list-full': '排名已满，取消您的选择之一的排名',
};
