export default {
	'this-survey-does-not-exist': 'Maaf, tinjauan ini tidak wujud.',
	'survey-closed': 'Minta maaf, kaji selidik ini sekarang sudah tutup.',
	'survey-no-products': 'Tinjauan ini tidak mengandungi produk.',
	'survey-no-products-summary': 'Jika anda pemilik tinjauan ini, cipta produk dalam papan pemuka untuk meneruskan.',
	'survey-completed': 'Anda sudah pun melengkapkan tinjauan ini.',
	'summary-placeholder':
		'Jika anda yakin bahawa ini ialah ralat, sila hubungi orang yang menghantar pautan ini kepada anda.',
	'gone-wrong': 'Hmmm, terdapat sesuatu yang tidak betul.',
	'try-again': 'Cuba lagi atau kembali kemudian.',
	'thank-you': 'Terima kasih',
	'thank-you-summary': 'Tinjauan telah lengkap, keputusan anda telah diserahkan.',
	'thank-you-summary-redirect': 'Anda akan diubah hala dalam beberapa saat.',
	'page-not-found': 'Ada sesuatu yang tidak kena',
	'page-not-found-placeholder': 'Mohon maaf, pautan putus',
	'tap-your-favorite': 'Ketik tanda semak pada kegemaran anda',
	or: 'atau',
	'get-started-with-questions': 'Beberapa soalan untuk kita mulakan',
	continue: 'Teruskan',
	retry: 'Cuba Semula',
	'tell-us-how-you-feel-about-this-product': 'Beritahu kami pendapat anda mengenai produk ini',
	begin: 'Mula',
	'how-would-you-describe-this-product-to-a-friend': 'Bagaimanakah anda menggambarkan produk ini kepada rakan?',
	'type-your-response-here': 'Taip jawapan anda di sini ...',
	'sum-of-total-characters': '${SUM} daripada ${TOTAL} aksara',
	edit: 'Edit',
	remove: 'Alih Keluar',
	'how-do-you-feel-about-this-part': 'Bagaimanakah pendapat anda tentang bahagian ini?',
	'tap-on-an-area-and-choose-an-emoji': 'Ketik pada kawasan dan pilih emoji',
	'im-finished': 'Saya sudah selesai',
	maintenance: 'Penyelenggaraan',
	'maintenance-mode': 'Upsiide sedang menjalani penyelenggaraan. Sila periksa semula sebentar lagi.',
	'please-specify': 'Sila nyatakan',
	'monadic-split-statement':
		'Pada skrin seterusnya, kami akan menunjukkan idea kepada anda. Sila semak dengan teliti kerana kami akan mengajukan soalan mengenainya.',
	disapproval: 'Tidak Bersetuju',
	anger: 'Marah',
	sad: 'Sedih',
	joy: 'Gembira',
	love: 'Sayang',
	trust: 'Percaya',
	hope: 'Harapan',
	proud: 'Bangga',
	amazed: 'Kagum',
	suprised: 'Terkejut',
	guilt: 'Bersalah',
	curious: 'Ingin Tahu',
	jealous: 'Cemburu',
	anticipation: 'Jangkaan',
	optimistic: 'Optimis',
	anxious: 'Resah',
	fear: 'Takut',
	despair: 'Putus Asa',
	disbelief: 'Tidak Percaya',
	cynicism: 'Sinis',
	regret: 'Menyesal',
	disgust: 'Meluat',
	annoyed: 'Jengkel',
	aggressive: 'Agresif',
	shocked: 'Terkejut',
	choose: 'Pilih {{amount}}',
	'up-to': 'Pilih maksimum sebanyak {{amount}}',
	unlimited: 'Pilih seberapa banyak yang anda suka',
	'single-select': 'Pilih satu',
	number: 'Masukkan nombor',
	'number-between-min-max': 'Masukkan nombor antara {{min}} dengan {{max}}',
	'number-min': 'Masukkan nombor {{min}} atau lebih besar',
	'number-max': 'Masukkan nombor {{max}} atau kurang',
	'open-ended-pledge':
		'Anda akan diminta untuk menjawab soalan dalam tinjauan ini menggunakan perkataan anda sendiri. Respons anda akan disemak secara automatik untuk memastikannya memenuhi syarat kualiti kami. Adakah anda bersetuju untuk meneruskan tinjauan? ',
	'open-ended-pledge-accept': 'Ya, saya bersetuju',
	'open-ended-pledge-decline': 'Tidak, saya tidak bersetuju',
	'rh-1-label': 'Yang manakah antara berikut bukan merupakan sukan?',
	'rh-1-o-1': 'Bola keranjang',
	'rh-1-o-2': 'Tenis',
	'rh-1-o-3': 'Biskut',
	'rh-1-o-4': 'Hoki',
	'rh-2-label': 'Apakah 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Yang manakah antara berikut ialah buah?',
	'rh-3-o-1': 'Susu ',
	'rh-3-o-2': 'Coklat',
	'rh-3-o-3': 'Telur',
	'rh-3-o-4': 'Epal',
	'open-end-max-length': 'Maaf, jawapan yang dimasukkan terlalu panjang',
	'heatmap-click-image': `Klik pada imej untuk menambah komen`,
	'heatmap-click-image-no-comments': `Klik mana-mana sahaja pada imej`,
	'heatmap-click-image-product-card': `Klik pada kad untuk menambah komen`,
	'heatmap-click-image-product-card--no-comment': `Klik mana-mana sahaja pada kad`,
	'heatmap-type-comment': `Taip komen`,
	cancel: `Batal`,
	skip: 'Langkau',
	ranked: 'Pangkat',
	top: 'Teratas',
	'tap-or-drag': 'Ketik atau seret dan lepaskan untuk menentukan kedudukan',
	unranked: 'Tidak berpangkat',
	reset: 'Tetapkan semula',
	'no-more-options': 'Tiada lagi pilihan untuk berpangkat',
	'rank-list-full': 'Senarai kedudukan sudah penuh, nyah kedudukan salah satu pilihan anda',
};
