import React, { useState, useMemo, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import Button from 'src/components/new/Button';
import SingleSelect from '../../components/Question/SingleSelect';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';
import * as misc from 'src/utilities/misc';
import _ from 'lodash';
import * as actions from '../../actions';
import './styles.scss';
import * as selectors from '../../selectors';

const containerHeightBuffer = 10;
const buttonHeight = 68;
const className = 'open-ended-pledge-container';
const el = (name, mod) => cn(className, name, mod);

const OpenEndedPledge = ({ t, study, setDisqualified, setNextSection }) => {
	const [selectedOption, setSelectedOption] = useState();
	const [containerHeight, setContainerHeight] = useState(window.innerHeight - containerHeightBuffer);
	const [justifyContent, setJustifyContent] = useState(false);
	const [contentOverflow, setContentOverflow] = useState(false);
	const elementRef = useRef(true);
	const orderedOptions = useMemo(() => [
		{
			id: 'pledge-yes',
			value: 'pledge-yes',
			label: t('open-ended-pledge-accept')
		},
		{
			id: 'pledge-no',
			value: 'pledge-no',
			label: t('open-ended-pledge-decline')
		}
	])

	useEffect(() => {
		updateContainerHeight();
		window.addEventListener('resize', updateContainerHeight);
		window.addEventListener('orientationchange', updateContainerHeight);
		return () => {
			window.removeEventListener('resize', updateContainerHeight);
			window.removeEventListener('orientationchange', updateContainerHeight);
		}
	}, []);


	const updateContainerHeight = () => {
		setContainerHeight(window.innerHeight - containerHeightBuffer);
		setTimeout(() => {
			const isScrolled = elementRef && elementRef.current && (elementRef.current.scrollHeight - buttonHeight/2) > window.innerHeight;
			setJustifyContent(isScrolled);
			setContentOverflow(isScrolled);
		}, 100);
	}

    const handleResults = () => {
        if(selectedOption === 'pledge-no') {
            const testMode = !!study?.previewUuid;
            if (testMode) {
                setStep('terminated');
            } else {
				setDisqualified(null, study?.audienceUuid);
            }
        } else {
            setNextSection();
        }
    }

	return (
		<div className={className} 
			ref={elementRef} 
			style={{height: `${containerHeight}px`,
					maxHeight: `${containerHeight}px`, 
					minHeight: `${containerHeight}px`,
				justifyContent: `${justifyContent ? 'flex-start' : 'center'}`}}>
			<div className={el('questions')}>
				<div key='open-peldge' >
					<p>{t('open-ended-pledge')}</p>
					<SingleSelect
						question={t('open-ended-pledge')}
						value={selectedOption}
						onChange={(value) => setSelectedOption(value)}
						options={orderedOptions}
					/>
				</div>
				<div className={`${el('inline-button-container')}${!contentOverflow ? ' no-overflow' : ''}`}>
					<div className={el('inline-button')}>
						<Button
							label={t('continue')}
							onClick={handleResults}
							disabled={!selectedOption}
							tabindex={0}
							dataTestId="continue-button"
						/>
					</div>
				</div>
			</div>
			<GlobalScrollIndicator show={contentOverflow} />
		</div>
	);

}

const mapStateToProps = state => ({
	study: selectors.getStudy(state),
});

const mapDispatchToProps = dispatch => ({
    jumpToSection: sectionId => dispatch(actions.jumpToSection(sectionId)),
    setDisqualified: (answers, audienceUuid) => dispatch(actions.setDisqualified(answers, audienceUuid, "open end pledge disqualification")),
    setNextSection: () => dispatch(actions.setNextSection()),
});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(OpenEndedPledge));