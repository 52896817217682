import React from 'react';

export default () => (
	<svg
		version="1.1"
		id="Capa_1"
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 512 512"
	>
		<circle fill="#FFE17D" cx="256" cy="256" r="256" />
		<path
			fill="#FFD164"
			d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.376-161.463
	C36.863,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.626-94.538
	C410.573,453.317,354.389,474.839,293.161,474.839z"
		/>
		<g>
			<path
				fill="#AA7346"
				d="M132.081,299.359c-3.79,0-7.435-2.093-9.242-5.71c-2.548-5.101-0.484-11.298,4.621-13.851
		L175.056,256l-47.597-23.798c-5.105-2.552-7.169-8.75-4.621-13.851c2.54-5.097,8.75-7.185,13.847-4.617l66.065,33.032
		c3.5,1.75,5.71,5.323,5.71,9.234s-2.21,7.484-5.71,9.234l-66.065,33.032C135.21,299.004,133.629,299.359,132.081,299.359z"
			/>
			<path
				fill="#AA7346"
				d="M379.806,299.359c-1.548,0-3.129-0.351-4.605-1.093l-66.065-33.032c-3.5-1.75-5.71-5.323-5.71-9.234
		s2.21-7.484,5.71-9.234l66.065-33.032c5.097-2.54,11.298-0.476,13.847,4.617c2.548,5.101,0.484,11.298-4.621,13.851L336.831,256
		l47.597,23.798c5.105,2.552,7.169,8.75,4.621,13.851C387.242,297.266,383.597,299.359,379.806,299.359z"
			/>
			<path
				fill="#AA7346"
				d="M115.621,200.262c-3.96,0-7.734-2.286-9.444-6.133c-2.315-5.21,0.032-11.31,5.242-13.625
		l74.323-33.032c5.226-2.339,11.315,0.036,13.629,5.238c2.315,5.21-0.032,11.31-5.242,13.625l-74.323,33.032
		C118.444,199.976,117.016,200.262,115.621,200.262z"
			/>
			<path
				fill="#AA7346"
				d="M396.379,200.262c-1.395,0-2.823-0.286-4.185-0.895l-74.323-33.032
		c-5.21-2.315-7.556-8.415-5.242-13.625c2.306-5.202,8.387-7.577,13.629-5.238l74.323,33.032c5.21,2.315,7.556,8.415,5.242,13.625
		C404.113,197.976,400.339,200.262,396.379,200.262z"
			/>
			<path
				fill="#AA7346"
				d="M341.879,385.843c-5.387,0-10.508-2.339-14.04-6.419l-28.895-33.339l-28.903,33.347
		c-7.048,8.145-21.024,8.153-28.089-0.008l-28.895-33.339l-28.903,33.347c-7.056,8.149-21.016,8.149-28.081-0.008l-15.226-17.565
		c-3.734-4.306-3.274-10.827,1.04-14.56c4.306-3.742,10.823-3.27,14.556,1.036l13.669,15.77l28.903-33.343
		c3.532-4.077,8.653-6.411,14.04-6.411c0.008,0,0.008,0,0.016,0c5.387,0.004,10.5,2.343,14.032,6.419L256,364.109l28.903-33.347
		c3.524-4.069,8.637-6.407,14.024-6.411c0.008,0,0.008,0,0.016,0c5.387,0,10.508,2.335,14.04,6.411l28.903,33.343l13.669-15.77
		c3.726-4.306,10.242-4.782,14.556-1.036c4.315,3.734,4.774,10.254,1.04,14.56l-15.234,17.573
		C352.395,383.504,347.274,385.843,341.879,385.843z"
			/>
		</g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
	</svg>
);
