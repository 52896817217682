module.exports = {
	getOptions(options, answers, productId = false, questions = null) {
		// flatting the array that comes back from the recursive function
		const optionsList =
			options
				?.map((option) => this._prepareOptions(option, false, answers, productId, questions))
				?.flat(Infinity) ?? [];
		// get unique options (avoid double selecting options)
		const uniqueOptions = [...new Map(optionsList.map((item) => [item.id, item])).values()];
		return uniqueOptions;
	},
	_prepareOptions(option, optionParameters, answers, productId = false, questions = null) {
		if (option?.isMasked) {
			return option.maskedOptions
				.filter((innerOption) => {
					// remove NOA with shortcut
					if (innerOption?.isNoneOfTheAbove) {
						return false;
					}
					// isolate surface options
					if (!innerOption?.isMasked) {
						const parameters = optionParameters || option?.maskedParameters;

						// Filter OTS if logic is not-selected & user did not select it
						if (innerOption?.isOtherSpecify) {
							// 1 - check if we are on a product of splits (product id exists)
							// 2 - verify if we are masking from within the split or from outside(see if splits answer exists)
							// 3 - choose between getting the answer from splits or from the original question
							const otherAnswer =
								productId && `${option?.maskedParameters?.maskedQuestionId}-${productId}` in answers
									? answers[`${option?.maskedParameters?.maskedQuestionId}-${productId}`]
									: answers[option?.maskedParameters?.maskedQuestionId];
							if (
								String(otherAnswer?.otherOptionId) !== String(innerOption?.id) &&
								parameters?.logic !== 'selected'
							) {
								return false;
							}
						}

						// 1 - check if we are on a product of splits (product id exists)
						// 2 - verify if we are masking from within the split or from outside(see if splits answer exists)
						// 3 - choose between getting the answer from splits or from the original question
						// Note -> here we use the "parameters?.maskedQuestionId' to better serve the recursive functionality
						const answer =
							productId && `${parameters?.maskedQuestionId}-${productId}` in answers
								? answers[`${parameters?.maskedQuestionId}-${productId}`]
								: answers[parameters?.maskedQuestionId];

						// grab selected and available options - This avoids loosing available options on the third HOP
						let selectedValues = answer?.optionId || answer?.value || [];
						const availableOptions = answer?.availableOptions || [];
						let notSelected = availableOptions.filter((id) => !selectedValues.includes(String(id)));

						// make sure they are arrays(single selections are simple strings)
						if (!Array.isArray(selectedValues)) {
							selectedValues = [selectedValues];
						}
						if (!Array.isArray(notSelected)) {
							notSelected = [notSelected];
						}

						// logic for ranked questions
						if (answer?.type === 'ranked') {
							const optionRanking = answer.value.findIndex((id) => id === innerOption.id) + 1;
							if (parameters?.position === 'top') {
								return optionRanking > 0 && optionRanking <= parameters?.rank;
							}
							const maskedQuestion = questions.find((q) => q.id === parameters?.maskedQuestionId);
							const topNSet =
								maskedQuestion?.settings?.find((s) => s?.label === 'top-n')?.value === 'true';

							const topN = topNSet
								? parseInt(maskedQuestion?.settings?.find((s) => s?.label === 'top-n-limit')?.value) ||
								  1
								: Math.min(availableOptions?.length || 0, 10);

							return optionRanking > 0 && topN - optionRanking < parameters?.rank;
						}

						// check the logic and it's inverse
						if (parameters?.logic === 'selected') {
							return selectedValues.includes(String(innerOption?.id));
						}
						return notSelected.includes(String(innerOption?.id));
					}
					return true;
				})
				.map((subOption) => {
					// keep recursing
					if (subOption.isMasked) {
						return this._prepareOptions(
							subOption,
							optionParameters || option?.maskedParameters,
							answers,
							productId,
							questions,
						)?.flat(Infinity);
					}

					// fill OTS label if selected previously
					if (subOption?.isOtherSpecify) {
						// 1 - check if we are on a product of splits (product id exists)
						// 2 - verify if we are masking from within the split or from outside(see if splits answer exists)
						// 3 - choose between getting the answer from splits or from the original question
						const answer =
							productId && `${option?.maskedParameters?.maskedQuestionId}-${productId}` in answers
								? answers[`${option?.maskedParameters?.maskedQuestionId}-${productId}`]
								: answers[option?.maskedParameters?.maskedQuestionId];
						if (String(answer?.otherOptionId) === String(subOption?.id)) {
							subOption.label = answer?.otherValue;
						}
					}
					return subOption;
				});
		}
		return option;
	},
	getMaskedQuestionsRecursevely(option) {
		if (option.maskedOptions.length) {
			return option.maskedOptions.map((innerOption) => this.getMaskedQuestionsRecursevely(innerOption));
		}
		return option;
	},
	getMaskedPossibleOptions(options) {
		const maskedOptions = options.map((option) => this.getMaskedQuestionsRecursevely(option)).flat(Infinity);
		return maskedOptions;
	},
};
