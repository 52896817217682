import { useState, useRef } from 'react';

export default function useDebounceCallback(debounceDelay = 1000) {
  const timerRef = useRef(null);
  const [debounceStartTimer, setDebounceStartTimer] = useState(new Date);

  const debounceCallback = (callback) => {
    const delay = new Date - debounceStartTimer;
    const debounceTimer = delay > debounceDelay ? 0 : debounceDelay - delay;

    if (timerRef?.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(callback, debounceTimer);
  };

  const updateDebounceTimer = () => {
    setDebounceStartTimer(new Date);
  }

  return [updateDebounceTimer, debounceCallback];
}
