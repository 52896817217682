export default {
	'this-survey-does-not-exist': '申し訳ありません、このアンケート調査は存在しません。',
	'survey-closed': '申し訳ありません、このアンケート調査はすでに終了しました。',
	'survey-no-products': 'このアンケート調査には製品がありません。',
	'survey-no-products-summary': 'この調査の作成者である場合は、ダッシュボードで製品を作成してから続行してください。',
	'survey-completed': 'すでにアンケート調査にご回答済みです。',
	'summary-placeholder': 'エラーだと思われる場合は、このリンクの送信者にご連絡ください。',
	'gone-wrong': 'エラーが発生しました。',
	'try-again': 'もう一度試すか、しばらくしてから再試行してください。',
	'thank-you': 'ありがとうございます',
	'thank-you-summary': 'アンケート調査終了です。結果は送信されました。',
	'thank-you-summary-redirect': '数秒後にリダイレクトされます。',
	'page-not-found': 'エラーが発生しました',
	'page-not-found-placeholder': 'リンクに問題があります',
	'tap-your-favorite': '気に入ったもののチェックマークをタップしてください',
	or: 'または',
	'get-started-with-questions': 'まずはじめにお答えください',
	continue: '続ける',
	retry: '再試行',
	'tell-us-how-you-feel-about-this-product': 'この製品についてのご意見をお聞かせください',
	begin: '開始',
	'how-would-you-describe-this-product-to-a-friend': '友人にこの製品について話すとしたら、どのように説明しますか？',
	'type-your-response-here': 'こちらに回答をご入力ください…',
	'sum-of-total-characters': '計${TOTAL} 文字中${SUM}文字',
	edit: '編集',
	remove: '削除',
	'how-do-you-feel-about-this-part': 'アンケート調査にご参加いただき、いかがでしたか？',
	'tap-on-an-area-and-choose-an-emoji': 'いずれかのエリアをタップして、絵文字をお選びください',
	'im-finished': '終了',
	maintenance: 'メンテナンス中',
	'maintenance-mode': 'Upsiideは現在メンテナンス中です。しばらくしてからご利用ください。',
	'please-specify': '具体的にご記入ください',
	'monadic-split-statement':
		'次の画面では、アイデアをお見せします。このアイデアに関する質問をご用意しておりますので、入念にご確認ください。',
	disapproval: '非難',
	anger: '怒り',
	sad: '悲しみ',
	joy: '喜び',
	love: '愛情',
	trust: '信頼',
	hope: '希望',
	proud: '誇り',
	amazed: '驚嘆',
	surprised: '驚き',
	guilt: '罪悪感',
	curious: '好奇心',
	jealous: '嫉妬心',
	anticipation: '期待感',
	optimistic: '楽観的',
	anxious: '気がかり',
	fear: '恐怖',
	despair: '絶望',
	disbelief: '不信感',
	cynicism: '皮肉',
	regret: '後悔',
	disgust: '嫌悪感',
	annoyed: 'イラつき',
	aggressive: '攻撃的',
	shocked: 'ショックを受けた',
	choose: '選んでください {{amount}}',
	'up-to': '[number]個まで選んでください {{amount}}',
	unlimited: 'いくつでも当てはまるものを選んでください',
	'single-select': '一つ選択してください',
	number: '数を入力してください',
	'number-between-min-max': '{{min}}から{{max}}の数を入力してください',
	'number-min': '{{min}}以上の数を入力してください',
	'number-max': '{{max}}以下の数を入力してください',
	'open-ended-pledge':
		'本アンケート調査には、貴方ご自身の言葉でお答えいただく質問があります。貴方のお答えは自動的にチェックされ、弊社の品質要件を満たしていることが確認されます。続行することにご同意いただけますか。 ',
	'open-ended-pledge-accept': 'はい、同意する',
	'open-ended-pledge-decline': 'いいえ、同意しない',
	'rh-1-label': 'スポーツでないものは次のどれですか。',
	'rh-1-o-1': 'バスケットボール',
	'rh-1-o-2': 'テニス',
	'rh-1-o-3': 'クッキー',
	'rh-1-o-4': 'ホッケー',
	'rh-2-label': '２＋３は何ですか。',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': '果物は次のどれですか。',
	'rh-3-o-1': 'ミルク',
	'rh-3-o-2': 'チョコレート',
	'rh-3-o-3': '卵',
	'rh-3-o-4': 'リンゴ',
	'open-end-max-length': '入力した回答が長すぎます',
	'heatmap-click-image': `コメントを追加するには画像をクリックしてください`,
	'heatmap-click-image-no-comments': `画像をクリックしてください`,
	'heatmap-click-image-product-card': `コメントを追加するにはカードをクリックしてください`,
	'heatmap-click-image-product-card--no-comment': `カードをクリックしてください`,
	'heatmap-type-comment': `コメントを入力`,
	cancel: `キャンセル`,
	skip: 'スキップ',
	ranked: 'を配置',
	top: '上位',
	'tap-or-drag': 'タップまたはドラッグ＆ドロップしてランクを選択します',
	unranked: '置かれていない',
	reset: '組み替える',
	'no-more-options': 'これ以上配置するオプションはありません',
	'rank-list-full': 'ランクリストはいっぱいです。あなたの選択のうちの1つをランク解除してください',
};
