// React & Redux
import React, { useState } from 'react';
import { connect } from 'react-redux';

// Text AI sub-sections
import TextAIProductIntro from '../TextAI/ProductIntro';
import TextAIProductFeedback from '../TextAI/ProductFeedback';
import TextAIProductDescription from '../TextAI/ProductDescription';

// Actions
import * as actions from '../../actions';

// Selectors
import * as selectors from '../../selectors';

const TextAI = ({ study = {}, sections, currentSectionIndex, setCurrentSectionIndex, textAIProductIds, ...props }) => {
	const [currProductIndex, setCurrProductIndex] = useState(0);
	const [textAIStep, setTextAIStep] = useState('intro');

	const textAiProduct = study.products.find(p => p.id === textAIProductIds[currProductIndex]);
	const next = step => {
		if (currProductIndex + 1 === textAIProductIds.length && step === 'intro') {
			// TODO: Fix the problem preventing you from proceeding to 'End'
			setCurrentSectionIndex(sections.length - 1);
		} else {
			setTextAIStep(step);
			if (step === 'intro') {
				setCurrProductIndex(currProductIndex + 1);
			}
		}
	};

	const getImageUrl = product => {
		let { fieldOneType, fieldTwoType } = product;
		if (fieldOneType && fieldOneType.toLowerCase() === 'asset') {
			return product.fieldOne[0].url;
		}
		if (fieldTwoType && fieldTwoType.toLowerCase() === 'asset') {
			return product.fieldTwo[0].url;
		}
		return null;
	};

	const textAIFeedbackNext = () => {
		if (getImageUrl(textAiProduct)) {
			next('feedback');
		} else {
			next('description');
		}
	};

	switch (textAIStep) {
		case 'intro':
			return (
				<TextAIProductIntro 
					{...props} 
					textAiProduct={textAiProduct} 
					onNext={() => textAIFeedbackNext()}
				/>
			);
		case 'feedback':
			return (
				<TextAIProductFeedback
					{...props}
					textAiProduct={textAiProduct}
					onNext={() => next('description')}
					imgUrl={getImageUrl(textAiProduct)}
				/>
			);
		case 'description':
			return (
				<TextAIProductDescription
					{...props}
					textAiProduct={textAiProduct}
					onNext={() => next('intro')}
				/>
			);
	}
};

const mapStateToProps = state => ({
	study: selectors.getStudy(state),
	sections: selectors.getSections(state),
	currentSectionIndex: selectors.getCurrentSectionIndex(state),
	textAIProductIds: selectors.getTextAIProductId(state),
});

const mapDispatchToProps = dispatch => ({
	setCurrentSectionIndex: (currentSectionIndex, data) => dispatch(
		actions.setCurrentSectionIndex(currentSectionIndex, data)
	),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextAI);
