export default {
	'this-survey-does-not-exist': "Nous sommes désolés. Ce sondage n'existe pas.",
	'survey-closed': 'Nous sommes désolés. Ce sondage est maintenant fermé.',
	'survey-no-products': "Ce sondage n'a pas de produits.",
	'survey-no-products-summary':
		'Si vous êtes propriétaire de ce sondage, créez des produits dans le tableau de bord pour continuer.',
	'survey-completed': 'Vous avez déjà répondu à ce sondage.',
	'summary-placeholder':
		'Si vous croyez recevoir ce message par erreur, veuillez communiquer avec la personne qui vous a envoyé le lien.',
	'gone-wrong': 'Hm, quelque chose ne tourne pas rond.',
	'try-again': 'Essayez de nouveau ou revenez un peu plus tard.',
	'thank-you': 'Merci',
	'thank-you-summary': 'Le sondage est terminé et vos résultats ont été envoyés.',
	'thank-you-summary-redirect': 'Nous vous réacheminerons dans quelques secondes.',
	'page-not-found': 'Quelque chose ne tourne pas rond.',
	'page-not-found-placeholder': 'Oups, le lien est brisé.',
	'tap-your-favorite': 'Cochez la case de votre préférence',
	or: 'ou',
	'get-started-with-questions': 'Quelques questions pour commencer',
	continue: 'Continuer',
	retry: 'Réessayer',
	'tell-us-how-you-feel-about-this-product': 'Dites-nous ce que vous pensez de ce produit',
	begin: 'Commencer',
	'how-would-you-describe-this-product-to-a-friend': 'Comment décririez-vous ce produit à un ami?',
	'type-your-response-here': 'Tapez votre réponse ici…',
	'sum-of-total-characters': '${SUM} sur ${TOTAL} caractères',
	edit: 'Éditer',
	remove: 'Retirer',
	'how-do-you-feel-about-this-part': 'Que pensez-vous de cette partie?',
	'tap-on-an-area-and-choose-an-emoji': 'Appuyez sur une zone et choisissez un emoji',
	'im-finished': "j'ai terminé",
	maintenance: 'Maintenance',
	'maintenance-mode': 'Upsiide is currently undergoing maintenance. Please check back soon.',
	'please-specify': 'Veuillez préciser',
	'monadic-split-statement':
		'Sur l’écran suivant, nous allons vous présenter une idée. Veuillez prendre le temps de l’examiner attentivement, car nous vous poserons des questions à ce sujet.',
	disapproval: 'Désapprobation',
	anger: 'Colère',
	sad: 'Tristesse',
	joy: 'Joie',
	love: 'Amour',
	trust: 'Confiance',
	hope: 'Espoir',
	proud: 'Fierté',
	amazed: 'Stupéfaction',
	surprised: 'Surprise',
	guilt: 'Culpabilité',
	curious: 'Curiosité',
	jealous: 'Jalousie',
	anticipation: 'Anticipation',
	optimistic: 'Optimisme',
	anxious: 'Anxiété',
	fear: 'Peur',
	despair: 'Désespoir',
	disbelief: 'Incrédulité',
	cynicism: 'Cynisme',
	regret: 'Regret',
	disgust: 'Dégoût',
	annoyed: 'Ennui',
	aggressive: 'Agressivité',
	shocked: 'Choqué(e)',
	choose: 'Choisissez {{amount}}',
	'up-to': 'Choisissez au maximum {{amount}}',
	unlimited: 'Choisissez autant de réponses que vous le souhaitez',
	'single-select': 'Choisissez une',
	number: 'Saisissez un nombre',
	'number-between-min-max': 'Saisissez un nombre compris entre {{min}} et {{max}}',
	'number-min': 'Saisissez un nombre de {{min}} ou plus',
	'number-max': 'Saisissez un nombre de {{max}} ou moins',
	'open-ended-pledge':
		'Cette enquête contient des questions auxquelles il vous sera demandé de répondre avec vos propres mots. Vos réponses seront automatiquement vérifiées pour assurer qu’elles répondent à nos critères de qualité. Acceptez-vous de continuer ?',
	'open-ended-pledge-accept': 'Oui, j’accepte',
	'open-ended-pledge-decline': "Non, je n'accepte pas",
	'rh-1-label': 'Parmi les choix suivants, lequel n’est pas un sport ?',
	'rh-1-o-1': 'Basketball',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Gâteau',
	'rh-1-o-4': 'Hockey',
	'rh-2-label': 'Combien font 2+3 ?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Parmi les choix suivants, lequel est un fruit ?',
	'rh-3-o-1': 'Lait',
	'rh-3-o-2': 'Chocolat',
	'rh-3-o-3': 'Œuf',
	'rh-3-o-4': 'Pomme',
	'open-end-max-length': 'Désolé, la réponse saisie est trop longue',
	'heatmap-click-image': `Cliquez sur l'image pour ajouter un commentaire`,
	'heatmap-click-image-no-comments': `Cliquez n’importe où sur l’image`,
	'heatmap-click-image-product-card': `Cliquez sur la carte pour ajouter un commentaire'`,
	'heatmap-click-image-product-card--no-comment': `Cliquez n’importe où sur la carte`,
	'heatmap-type-comment': `Saisissez un commentaire`,
	cancel: `Annuler`,
	skip: 'Sauter',
	ranked: 'Classé',
	top: 'premier',
	'tap-or-drag': 'Appuyez ou faites glisser et déposez pour classer',
	unranked: 'Non classé',
	reset: 'Réinitialiser',
	'no-more-options': "Plus d'options de classement",
	'rank-list-full': "La liste de classement est pleine, supprimez le classement de l'un de vos choix",
};
