export default {
	'this-survey-does-not-exist': 'معذرت خواہ ہیں، یہ سروے موجود ہی نہیں۔',
	'survey-closed': 'معذرت خواہ ہیں، یہ سروے اب بند ہو چکا ہے۔',
	'survey-no-products': 'اس سروے میں کوئی پراڈکٹس موجود نہیں۔',
	'survey-no-products-summary': 'اگر آپ اس سروے کے مالک ہیں، تو آگے بڑھنے کے لئے ڈیش بورڈ میں پراڈکٹس تخلیق کریں۔',
	'survey-completed': 'آپ یہ سروے پہلے ہی مکمل کر چکے ہیں۔',
	'summary-placeholder':
		'اگر آپ کو یقین ہے کہ یہ غلطی ہے، تو براہ کرم اس فرد تک رسائی کریں جس نے آپ کو یہ لنک بھیجا۔',
	'gone-wrong': 'ہمممم، کچھ ہے جو ٹھیک نہیں۔',
	'try-again': 'دوبارہ کوشش کریں یا بعد میں تشریف لائیں۔',
	'thank-you': 'آپ کا شکریہ',
	'thank-you-summary': 'سروے مکمل ہے، اور آپ کے نتائج جمع ہو گئے ہیں۔',
	'thank-you-summary-redirect': 'آپ کو چند سیکنڈز میں واپس لے جایا جائے گا۔',
	'page-not-found': 'کچھ ٹھیک نہیں ہے',
	'page-not-found-placeholder': 'اوہ، لنک ٹوٹا ہوا ہے',
	'tap-your-favorite': 'اپنے پسندیدہ پر چیک مارک کو ٹیپ کریں',
	or: 'یا',
	'get-started-with-questions': 'ہمارے آغاز کی خاطر چند سوالات',
	continue: 'جاری رکھیں',
	retry: 'دوبارہ کوشش کریں',
	'tell-us-how-you-feel-about-this-product': 'ہمیں بتائیں کہ آپ کو اس پراڈکٹ کے بارے میں کیسا محسوس ہوا',
	begin: 'شروع کریں',
	'how-would-you-describe-this-product-to-a-friend': 'آپ کسی دوست کے سامنے اس پراڈکٹ کی تعریف کیسے کریں گے۔',
	'type-your-response-here': 'اپنا جواب یہاں‎ â€¦‎ ٹائپ کریں',
	'sum-of-total-characters': '${SUM} کُل ${TOTAL} حروف',
	edit: 'ترمیم کریں',
	remove: 'ہٹائیں',
	'how-do-you-feel-about-this-part': 'آپ کو یہ حصہ کیسا لگا؟',
	'tap-on-an-area-and-choose-an-emoji': 'کسی حصے پر ٹیپ کریں اور ایموجی منتخب کریں',
	'im-finished': 'میرا کام ختم ہو گیا',
	maintenance: 'بحالی',
	'maintenance-mode': 'Upsiide پر فی الوقت مرمت چل رہی ہے۔ براہ کرم جلد دوبارہ چیک کریں۔',
	'please-specify': 'براہ کرم واضح کریں',
	'monadic-split-statement':
		'اگلی اسکرین پر، ہم آپ کو ایک تصور دکھائیں گے۔ براہ کرم اس کا احتیاط سے جائزہ لیں کیونکہ ہمارے پاس اس کے حوالے سے سوالات ہوں گے۔',
	disapproval: 'نامنظوری',
	anger: 'غصہ',
	sad: 'اداس',
	joy: 'لطف',
	love: 'پیار',
	trust: 'بھروسہ',
	hope: 'امید',
	proud: 'فخر',
	amazed: 'دم بخود',
	surprised: 'حیرت زدہ',
	guilt: 'شرمندگی',
	curious: 'پُرتجسس',
	jealous: 'حاسد',
	anticipation: 'پیش بینی',
	optimistic: 'پُرامید',
	anxious: 'مضطرب',
	fear: 'خوف',
	despair: 'مایوسی',
	disbelief: 'بے اعتباری',
	cynicism: 'خشک مزاجی',
	regret: 'پچھتاوا',
	disgust: 'نفرت',
	annoyed: 'غصے میں',
	aggressive: 'غضبناک',
	shocked: 'صدمہ زدہ',
	choose: 'انتخاب کریں {{amount}}',
	'up-to': 'انتخاب کریں تا {{amount}}',
	unlimited: 'جتنے چاہیں منتخب کریں',
	'single-select': 'ایک منتخب کریں',
	number: 'کوئی نمبر درج کریں',
	'number-between-min-max': '{{min}} اور {{max}} کے درمیان نمبر درج کریں',
	'number-min': '{{min}} یا اس سے بڑا نمبر درج کریں',
	'number-max': '{{max}} یا اس سے چھوٹا نمبر درج کریں',
	'open-ended-pledge':
		'اس سروے میں ایسے سوالات ہیں جن میں آپ سے اپنے لفظوں میں جواب دینے کو کہا جائے گا۔ آپ کے جواب کی جانچ خود کار طریقے سے اس بات کو یقینی بنانے کے لیے کی جائے گی کہ وہ ہمارے معیار کے تقاضوں کو پورا کرتے ہیں۔ کیا آپ جاری رکھنے سے متفق ہیں؟ ',
	'open-ended-pledge-accept': 'ہاں، میں متفق ہوں',
	'open-ended-pledge-decline': 'نہیں، میں متفق نہیں ہوں',
	'rh-1-label': 'مندرجہ ذیل میں سے کون سی چيز کھیل نہیں ہے؟',
	'rh-1-o-1': 'باسکٹ بال',
	'rh-1-o-2': 'ٹینس',
	'rh-1-o-3': 'کوکی',
	'rh-1-o-4': 'ہاکی',
	'rh-2-label': '2+3 کتنا ہوتا ہے؟',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'مندرجہ ذیل میں سے کون سی چیز پھل ہے؟',
	'rh-3-o-1': 'دودھ',
	'rh-3-o-2': 'چاکلیٹ',
	'rh-3-o-3': 'انڈا',
	'rh-3-o-4': 'سیب',
	'open-end-max-length': 'معذرت، درج کردہ جواب بہت لمبا ہے۔',
	'heatmap-click-image': ' تبصرہ شامل کرنے کے لیے تصویر پر کلک کریں',
	'heatmap-click-image-no-comments': ' تصویر پر کہیں بھی کلک کریں',
	'heatmap-click-image-product-card': ' تبصرہ شامل کرنے کے لیے کارڈ پر کلک کریں ',
	'heatmap-click-image-product-card--no-comment': ' کارڈ پر کہیں بھی کلک کریں',
	'heatmap-type-comment': ' تبصرہ ٹائپ کریں',
	cancel: 'منسوخ کریں',
	skip: 'چھوڑ دو',
	ranked: 'درجہ بندی',
	top: 'اوپر',
	'tap-or-drag': 'درجہ بندی کے لیے تھپتھپائیں یا گھسیٹیں اور چھوڑیں۔',
	unranked: 'غیر درجہ بندی',
	reset: 'دوبارہ ترتیب دیں۔	',
	'no-more-options': 'درجہ بندی کے لیے مزید اختیارات نہیں ہیں۔',
	'rank-list-full': 'درجہ بندی کی فہرست بھری ہوئی ہے، اپنے انتخاب میں سے کسی ایک کو ہٹا دیں۔',
};
