import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import DevicePreviewHeader from 'src/components/new/DevicePreviewHeader';
import MainContainer from 'src/components/new/MainContainer';
import MaintenanceMode from 'src/domains/main/pages/MaintenanceMode';
import { FooterProvider } from 'src/components/helpers/Footer';
import * as misc from 'src/utilities/misc';
import DomainRoutes from 'src/domains/routes';
import i18n from './i18n';
import { UPThemeProvider } from '@upsiide/ui-components';
import ScrollContainer from './components/shared/ScrollContainer';

class App extends React.Component {
	constructor() {
		super();
		this.state = {
			className: '',
		};
		this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
	}

	componentDidMount() {
		window.addEventListener('changeLanguage', this.handleChangeLanguage);
	}

	componentWillUnmount() {
		window.removeEventListener('changeLanguage', this.handleChangeLanguage);
	}

	handleChangeLanguage() {
		if (i18n.isRTL()) {
			this.setState({ className: 'rtl' });
		}
	}

	render() {
		const urlParams = misc.getAllUrlParams();

		if (process.env.maintenanceMode && !urlParams.skipMaintenance) {
			return (
				<div className="maintenance-container">
					<MaintenanceMode />
				</div>
			);
		}
		return (
			<MainContainer className={this.state.className}>
				<UPThemeProvider>
					<FooterProvider>
						<DevicePreviewHeader />
						<ScrollContainer>
							<BrowserRouter>
								<DomainRoutes path="/*" />
							</BrowserRouter>
						</ScrollContainer>
					</FooterProvider>
				</UPThemeProvider>
			</MainContainer>
		);
	}
}

export default App;
