const PIPING_KEY = 'pipedBlock';
const PIPING_LOGIC = 'logic';

// Matching example: `{{${PIPING_KEY}:123}}`
export const PIPING_REGEX = new RegExp(
	`{{${PIPING_KEY}:\\s*([1-9][0-9]+|invalid)(,\\s*${PIPING_LOGIC}:\\s*(selected|not-selected))*}}`,
	'g',
);

export const PIPING_REGEX_WITH_RANK = new RegExp(
	`{{${PIPING_KEY}:\\s*([1-9][0-9]+|invalid)(,\\s*${PIPING_LOGIC}:\\s*(selected|not-selected))?(,\\s*position:\\s*(top|bottom))?(,\\s*rank:\\s*[1-9][0-9]*)?}}`,
	'g',
);

/**
 *
 * @param {*} label
 * @returns
 */
export const hasPipedQuestion = label => {
	if (!label) return;
	return PIPING_REGEX.test(label) || PIPING_REGEX_WITH_RANK.test(label);
};
