import React from 'react';
import Stage from './Stage';
import cn from 'src/utilities/bem-cn';
import cards from '@upsiide/product-cards';
import './styles.scss';

const className = 'commitment';
const el = name => cn(className, name);

class Commitment extends React.Component {

	resetScrollContainerClasses = () => {
		setTimeout(() => {
			// Reset scroll container 
			const scrollContainer = document.querySelector(`[class*='scroll-container']`);
			if (scrollContainer) {
				scrollContainer.classList.remove('scrolled-to-bottom');
				scrollContainer.classList.remove('remove-scroll-indicator');
				scrollContainer.scrollTo(0, 0);
			}
		});
	}
	getProducts = products => {
		const { onClick } = this.props;
		return Array.from({ length: 2 }, (_, i) => {
			return ({
				id: products[i].id,
				ideaScreenLayout: products[i].ideaScreenLayout,
				imageSize: products[i].imageSize,
				imageCropping: products[i].imageCropping,
				element: (
					<cards.CommitmentCard
						product={products[i]}
						interactionsDisabled={products[i]?.ideaScreenLayout === 'imageOnly'}
						onClick={() => {
							onClick(products[i].id);
							this.resetScrollContainerClasses();
						}}
					/>
				),
			}
			)
		});
	}

	render() {
		const { products, study, currentSection } = this.props;
		const { settings } = currentSection;
		// Idea screen layout
		const ideaScreenLayoutSetting = settings ? settings.find(s => s.label === 'idea_screen_layout') : { value: '' };
		const ideaScreenLayout = ideaScreenLayoutSetting && ideaScreenLayoutSetting.value ? ideaScreenLayoutSetting.value : 'invalid_layout';
		// Idea image size
		const imageSizeSetting = settings ? settings.find(s => s.label === 'image_size') : { value: '' };
		const imageSize = imageSizeSetting && imageSizeSetting.value ? imageSizeSetting.value : 'medium';
		// Idea image cropping
		const imageCroppingSetting = settings ? settings.find(s => s.label === 'image_cropping') : { value: '' };
		const imageCropping = imageCroppingSetting && imageCroppingSetting.value ? imageCroppingSetting.value : 'fit';

		const twoColumn = ideaScreenLayout === 'sideBySide';
		products.map(p => {
			// * Product level
			p.ideaScreenLayout = ideaScreenLayout;
			// TODO: Ideally these would be passed in at the top level only and not prefixed here, however I need to sort of the "Get Started" scenario
			p.imageSize = imageSize ? `size-${imageSize}` : 'size-medium';
			p.imageCropping = imageCropping ? `image-${imageCropping}` : 'image-fit';
			//
			p.twoColumn = twoColumn;
			// * Stack product level
			if (!p.stackProduct) {
				p.stackProduct = {};
			}
			p.stackProduct.twoColumn = twoColumn;
			p.stackProduct.ideaScreenLayout = ideaScreenLayout;
			return p;
		});
		const stageData = this.getProducts(products);
		return (<Stage data={stageData} render={props => props.element} study={study} currentSection={currentSection} />);
	}
}

export default Commitment;
