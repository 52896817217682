export default {
	'this-survey-does-not-exist': 'ขออภัย ไม่พบแบบสำรวจนี้',
	'survey-closed': 'ขออภัย ปิดรับแบบสำรวจนี้แล้ว',
	'survey-no-products': 'แบบสำรวจนี้ไม่มีผลิตภัณฑ์',
	'survey-no-products-summary': 'หากคุณเป็นเจ้าของแบบสำรวจนี้ ให้สร้างผลิตภัณฑ์ในหน้าแดชบอร์ดเพื่อดำเนินการต่อ',
	'survey-completed': 'คุณตอบแบบสำรวจนี้เรียบร้อยแล้ว',
	'summary-placeholder': 'หากคุณคิดว่านี่เป็นข้อผิดพลาด โปรดติดต่อบุคคลที่ส่งลิงก์นี้ให้คุณ',
	'gone-wrong': 'หืม...เกิดข้อผิดพลาด',
	'try-again': 'ลองใหม่อีกครั้งหรือกลับมาลองใหม่ภายหลัง',
	'thank-you': 'ขอบคุณ',
	'thank-you-summary': 'ตอบแบบสำรวจเสร็จแล้ว ระบบได้รับคำตอบจากคุณเรียบร้อยแล้ว',
	'thank-you-summary-redirect': 'ระบบจะนำคุณไปสู่หน้าใหม่อีกไม่กี่วินาที',
	'page-not-found': 'เกิดข้อผิดพลาด',
	'page-not-found-placeholder': 'แย่ล่ะ...ลิงก์เสีย',
	'tap-your-favorite': 'แตะที่เครื่องหมายถูกในรายการโปรด',
	or: 'หรือ',
	'get-started-with-questions': 'คำถามสองสามข้อเพื่อเริ่ม',
	continue: 'ดำเนินการต่อ',
	retry: 'ลองใหม่',
	'tell-us-how-you-feel-about-this-product': 'บอกเราว่าคุณรู้สึกอย่างไรเกี่ยวกับผลิตภัณฑ์นี้',
	begin: 'เริ่ม',
	'how-would-you-describe-this-product-to-a-friend': 'คุณจะอธิบายผลิตภัณฑ์นี้แก่เพื่อนอย่างไร',
	'type-your-response-here': 'พิมพ์คำตอบของคุณที่นี่ ...',
	'sum-of-total-characters': '${SUM} จาก ${TOTAL} ตัวอักษร',
	edit: 'แก้ไข',
	remove: 'ลบ',
	'how-do-you-feel-about-this-part': 'คุณรู้สึกอย่างไรเกี่ยวกับส่วนนี้',
	'tap-on-an-area-and-choose-an-emoji': 'แตะที่พื้นที่และเลือกอิโมจิ',
	'im-finished': 'ฉันเสร็จแล้ว',
	maintenance: 'Maintenance',
	'maintenance-mode': 'Upsiide is currently undergoing maintenance. Please check back soon.',
	'please-specify': 'กรุณาระบุ',
	'monadic-split-statement':
		'ในหน้าจอถัดไป เราจะแสดงแนวคิดให้คุณดู โปรดทบทวนอย่างละเอียดเนื่องจากเราจะมีคําถามเกี่ยวกับแนวคิดนี้',
	disapproval: 'ความไม่เห็นด้วย',
	anger: 'ความโกรธ',
	sad: 'เศร้า',
	joy: 'ความเบิกบานใจ',
	love: 'ความรัก',
	trust: 'ความเชื่อมั่น',
	hope: 'ความหวัง',
	proud: 'ภูมิใจ',
	amazed: 'แปลกใจ',
	surprised: 'ประหลาดใจ',
	guilt: 'ความรู้สึกผิด',
	curious: 'อยากรู้',
	jealous: 'อิจฉา',
	anticipation: 'ความคาดหวัง',
	optimistic: 'มองโลกในแง่ดี',
	anxious: 'กังวลใจ',
	fear: 'ความกลัว',
	despair: 'ความสิ้นหวัง',
	disbelief: 'ความไม่เชื่อ',
	cynicism: 'การเยาะเย้ยถากถาง',
	regret: 'ความเสียใจ',
	disgust: 'ความรังเกียจ',
	annoyed: 'รำคาญ',
	aggressive: 'ก้าวร้าว',
	shocked: 'ตกใจ',
	choose: 'เลือก {{amount}}',
	'up-to': 'เลือกได้สูงสุด {{amount}}',
	unlimited: 'เลือกได้มากเท่าที่คุณต้องการ',
	'single-select': 'เลือกหนึ่งอัน',
	number: 'ป้อนตัวเลข',
	'number-between-min-max': 'ป้อนตัวเลขระหว่าง {{min}} ถึง {{max}}',
	'number-min': 'ป้อนตัวเลข {{min}} หรือมากกว่า',
	'number-max': 'ป้อนตัวเลข {{max}} หรือน้อยกว่า',
	'open-ended-pledge':
		'แบบสำรวจนี้มีคำถามซึ่งคุณจะต้องตอบโดยใช้คำพูดของคุณเอง คำตอบของคุณจะได้รับการตรวจสอบโดยอัตโนมัติเพื่อให้แน่ใจว่าเป็นไปตามข้อกำหนดด้านคุณภาพของเรา คุณตกลงที่จะดำเนินการต่อหรือไม่ ',
	'open-ended-pledge-accept': 'ใช่ ฉันตกลง',
	'open-ended-pledge-decline': 'ไม่ ฉันไม่ตกลง',
	'rh-1-label': 'ข้อใดต่อไปนี้ไม่ใช่กีฬา',
	'rh-1-o-1': 'บาสเกตบอล',
	'rh-1-o-2': 'เทนนิส',
	'rh-1-o-3': 'คุกกี้',
	'rh-1-o-4': 'ฮอกกี้',
	'rh-2-label': '2+3 เท่ากับเท่าไร',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'ข้อใดต่อไปนี้เป็นผลไม้',
	'rh-3-o-1': 'นม',
	'rh-3-o-2': 'ช็อกโกแลต',
	'rh-3-o-3': 'ไข่',
	'rh-3-o-4': 'แอปเปิล',
	'open-end-max-length': 'ขออภัย คำตอบที่ป้อนยาวเกินไป',
	'heatmap-click-image': 'คลิกที่ภาพเพื่อเพิ่มความคิดเห็น',
	'heatmap-click-image-no-comments': 'คลิกที่ส่วนใดก็ได้บนภาพ',
	'heatmap-click-image-product-card': 'คลิกที่บัตรเพื่อเพิ่มความคิดเห็น ',
	'heatmap-click-image-product-card--no-comment': 'คลิกที่ส่วนใดก็ได้บนบัตร',
	'heatmap-type-comment': 'พิมพ์ความคิดเห็น',
	cancel: 'ยกเลิก',
	skip: 'ข้าม',
	ranked: 'จัดอันดับ',
	top: 'ตัวเลือก',
	'tap-or-drag': 'แตะหรือลากและวางเพื่อจัดอันดับ',
	unranked: 'ไม่ติดอันดับ',
	reset: 'รีเซ็ต',
	'no-more-options': 'ไม่มีตัวเลือกในการจัดอันดับอีกต่อไป',
	'rank-list-full': 'รายการอันดับเต็มแล้ว ยกเลิกการจัดอันดับหนึ่งในตัวเลือกของคุณ',
};
