import React, { useEffect, useState } from 'react';
import { RadioGroup, RadioButton } from '@upsiide/ui-components';
import cn from 'src/utilities/bem-cn';
import * as misc from 'src/utilities/misc';
import { useTranslation } from 'react-i18next';
import ImageZoom from 'src/components/new/ImageZoom';
import Input from 'src/components/shared/Input';

import './styles.scss';

const className = 'question-multi-select';
const el = (name, mod) => cn(className, name, mod);

const toggle = (c, v) => (c.indexOf(v) > -1 ? c.filter(($v) => $v !== v) : c.concat([v]));

const MultiSelect = ({
	question,
	disabled,
	value,
	options,
	onChange,
	otherSelected,
	onOtherChange,
	currentAttribute,
}) => {
	const { t } = useTranslation('main');
	const [otherValue, setOtherValue] = useState(null);
	const handleChange = (v) => {
		onChange(toggle(value || [], v));
	};

	const imageOnly = misc.getQuestionSetting(question, 'image_only') === 'true';

	useEffect(() => {
		setOtherValue('');
	}, [currentAttribute]);

	const onOtherValueChange = (otherValue) => {
		if (onOtherChange) {
			onOtherChange(otherValue);
		}
		setOtherValue(otherValue);
	};
	useEffect(() => {
		const pageContainer = document.querySelector('.scroll-container');
		if (pageContainer) {
			pageContainer.style.scrollBehavior = 'auto';
			pageContainer.scrollTo(0, 0);
		}
		// Reset scroll container
		const scrollContainer = document.querySelector(`[class*='scroll-container']`);
		if (scrollContainer) {
			scrollContainer.classList.remove('scrolled-to-bottom');
			scrollContainer.classList.remove('remove-scroll-indicator');
		}
	}, []);

	const optionsHaveAsset = () => options.find((option) => option.asset);

	const renderImageOption = (option) =>
		option.asset && (
			<ImageZoom className={el('option-image-container')}>
				<img
					className={`${el('option-image')} ${
						misc.getQuestionSetting(question, 'option_image_cropping') === 'crop'
							? el('option-image-crop')
							: ''
					}`}
					alt={option.label || option.value}
					src={misc.getAssetVariationUrl(option, ['large', 'full', 'medium', 'thumbnail'])}
				/>
			</ImageZoom>
		);

	const renderSingleOption = (option) => (
		<RadioButton
			disabled={disabled}
			checked={(value || []).indexOf(option.id.toString()) > -1}
			key={option.id}
			value={option.id.toString()}
			onClick={handleChange}
		>
			{!imageOnly || !option.asset || option.isNoneOfTheAbove || option.isOtherSpecify ? (
				<span
					className={`${el('option-label')}${option.asset ? ' has-asset' : ''}${
						option.isOtherSpecify && otherSelected ? ' hidden' : ''
					}`}
				>
					{option.label || option.value}
				</span>
			) : null}
			{option.isOtherSpecify && otherSelected && (
				<Input
					id="other-input-field"
					className={el('other-input')}
					placeholder={t('please-specify')}
					value={otherValue}
					onChange={onOtherValueChange}
				/>
			)}
			{renderImageOption(option)}
		</RadioButton>
	);

	const generateClassNames = () => {
		const hasOther = options.filter((o) => o.isOtherSpecify).length > 0 ? ' has-other-please-specify' : '';
		const hasNoneOfTheAbove = options.filter((o) => o.isNoneOfTheAbove).length > 0 ? ' has-none-of-the-above' : '';
		return `${className} ${!optionsHaveAsset() ? ' no-asset' : ''}${
			imageOnly ? ' image-only' : ''
		}${hasOther}${hasNoneOfTheAbove}`;
	};

	return (
		<RadioGroup multiSelect className={generateClassNames()}>
			{options.map((option) => renderSingleOption(option))}
		</RadioGroup>
	);
};

export default MultiSelect;
