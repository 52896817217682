import React, { useState, useEffect } from 'react';
// Localization
import { useTranslation } from 'react-i18next';

import { motion } from 'framer-motion';
import { getEmoji } from 'src/components/helpers/Emoji';
import './styles.scss';

const animationVariants = {
	hide: {
		y: 15,
		opacity: 0,
		scale: 0.5,
		transition: {
			duration: 0.2,
		},
	},
	show: {
		y: 0,
		opacity: 1,
		scale: 1,
		transition: {
			duration: 0.2,
		},
	},
};

const EmojiButton = ({ label, unchecked }) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	const { t } = useTranslation('main');

	useEffect(() => {
		let hasTouchScreen = false;
		if ('maxTouchPoints' in navigator) {
			hasTouchScreen = navigator.maxTouchPoints > 0;
		} else if ('msMaxTouchPoints' in navigator) {
			hasTouchScreen = navigator.msMaxTouchPoints > 0;
		} else {
			const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
			if (mQ && mQ.media === '(pointer:coarse)') {
				hasTouchScreen = !!mQ.matches;
			} else if ('orientation' in window) {
				hasTouchScreen = true; // deprecated, but good fallback
			} else {
				// Only as a last resort, fall back to user agent sniffing
				const UA = navigator.userAgent;
				hasTouchScreen =
					/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
					/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
			}
		}

		setIsMobile(hasTouchScreen);
	}, []);

	const activateTooltip = () => {
		document.querySelectorAll('.tooltiptext').forEach(tooltip => (tooltip.style.display = 'none'));
		document.querySelector(`.${label}`).style.display = 'flex';

		setShowTooltip(true);
		setTimeout(() => {
			setShowTooltip(false);
		}, 2000);
	};

	return (
		<div
			onMouseEnter={() => {
				if (!isMobile) activateTooltip();
			}}
			onMouseLeave={() => {
				if (!isMobile) setShowTooltip(false);
			}}
			style={{ width: 56, height: 56 }}
			onClick={() => {
				if (unchecked && isMobile) activateTooltip();
			}}
		>
			<motion.div
				className="emoji-button-container"
				key={`tooltip-animation-${label}`}
				initial={false}
				animate={showTooltip ? 'show' : 'hide'}
				variants={animationVariants}
			>
				<span className={`tooltiptext ${label}`}>{t(label)}</span>
			</motion.div>
			<img src={getEmoji(label)} alt={t(label)} />
		</div>
	);
};

export default EmojiButton;
