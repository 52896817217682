import React from 'react';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

// Class is tied to scroll event listener
const className = 'scroll-container';
const el = (name) => cn(className, name);

class ScrollContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { height: 0, mobileDevice: '' };
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		window.addEventListener('orientationchange', this.updateWindowDimensions);
		// For Pinching gestures https://use-gesture.netlify.app/docs/gestures/
		window.addEventListener('gesturestart', this.handleGestures);
		window.addEventListener('gesturechange', this.handleGestures);
		window.addEventListener('gestureend', this.handleGestures);
		const ios = navigator.userAgent.match(/(iPhone|iPod)/i) ? 'ios-mobile' : '';
		const android = navigator.userAgent.match(/(android)/i) ? 'android-mobile' : '';
		this.setState({ mobileDevice: `${ios} ${android}` });
		if (ios) {
			document.body.classList.add(ios);
			// Disable Zoom In
			document.addEventListener('gesturestart', (e) => {
				e.preventDefault();
			});
		}
		if (android) {
			document.body.classList.add(android);
			// Disable Zoom In
			document.addEventListener('gesturestart', (e) => {
				e.preventDefault();
			});
		}
		const scrollContainer = document.querySelector(`.${className}`);
		if (scrollContainer) {
			scrollContainer.addEventListener('scroll', this.handleScroll);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		window.removeEventListener('orientationchange', this.updateWindowDimensions);
		// For Pinching gestures https://use-gesture.netlify.app/docs/gestures/
		window.addEventListener('gesturestart', this.handleGestures);
		window.addEventListener('gesturechange', this.handleGestures);
		window.addEventListener('gestureend', this.handleGestures);
		const scrollContainer = document.querySelector(`.${className}`);
		if (scrollContainer) {
			scrollContainer.removeEventListener('scroll', this.handleScroll);
		}
	}

	handleGestures = (e) => {
		e.preventDefault();
	};

	handleScroll = (e) => {
		const bottomBuffer = 20;
		const minScrollToDismissIndicator = 100;
		const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + bottomBuffer;
		if (bottom && e.target.scrollTop > 0) {
			const scrollContainer = document.querySelector(`.${className}`);
			scrollContainer.classList.add('scrolled-to-bottom');
		} else if (e.target.scrollTop > minScrollToDismissIndicator) {
			const scrollContainer = document.querySelector(`.${className}`);
			scrollContainer.classList.add('remove-scroll-indicator');
		}
	};

	updateWindowDimensions() {
		const previewHeader = document.querySelector('.device-preview-header');
		this.setState({ height: window.innerHeight });
	}

	render() {
		const styles = this.state.height ? { height: this.state.height } : {};
		const { mobileDevice } = this.state;
		return (
			<div className={`${className} ${mobileDevice}`} style={styles}>
				<div className={el('inner')}>{this.props.children}</div>
			</div>
		);
	}
}

export default ScrollContainer;
