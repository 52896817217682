export default {
	'this-survey-does-not-exist': 'Beklager, denne undersøgelse findes ikke.',
	'survey-closed': 'Beklager, denne undersøgelse er nu lukket.',
	'survey-no-products': 'Denne undersøgelse har ingen produkter.',
	'survey-no-products-summary':
		'Hvis du er ejeren af denne undersøgelse, skal du oprette produkter på dashboardet for at fortsætte.',
	'survey-completed': 'Du har allerede gennemført denne undersøgelse.',
	'summary-placeholder':
		'Hvis du mener, at dette er en fejl, bedes du kontakte den person, der har sendt dig dette link.',
	'gone-wrong': 'Hmmm, noget gik galt.',
	'try-again': 'Prøv igen, eller kom tilbage senere.',
	'thank-you': 'Tak',
	'thank-you-summary': 'Undersøgelsen er afsluttet, og dine resultater er indsendt.',
	'thank-you-summary-redirect': 'Du vil blive omdirigeret om et par sekunder.',
	'page-not-found': 'Der er noget galt',
	'page-not-found-placeholder': 'Ups, linket virker ikke',
	'tap-your-favorite': 'Tryk på fluebenet på din favorit',
	or: 'eller',
	'get-started-with-questions': 'Et par spørgsmål for at komme i gang',
	continue: 'Fortsæt',
	retry: 'Prøv igen',
	'tell-us-how-you-feel-about-this-product': 'Fortæl os, hvordan du har det med dette produkt',
	begin: 'Begynd',
	'how-would-you-describe-this-product-to-a-friend': 'Hvordan vil du beskrive dette produkt til en ven?',
	'type-your-response-here': 'Skriv dit svar her…',
	'sum-of-total-characters': '${SUM} af ${TOTAL} tegn',
	edit: 'Rediger',
	remove: 'Fjern',
	'how-do-you-feel-about-this-part': 'Hvordan har du det med denne del?',
	'tap-on-an-area-and-choose-an-emoji': 'Tryk på et område, og vælg en emoji',
	'im-finished': 'Jeg er færdig',
	maintenance: 'Vedligeholdelse',
	'maintenance-mode': 'Upsiide er i øjeblikket under vedligeholdelse. Vend tilbage igen snart.',
	'please-specify': 'Vær venlig at uddybe',
	'monadic-split-statement':
		'På næste skærmbillede viser vi dig en idé. Gennemgå den omhyggeligt, da vi stiller spørgsmål om den.',
	disapproval: 'Misbilligelse',
	anger: 'Vrede',
	sad: 'Vemodig',
	joy: 'Glæde',
	love: 'Kærlighed',
	trust: 'Tillid',
	hope: 'Håb',
	proud: 'Stolt',
	amazed: 'Forbløffet',
	surprised: 'Overrasket',
	guilt: 'Skyld',
	curious: 'Nysgerrig',
	jealous: 'Jaloux',
	anticipation: 'Forventning',
	optimistic: 'Optimistisk',
	anxious: 'Ængstelig',
	fear: 'Frygt',
	despair: 'Fortvivlelse',
	disbelief: 'Vantro',
	cynicism: 'Kynisme',
	regret: 'Fortrydelse',
	disgust: 'Afsky',
	annoyed: 'Irriteret',
	aggressive: 'Aggressiv',
	shocked: 'Chokeret',
	choose: 'Vælg {{amount}}',
	'up-to': 'Vælg op til {{amount}}',
	unlimited: 'Vælg så mange, du vil',
	'single-select': 'Vælg en',
	number: 'Indtast et tal',
	'number-between-min-max': 'Indtast et tal mellem {{min}} og {{max}}',
	'number-min': 'Indtast et tal, der er {{min}} eller større',
	'number-max': 'Indtast et tal, der er {{max}} eller mindre',
	'open-ended-pledge':
		'Denne undersøgelse indeholder spørgsmål, hvor du bliver bedt om at svare med dine egne ord. Dine svar vil automatisk blive kontrolleret for at sikre, at de opfylder vores kvalitetskrav. Accepterer du at fortsætte? ',
	'open-ended-pledge-accept': 'Ja, jeg accepterer',
	'open-ended-pledge-decline': 'Nej, jeg accepterer ikke',
	'rh-1-label': 'Hvilken af følgende er ikke en sportsgren?',
	'rh-1-o-1': 'Basketball',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Cookie',
	'rh-1-o-4': 'Ishockey',
	'rh-2-label': 'Hvad er 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Hvilken af følgende er en frugt?',
	'rh-3-o-1': 'Mælk',
	'rh-3-o-2': 'Chokolade',
	'rh-3-o-3': 'Æg',
	'rh-3-o-4': 'Æble',
	'open-end-max-length': 'Beklager, det indtastede svar er for langt',
	'heatmap-click-image': `Klik på billedet for at tilføje en kommentar`,
	'heatmap-click-image-no-comments': `Klik hvor som helst på billedet`,
	'heatmap-click-image-product-card': `Klik på kortet for at tilføje en kommentar`,
	'heatmap-click-image-product-card--no-comment': `Klik hvor som helst på kortet`,
	'heatmap-type-comment': `Skriv en kommentar,`,
	cancel: `Annuller,`,
	skip: 'Springe',
	ranked: 'Rangeret',
	top: 'Top',
	'tap-or-drag': 'Tryk eller træk og slip for at rangere',
	unranked: 'Urangeret',
	reset: 'Nulstil',
	'no-more-options': 'Ikke flere muligheder for at rangere',
	'rank-list-full': 'Ranglisten er fuld, fjern rangeringen af ​​et af dine valg',
};
