export default {
	'this-survey-does-not-exist': 'Ne pare rău, acest sondaj nu există.',
	'survey-closed': 'Ne pare rău, acest sondaj este acum închis.',
	'survey-no-products': 'Acest sondaj nu conține produse.',
	'survey-no-products-summary':
		'Dacă sunteți proprietarul acestui sondaj, creați produse în tabloul de bord pentru a continua.',
	'survey-completed': 'Ați completat deja acest sondaj.',
	'summary-placeholder':
		'În cazul în care considerați că s-a produs o eroare, contactați persoana care v-a trimis acest link.',
	'gone-wrong': 'Hmmm, ceva nu este în regulă.',
	'try-again': 'Încercați din nou sau reveniți mai târziu.',
	'thank-you': 'Vă mulțumim',
	'thank-you-summary': 'Sondajul este finalizat, iar rezultatele dvs. sunt trimise.',
	'thank-you-summary-redirect': 'Veți fi redirecționat în câteva secunde.',
	'page-not-found': 'Ceva nu este în regulă',
	'page-not-found-placeholder': 'Oh, probleme cu linkul',
	'tap-your-favorite': 'Atingeți bifa de pe varianta preferată',
	or: 'sau',
	'get-started-with-questions': 'Câteva întrebări pentru început',
	continue: 'Continuați',
	retry: 'Reîncercați',
	'tell-us-how-you-feel-about-this-product': 'Spuneți-ne ce părere aveți despre acest produs',
	begin: 'Începeți',
	'how-would-you-describe-this-product-to-a-friend': 'Cum ați descrie acest produs unui prieten?',
	'type-your-response-here': 'Introduceți răspunsul dvs. aiciâ€¦',
	'sum-of-total-characters': '${SUM} Din ${TOTAL} caractere',
	edit: 'Editați',
	remove: 'Eliminați',
	'how-do-you-feel-about-this-part': 'Ce părere aveți despre această parte?',
	'tap-on-an-area-and-choose-an-emoji': 'Atingeți o zonă și alegeți un emoji',
	'im-finished': 'Am terminat',
	maintenance: 'Mentenanță',
	'maintenance-mode': 'Upsiide este în prezent în curs de mentenanță. Reveniți în curând.',
	'please-specify': 'Vă rugăm să specificați',
	'monadic-split-statement':
		'Vă vom arăta o idee în ecranul următor. Examinați-o cu atenție, deoarece vom avea întrebări cu privire la aceasta.',
	disapproval: 'Dezaprobare',
	anger: 'Furie',
	sad: 'Trist',
	joy: 'Bucurie',
	love: 'Dragoste',
	trust: 'Încredere',
	hope: 'Speranță',
	proud: 'Mândru',
	amazed: 'Uimit',
	surprised: 'Surprins',
	guilt: 'Vinovăție',
	curious: 'Curios',
	jealous: 'Gelos',
	anticipation: 'Anticipare',
	optimistic: 'Optimist',
	anxious: 'Nerăbdător',
	fear: 'Frică',
	despair: 'Disperare',
	disbelief: 'Neîncredere',
	cynicism: 'Cinism',
	regret: 'Regret',
	disgust: 'Dezgust',
	annoyed: 'Enervat',
	aggressive: 'Agresiv',
	shocked: 'Șocat',
	choose: 'Alegeți',
	'up-to': 'Alegeți până la',
	unlimited: 'Alegeți câte doriți',
	'single-select': 'Alege unul',
	number: 'Introduceți un număr',
	'number-between-min-max': 'Introduceți un număr între ${min} și ${max}',
	'number-min': 'Introduceți un număr ${min} sau mai mare',
	'number-max': 'Introduceți un număr ${max} sau mai mic',
	'open-ended-pledge':
		'Acest sondaj conține întrebări la care ți se va cere să răspunzi cu propriile cuvinte. Răspunsurile tale vor fi verificate automat pentru a ne asigura că îndeplinesc cerințele noastre de calitate. Ești de acord să continui? ',
	'open-ended-pledge-accept': 'Da, sunt de acord',
	'open-ended-pledge-decline': 'Nu, nu sunt de acord',
	'rh-1-label': 'Care dintre următoarele nu este un sport?',
	'rh-1-o-1': 'Baschet',
	'rh-1-o-2': 'Tenis',
	'rh-1-o-3': 'Biscuit',
	'rh-1-o-4': 'Hochei',
	'rh-2-label': 'Cât face 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Care dintre următoarele este un fruct?',
	'rh-3-o-1': 'Lapte',
	'rh-3-o-2': 'Ciocolată',
	'rh-3-o-3': 'Ou',
	'rh-3-o-4': 'Măr',
	'open-end-max-length': 'Ne pare rău, răspunsul introdus este prea lung',
	'heatmap-click-image': `Faceți clic pe imagine pentru a adăuga un comentariu`,
	'heatmap-click-image-no-comments': `Faceți clic oriunde pe imagine`,
	'heatmap-click-image-product-card': `Faceți clic pe card pentru a adăuga un comentariu`,
	'heatmap-click-image-product-card--no-comment': `Faceți clic oriunde pe card`,
	'heatmap-type-comment': `Tastați un comentariu`,
	cancel: `Anulare`,
	skip: 'Ocolire',
	ranked: 'Clasat',
	top: 'Primele',
	'tap-or-drag': 'Atingeți sau trageți și plasați pentru a clasifica',
	unranked: 'Neclasat',
	reset: 'Resetați',
	'no-more-options': 'Nu mai sunt opțiuni de clasare',
	'rank-list-full': 'Lista de clasare este plină, anulați clasarea una dintre opțiunile dvs',
};
