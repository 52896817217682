import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'src/utilities/bem-cn';
import Loader from 'src/components/elements/Loader';
import logo from 'public/images/favicon/favicon.png';
import laptopIcon from 'public/images/laptop.png';
import maskingHelper from 'src/utilities/maskingHelper';
import * as misc from 'src/utilities/misc';
import * as actions from '../../actions';
import './style.scss';

const className = 'link-routing-container';
const el = (name, mod) => cn(className, name, mod);

const postfixUrl = (url, hasParams) => `${url}${url.includes('?') ? (hasParams ? '&' : '') : hasParams ? '?' : ''}`;

const LinkRouting = () => {
	const data = useSelector(state => state);
	const token = data.main.RID;
	let audienceUuid = useSelector(state => state.main?.study?.audienceUuid);
	let previewUuid = useSelector(state => state.main?.study?.previewUuid);
	let studyId = useSelector(state => state.main?.study?.uuid);
	const { sections, currentSectionIndex, answers } = data.main;
	const currentSection = sections[currentSectionIndex];
	const dispatch = useDispatch();
	const { url, customUrlParams } = currentSection.linkRouting[0];
	let { ridParam, audienceUuidParam } = currentSection.linkRouting[0];
	let redirectUrl = postfixUrl(url, customUrlParams?.length);
	useEffect(() => {
		customUrlParams
			.filter(({ sectionId }) => {
				const previousSectionsIds = sections.filter(s => s.order < currentSection.order && s.id).map(s => s.id);
				return previousSectionsIds.includes(sectionId);
			})
			.forEach((param, index) => {
				let answerKey = '';
				if (param.attributeId) {
					answerKey = `${param.questionId}-${param.attributeId}`;
				} else {
					answerKey = `${param.questionId}`;
				}
				let surveyAnswers = answers[answerKey]?.value;

				let paramValue = '';
				sections.forEach(section => {
					if (param.sectionId !== section.id) return;

					if (section.type === 'monadic_split' && section.questions?.length && section.products?.length) {
						const answeredProducts = Object.values(answers).filter(
							answer => answer.productId && answer.sectionId === section.id,
						);
						const answeredProductIds = answeredProducts.map(answer => answer.productId);
						const uniqueAnsweredProductIds = [...new Set(answeredProductIds)];
						const answeredProductNames = uniqueAnsweredProductIds.map(id => {
							const answer = section?.products?.find(prod => prod.id === id);
							if (!answer || !answer.name) return '';

							return answer?.name;
						});
						const viewedProductsUrlParam = answeredProductNames.reduce(
							(urlParam, productName) => (!urlParam ? productName : `${urlParam},${productName}`),
							'',
						);
						paramValue = encodeURIComponent(viewedProductsUrlParam);
					}

					if ((section.type === 'custom' || section.type === 'questions') && section.questions?.length > 0) {
						section.questions.map(question => {
							if (question.id === param.questionId) {
								const hasMaskedOptions = question.options.filter(o => o.maskedOptions?.length);
								let maskingOptions = question.options;
								if (hasMaskedOptions.length) {
									maskingOptions = maskingHelper.getMaskedPossibleOptions(question.options);
								}

								maskingOptions.forEach(option => {
									if (typeof surveyAnswers !== 'object') surveyAnswers = [surveyAnswers];
									if (surveyAnswers.includes(`${option.id}`)) paramValue = `${paramValue}1`;
									else paramValue = `${paramValue}0`;
								});
							}
						});
					}
				});
				redirectUrl = `${redirectUrl}${index > 0 ? '&' : ''}${param.text}=${paramValue}`;
			});
		
		if ((previewUuid || misc.getUrlParameter('previewUuid')) && !audienceUuid) {
			audienceUuid = 'null';
		}

		if (!ridParam) ridParam = 'rid';
		if (!audienceUuidParam) audienceUuidParam = 'audienceUuid';

		redirectUrl = `${postfixUrl(redirectUrl, true)}${ridParam}=${token}&${audienceUuidParam}=${audienceUuid}`;

		dispatch(
			actions.saveUserData({
				token,
				audienceUuid,
				studyUuid: studyId,
				data,
				redirectUrl,
			}),
		);
	}, [currentSection]);

	return (
		<div className={el('main')}>
			<p>
				<b>We're sending you to another site to continue your survey.</b>
			</p>
			<p>Once you're done, you'll be redirected back here.</p>
			<div className="link-routing-diagram">
				<div className="image-background">
					<img src={logo} />
				</div>
				<div className="arrow-container">
					<hr />
					<i className="arrow right"></i>
				</div>
				<div className="image-background">
					<img src={laptopIcon} />
				</div>
				<div className="arrow-container">
					<hr />
					<i className="arrow right"></i>
				</div>
				<div className="image-background">
					<img src={logo} />
				</div>
			</div>

			<Loader centered />
		</div>
	);
};

export default LinkRouting;
