export default {
	'this-survey-does-not-exist': 'Disculpe, esta página no existe.',
	'survey-closed': 'Disculpe, esta encuesta ya está cerrada.',
	'survey-no-products': 'Esta encuesta no tiene productos.',
	'survey-no-products-summary':
		'Si usted es el dueño de esta encuesta, cree los productos en la consola para proceder.',
	'survey-completed': 'Usted ya ha completado esta encuesta.',
	'summary-placeholder':
		'Si usted cree que este es un error, por favor contacte a la persona que le envió este enlace.',
	'gone-wrong': 'Hmmm, algo no está bien.',
	'try-again': 'Intente de nuevo o regrese más tarde.',
	'thank-you': 'Gracias',
	'thank-you-summary': 'La encuesta está completa, y sus resultados enviados.',
	'thank-you-summary-redirect': 'Se le redirigirá en unos segundos.',
	'page-not-found': 'Algo no está bien.',
	'page-not-found-placeholder': 'Huy, enlace roto',
	'tap-your-favorite': 'Toque el check en su favorito',
	or: 'o',
	'get-started-with-questions': 'Unas cuantas preguntas para empezar',
	continue: 'Continuar',
	retry: 'Reintente',
	'tell-us-how-you-feel-about-this-product': 'Cuéntanos cómo te sientes acerca de este producto.',
	begin: 'Empezar',
	'how-would-you-describe-this-product-to-a-friend': '¿Cómo describirías este producto a un amigo?',
	'type-your-response-here': 'Escriba su respuesta aquí ...',
	'sum-of-total-characters': '${SUM} de ${TOTAL} caracteres',
	edit: 'Editar',
	remove: 'Eliminar',
	'how-do-you-feel-about-this-part': '¿Cómo te sientes acerca de esta parte?',
	'tap-on-an-area-and-choose-an-emoji': 'Toca un área y elige un emoji',
	'im-finished': 'Estoy terminada',
	maintenance: 'Maintenance',
	'maintenance-mode': 'Upsiide is currently undergoing maintenance. Please check back soon.',
	'please-specify': 'Por favor especifica',
	'monadic-split-statement':
		'En la siguiente pantalla, le mostraremos una idea. Revísela detenidamente, ya que tendremos preguntas sobre ella.',
	disapproval: 'Desaprobación',
	anger: 'Enfado',
	sad: 'Triste',
	joy: 'Alegría',
	love: 'Amor',
	trust: 'Confianza',
	hope: 'Esperanza',
	proud: 'Orgulloso(a)',
	amazed: 'Asombrado(a)',
	surprised: 'Sorprendido(a)',
	guilt: 'Culpa',
	curious: 'Curioso(a)',
	jealous: 'Celoso(a)',
	anticipation: 'Ilusión',
	optimistic: 'Optimista',
	anxious: 'Ansioso(a)',
	fear: 'Miedo',
	despair: 'Desesperación',
	disbelief: 'Incredulidad',
	cynicism: 'Cinismo',
	regret: 'Arrepentimiento',
	disgust: 'Asco',
	annoyed: 'Molesto(a)',
	aggressive: 'Agresivo(a)',
	shocked: 'Conmocionado(a)',
	choose: 'Elija {{amount}}',
	'up-to': 'Elija, como máximo, {{amount}}',
	unlimited: 'Elija todas las opciones que quiera',
	'single-select': 'Elija uno',
	number: 'Introduzca un número',
	'number-between-min-max': 'Introduzca un número entre {{min}} y {{max}}',
	'number-min': 'Introduzca el número {{min}} o un número superior',
	'number-max': 'Introduzca el número {{max}} o un número inferior',
	'open-ended-pledge':
		'Esta encuesta incluye preguntas en las que se te pedirá que respondas con tus propias palabras. Tus respuestas se verificarán automáticamente para garantizar que cumplan con nuestros requisitos de calidad. ¿Aceptas continuar? ',
	'open-ended-pledge-accept': 'Sí, acepto',
	'open-ended-pledge-decline': 'No, no acepto',
	'rh-1-label': '¿Cuál de las siguientes opciones no es un deporte?',
	'rh-1-o-1': 'Básquetbol',
	'rh-1-o-2': 'Tenis',
	'rh-1-o-3': 'Galleta',
	'rh-1-o-4': 'Hockey',
	'rh-2-label': '¿Cuánto es 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': '¿Cuál de las siguientes opciones es una fruta?',
	'rh-3-o-1': 'Leche',
	'rh-3-o-2': 'Chocolate',
	'rh-3-o-3': 'Huevo',
	'rh-3-o-4': 'Manzana',
	'open-end-max-length': 'Lo siento, la respuesta ingresada es demasiado larga',
	'heatmap-click-image': `Haga clic en la imagen para añadir un comentario`,
	'heatmap-click-image-no-comments': `Haga clic en cualquier parte de la imagen`,
	'heatmap-click-image-product-card': `Haga clic en la tarjeta para añadir un comentario`,
	'heatmap-click-image-product-card--no-comment': `Haga clic en la tarjeta para añadir un comentario`,
	'heatmap-type-comment': `Escriba un comentario`,
	cancel: `Cancelar`,
	skip: 'Saltar',
	ranked: 'Clasificado',
	top: 'Mejor',
	'tap-or-drag': 'Toca o arrastra y suelta para clasificar',
	unranked: 'Sin clasificar',
	reset: 'Reiniciar',
	'no-more-options': 'No más opciones para clasificar',
	'rank-list-full': 'La lista de clasificación está llena, elimina la clasificación de una de tus opciones.',
};
