import React from 'react';

export default () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
		<path
			d="M23.8 21.9192C23.9262 22.0443 23.9972 22.2147 23.9972 22.3925C23.9972 22.5702 23.9262 22.7406 23.8 22.8658L22.8667 23.7992C22.7415 23.9254 22.5711 23.9963 22.3933 23.9963C22.2156 23.9963 22.0452 23.9254 21.92 23.7992L16 17.8792L10.08 23.7992C9.95483 23.9254 9.78443 23.9963 9.60667 23.9963C9.42891 23.9963 9.25852 23.9254 9.13334 23.7992L8.20001 22.8658C8.0738 22.7406 8.00281 22.5702 8.00281 22.3925C8.00281 22.2147 8.0738 22.0443 8.20001 21.9192L14.12 15.9992L8.20001 10.0792C8.0738 9.95397 8.00281 9.78358 8.00281 9.60582C8.00281 9.42806 8.0738 9.25766 8.20001 9.13249L9.13334 8.19915C9.25852 8.07294 9.42891 8.00195 9.60667 8.00195C9.78443 8.00195 9.95483 8.07294 10.08 8.19915L16 14.1192L21.92 8.19915C22.0452 8.07294 22.2156 8.00195 22.3933 8.00195C22.5711 8.00195 22.7415 8.07294 22.8667 8.19915L23.8 9.13249C23.9262 9.25766 23.9972 9.42806 23.9972 9.60582C23.9972 9.78358 23.9262 9.95397 23.8 10.0792L17.88 15.9992L23.8 21.9192Z"
			fill="#666666"
		/>
	</svg>
);
