import React from 'react';

// Localization
import { withTranslation } from 'react-i18next';
import './styles.scss';

const QuestionHelperText = ({
	t,
	style,
	numericValueOnly,
	openEndNumericMin,
	openEndNumericMax,
	openEndLengthLimit,
	multipleSelect,
	selectLimitRange,
	selectLimit,
	errorState,
}) => {
	const selectLimitWithDefault = selectLimit || 1;
	return (
		<>
			{style === 'open-ended' && numericValueOnly && (
				<div className={`question-help${errorState ? ' error' : ''}`}>
					{!openEndNumericMin && !openEndNumericMax && <>{t('number')}</>}
					{openEndNumericMin && !openEndNumericMax && <>{t('number-min', { min: openEndNumericMin })}</>}
					{!openEndNumericMin && openEndNumericMax && <>{t('number-max', { max: openEndNumericMax })}</>}
					{openEndNumericMin && openEndNumericMax && (
						<>{t('number-between-min-max', { min: openEndNumericMin, max: openEndNumericMax })}</>
					)}
				</div>
			)}
			{style === 'open-ended' && openEndLengthLimit && (
				<div className={`question-help${errorState ? ' error' : ''}`}>
					<>{t('open-end-max-length')}</>
				</div>
			)}
			{(style === 'multi-select' || style === 'grid' || style === 'emoji') && multipleSelect && (
				<div className="question-help">
					{(!selectLimitRange || selectLimitRange === 'unlimited') && <>{t('unlimited')}</>}
					{selectLimitRange && selectLimitRange === 'range' && (
						<>{t('up-to', { amount: selectLimitWithDefault })}</>
					)}
					{selectLimitRange && selectLimitRange === 'exact' && (
						<>{t('choose', { amount: selectLimitWithDefault })}</>
					)}
				</div>
			)}
		</>
	);
};

export default withTranslation('main')(QuestionHelperText);
