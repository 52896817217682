import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import ExpandIcon from './icons/expand.js';
import { useGesture } from '@use-gesture/react';

import './styles.scss';

const baseClassName = 'image-zoom';
const el = name => cn(baseClassName, name);

const ImageZoom = ({ children, className = '', ...rest }) => {
	const [expanded, setExpanded] = useState(false);
	const expandedRef = useRef(false);
	const expandedImageRef = useRef(null);
	const [tap, setTap] = useState(false)
	// Used for drag events
	const [expandedPosition, setExpandedPosition] = useState([0, 0]);
	// Used for pinch events
	const [expandedScale, setExpandedScale] = useState(1);
	const [cancelTransition, setCancelTransition] = useState(true);
	const scaleUpperBound = 2;
	const dragConfigOptions = {
		swipe: {
			distance: 25 /** Default is 50 https://use-gesture.netlify.app/docs/options/#swipedistance */,
		},
	};

	useEffect(() => {
		window.addEventListener('keyup', handleKeyboardInput);
		return () => {
			window.removeEventListener('keyup', handleKeyboardInput);
		}
	}, []);

	const handleKeyboardInput = event => {
		if (!expandedRef.current) return;
		if (event.key === 'Escape' || event.key === "Esc") {
			setExpanded(false);
			expandedRef.current = false;
		}
	}

	const handleOpen = event => {
		event.stopPropagation();
		event.preventDefault();
		setExpanded(true);
		expandedRef.current = true;
	};

	const handleClose = event => {
		event.preventDefault();
		event.stopPropagation();
		if (expanded) {
			setExpandedPosition([0, 0])
			setExpandedScale(1);
			setExpanded(false);
			expandedRef.current = false;
		}
	};

	const cancelEvent = e => {
		if(e?.preventDefault) {
			e.preventDefault();
		}
		e.stopPropagation();
	}

	const handleDrag = ({ event, cancel, ...drag }) => {
		if (drag.pinching) return;
		const x = expandedScale <= 1 && drag.last ? 0 : drag.offset[0];
		const y = expandedScale <= 1 && drag.last ? 0 : drag.offset[1];
		setCancelTransition(!drag.last);
		setExpandedPosition([x, y]);
		if (expandedScale <= 1 && (drag.swipe[1] > 0 || drag.last && drag.offset[1] > 100)) {
			setExpandedPosition([0, 0]);
			handleClose(event);
		}
	}

	const handlePinch = ({ event, ...pinch }) => {
		const [scale, _] = pinch.offset;

		if (scale < scaleUpperBound && pinch.last && scale < ((1 + scaleUpperBound) / 2)) {
			setCancelTransition(false);
			setExpandedPosition([0, 0]);
		}
		setExpandedScale(scale);
	}

	const handleGestures = useGesture(
		{
			onDrag: event => {
				handleDrag(event);
			},
			onPinch: event => {
				handlePinch(event);
			}
		},
		{
			drag: {
				...dragConfigOptions,
				from: () => {
					const { x, y } = expandedImageRef.current.getBoundingClientRect();
					return [expandedPosition[0], expandedPosition[1]]
				},
				bounds: () => {
					return { left: -(window.innerWidth / 2), right: (window.innerWidth / 2), top: -(window.innerHeight / 2), bottom: (window.innerHeight / 2) }
				},
				rubberband: true,
			},
			pinch: { scaleBounds: { min: 1, max: scaleUpperBound }, rubberband: true },
		},
	);
	const resetExpandedPosition = () => {
		if (expanded) {
			setExpandedScale(expandedScale < 2 ? 2 : 1);
			setExpandedPosition([0, 0])
		}
	}

	return (
		<div {...rest} className={`${baseClassName} ${className || ''} ${expanded ? 'expanded' : 'collapsed'}`}>
			<span className={el('preview-container')} tabIndex="-1">
				<a
					className={el('preview-trigger')}
					onClick={handleOpen}
					onTouchStart={e => { e.preventDefault(); cancelEvent(e); setTap(true); }}
					onTouchEnd={e => { cancelEvent(e); e.preventDefault(); if (tap) handleOpen(e) }}
					onTouchMove={e => { cancelEvent(e); e.preventDefault(); setTap(false) }}
					aria-hidden
				>
					<ExpandIcon />
				</a>
				{children}
			</span>
			<div
				{...handleGestures()}
				onClick={cancelEvent}
				onDoubleClick={resetExpandedPosition}
				onTouchStart={cancelEvent}
				ref={expandedImageRef}
				style={{ transform: `translate3d(${expandedPosition[0]}px, ${expandedPosition[1]}px, 0) scale(${expandedScale})`, transition: cancelTransition ? 'none' : null }}
				className={`${el('asset-window')} ${expanded ? 'expanded' : ''}`}
			>
				<div 
					className={el('overlay')} 
					onTouchStart={e => { cancelEvent(e); setTap(true); }}
					onTouchMove={e => { cancelEvent(e); setTap(false) }}
					onTouchEnd={e => { cancelEvent(e); if (tap) handleClose(e) }}
					onMouseDown={e => { e.preventDefault(); cancelEvent(e); setTap(true); }}
					onMouseMove={e => { cancelEvent(e); e.preventDefault(); setTap(false) }}
					onMouseUp={e => { cancelEvent(e); e.preventDefault(); if (tap) handleClose(e) }}
					onClick={e => { 
						e.preventDefault();
						e.stopPropagation(); 
						if (tap) handleClose(e)
						}}
				></div>
				<div className={el('asset-window-inner')}>
					<a
						className={el('close')}
						onClick={handleClose}
						onTouchStart={cancelEvent}
						aria-hidden
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							xlinkHref="http://www.w3.org/1999/xlink"
							width="39"
							height="37"
							viewBox="0 0 39 37"
							className="x-icon"
						>
							<g id="a">
								<g transform="translate(-1456 -1104)">
									<g transform="translate(1464.5 1112.5)">
										<line
											x2="18.886"
											y2="18.346"
											transform="translate(0.54 1.079)"
											fill="none"
											stroke={`#000000`}
											strokeWidth={1}
										/>
										<line
											x1="20.074"
											y2="20.065"
											transform="translate(0)"
											fill="none"
											stroke={`#000000`}
											strokeWidth={1}
										/>
									</g>
								</g>
							</g>
						</svg>
					</a>
					{children}
				</div>
			</div>
		</div >
	);
};

ImageZoom.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
};

export default ImageZoom;
