import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { shuffle } from 'src/utilities/lodash';
import useDebounceCallback from 'src/utilities/debounceCallback';
import cn from 'src/utilities/bem-cn';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';
import { motion } from 'framer-motion';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as questions from '../../../../utilities/red-herring/questions';
import * as misc from 'src/utilities/misc';
import RedHerringQuestion from '../../components/RedHerringQuestion';
import SingleSelect from '../../components/Question/SingleSelect';
import Button from 'src/components/new/Button';

const className = 'questions-container';
const el = (name, mod) => cn(className, name, mod);
const containerHeightBuffer = 10;
const buttonHeight = 68;

const RedHerring = ({
	t,
	study = {},
	setNextSection,
	setDisqualifiedWithReason,
}) => {
	const animation = {
		start: {
			y: 50,
			opacity: 0
		},
		completed: {
			y: 0,
			opacity: 1,
			staggerChildren: 100
		},
	}

	const questionAnimation = {
		start: {
			y: 50,
			opacity: 0
		},
		completed: {
			y: 0,
			opacity: 1
		},
	}

	const generateRandom = (min = 0, max = 3) => {
		let difference = max - min;
		const rand = Math.floor(Math.random() * difference);
		return rand + min;
	}

	const [pose, setPose] = useState('start');
	const [value, setValue] = useState(null);
	const [question, setQuestion] = useState(0);
	const [options, setOptions] = useState([]);
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const [contentOverflow, setContentOverflow] = useState(false);
	const [containerHeight, setContainerHeight] = useState(window.innerHeight - containerHeightBuffer);
	const [justifyContent, setJustifyContent] = useState(false);
	const [scrolled, setScrolled] = useState(false);
	const [updateDebounceTimer, debounceCallback] = useDebounceCallback();

	const elementRef = useRef(true);

	useEffect(() => {
		updateDebounceTimer();
		setTimeout(() => {
			const isContentOverflowing = elementRef && elementRef.current && (elementRef.current.clientHeight - buttonHeight/2) > window.innerHeight;
			isContentOverflowing || elementRef && elementRef.current && (elementRef.current.scrollHeight - buttonHeight/2) > window.innerHeight
			setContentOverflow(isContentOverflowing);
		}, 100);

		window.addEventListener('resize', updateContainerHeight);
		window.addEventListener('orientationchange', updateContainerHeight);
		return () => {
			window.removeEventListener('resize', updateContainerHeight);
			window.removeEventListener('orientationchange', updateContainerHeight);
		}
	}, []);


	const updateContainerHeight = () => {
		setContainerHeight(window.innerHeight - containerHeightBuffer);
		setTimeout(() => {
			const isScrolled = elementRef && elementRef.current && (elementRef.current.clientHeight - buttonHeight/2) > window.innerHeight;
			isScrolled || elementRef && elementRef.current && (elementRef.current.scrollHeight - buttonHeight/2) > window.innerHeight;
			setScrolled(isScrolled);
			setJustifyContent(isScrolled);
		}, 100);
	}

	const { audienceUuid } = useSelector(state => state);

	useEffect(() => {
		const questionIndex = generateRandom();
		const randomQuestion = questions[questionIndex];
		setQuestion(randomQuestion);
		const orderedOptions = shuffle(
			randomQuestion.validAnswers
				.concat(randomQuestion.invalidAnswers)
				.map(option => ({
					id: option,
					label: t(option),
				}))
		);
		setOptions(orderedOptions);
		setPose('completed');
	}, []);

  	const handleResults = () => {
		!question.validAnswers.includes(value)
		? setDisqualifiedWithReason(`red-herring ${value}`, study.audienceUuid)
		: setNextSection();
	}

	const handleChange = (value) => {
		setValue(value);
		const hasValidUserInputAnswer = misc.checkForValidInputs(question, value, question.style, false, false);
		debounceCallback(() => setButtonEnabled(hasValidUserInputAnswer));
	}

	if (!question) {
		return <div />;
	}

	return (
		<motion.div
			animate={pose}
			variants={animation}
			initial="start"
			className={className}
			ref={elementRef}
			style={{height: `${containerHeight}px`,
				maxHeight: `${containerHeight}px`,
				minHeight: `${containerHeight}px`,
				justifyContent: `${justifyContent ? 'flex-start' : 'center'}`}}
				>
			<div className={el('questions')}>
				<motion.div animate={pose} variants={questionAnimation} initial="start">
					<RedHerringQuestion
						questionLabel={t(question.label)}
					>
						<SingleSelect
							question={question}
							value={value}
							onChange={handleChange}
							options={options}
						/>
					</RedHerringQuestion>
				</motion.div>
				<div className={`${el('inline-button-container')}${!contentOverflow ? ' no-overflow' : ''}`}>
					<div className={el('inline-button')}>
						<Button
							disabled={!buttonEnabled}
							label={t('continue')}
							onClick={handleResults}
							tabindex={!buttonEnabled ? "-1" : 0}
							dataTestId="continue-button"
						/>
					</div>
				</div>
			</div>
			<GlobalScrollIndicator show={contentOverflow} />
		</motion.div>
	);
};

const mapStateToProps = state => ({
	study: selectors.getStudy(state),
});

const mapDispatchToProps = dispatch => ({
	setDisqualifiedWithReason: (reason, audienceUuid) => dispatch(actions.setDisqualified([], audienceUuid, reason)),
	setNextSection: () => dispatch(actions.setNextSection()),
});

RedHerring.propTypes = {
	t: PropTypes.func,
	study: PropTypes.any,
	setDisqualifiedWithReason: PropTypes.func,
	setNextSection: PropTypes.func,
};

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(RedHerring));
