import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './styles/index.scss';
import { initDataDogRum } from './utilities/dataDogRum';

import AppBoundary from './utilities/sentry';
import App from './App';
import store from './store';

const container = document.getElementById('index');
const root = createRoot(container);

if (process.env.logToSentry) {
	console.log('version', process.env.version);
	Sentry.init({
		dsn: 'https://c27b23ca56114d57b7a49c258118f751@o212722.ingest.sentry.io/1373212',
		tracesSampleRate: 0.25,
		release: process.env.version, // version,
		environment: process.env.production ? 'production' : process.env.staging ? 'staging' : 'test',
		ignoreErrors: ['getMonitorId'],
	});
}

if (process.env.logDataDogRum) {
	initDataDogRum();
}

const content = (
	<AppBoundary>
		<Provider store={store}>
			<I18nextProvider i18n={i18n}>
				<App />
			</I18nextProvider>
		</Provider>
	</AppBoundary>
);

root.render(content);

document.ontouchmove = function (e) {
	// e.preventDefault();
};
