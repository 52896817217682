import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import Button from 'src/components/new/Button';
import * as misc from 'src/utilities/misc';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import useDebounceCallback from 'src/utilities/debounceCallback';
import _ from 'lodash';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';
import RankedResetButton from '../../../../components/shared/RankedResetButton';
import api from '../../../../utilities/api';
import Question from '../../components/Question';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import './styles.scss';

const containerHeightBuffer = 10;
const buttonHeight = 68;
const className = 'questions-container';
const el = (name, mod) => cn(className, name, mod);

const CustomQuestion = ({
	t,
	study = {},
	RID,
	setNextSection,
	setSectionAnswers,
	setQualifiers,
	currentSection,
	sections,
	jumpToSection,
	jumpToQuestion,
	questionIndex,
	setStep,
	setQuestionIndex,
	swipeResults,
	allAnswers,
	setDisqualified,
	setDisqualifiedWithReason,
}) => {
	const { questions } = currentSection;
	const [containerHeight, setContainerHeight] = useState(window.innerHeight - containerHeightBuffer);
	const [justifyContent, setJustifyContent] = useState(false);
	const responseLoading = useRef(false);
	const handleResultsLoading = useRef(false);
	// Filter through currentSection.questions and remove any questions without a label

	const { audienceUuid, previewUuid, useServerlessSurvey } = useSelector((state) => state?.main?.study);
	const questionsWithLabels = questions.filter((question) => {
		if (previewUuid && question.audienceQuestionId) return;
		const questionLabel = question.label.replace(/<\/?p[^>]*>/g, '');
		if (!questionLabel || questionLabel === 'Type question here...' || questionLabel === 'Type option here...') {
			return false;
		}
		if (['single-select', 'multi-select'].includes(question.style)) {
			if (!question.options || question.options.length < 1) {
				return false;
			}
		}

		// remove Grid type questions with no attributes
		if (question.style === 'grid') {
			const hasAttributeLabels =
				question.attributes.length &&
				question.attributes.filter(
					(a) => a?.translations?.findIndex((translation) => translation.label.length !== 0) !== -1,
				).length;
			const hasOptionLabels = question.options.length && question.options.filter((o) => o.label.length).length;
			if (!hasAttributeLabels || !hasOptionLabels) return false;
		}
		return question;
	});

	questionsWithLabels.forEach((question) => {
		if (!question?.options) return;
		question.options = question.options.sort((a, b) => a.order - b.order);
	});

	// If the list of questions with labels is empty proceed to the next section
	if (questionsWithLabels.length === 0) {
		setNextSection();
		return false;
	}

	const [answers, setAnswers] = useState({});

	// const [questionIndex, setQuestionIndex] = useState(0);
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const [contentOverflow, setContentOverflow] = useState(false);
	const [resetRankedOptions, setResetRankedOptions] = useState(false);
	const [updateDebounceTimer, debounceCallback] = useDebounceCallback();
	const isRankedQuestion = questionsWithLabels[questionIndex]?.style === 'ranked';
	const scrollContainerClass = 'scroll-container';
	const isMobile = useIsMobile();

	useEffect(() => {
		updateContainerHeight();
		window.addEventListener('resize', updateContainerHeight);
		window.addEventListener('orientationchange', updateContainerHeight);
		return () => {
			window.removeEventListener('resize', updateContainerHeight);
			window.removeEventListener('orientationchange', updateContainerHeight);
		};
	}, []);

	useEffect(() => {
		updateContainerHeight();
		updateDebounceTimer();
	}, [questionIndex]);

	const preQuestion = useRef(true);
	const elementRef = useRef(true);

	useEffect(() => {
		const question = questionsWithLabels[questionIndex];
		if (!question) return;

		const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
		const settings = question?.settings;
		if (question?.style === 'ranked') {
			const topNSet = settings?.find((s) => s?.label === 'top-n')?.value === 'true';
			const topN = topNSet
				? parseInt(settings?.find((s) => s?.label === 'top-n-limit')?.value) || 1
				: Math.min(answers[question.id]?.availableOptions?.length || question?.options?.length || 0, 10);
			const answerLength = answers[question.id]?.value?.length || 0;
			const isEnabled = isOptional ? answerLength === 0 || answerLength >= topN : answerLength >= topN;
			setButtonEnabled(isEnabled);
			return;
		}

		if (!isOptional) return;

		if (question?.style !== 'ranked') {
			setButtonEnabled(true);
		}
	}, [questionsWithLabels, questionIndex, answers]);

	const updateContainerHeight = () => {
		setContainerHeight(window.innerHeight - containerHeightBuffer);
		setTimeout(() => {
			const isScrolled =
				elementRef?.current?.scrollHeight && elementRef.current.scrollHeight > window.innerHeight;

			setJustifyContent(isScrolled);
			setContentOverflow(isScrolled);
		}, 100);
	};

	const isGridQuestion = questionsWithLabels[questionIndex]?.style === 'grid';

	const checkForLogicConditionMatch = (triggerOption, answerSet) => {
		if (answerSet?.type === 'ranked') {
			const answerRank = answerSet.value.findIndex((id) => id === triggerOption?.id) + 1;
			const isOptionRanked = answerSet?.value?.includes(triggerOption?.id);
			if (triggerOption?.position === 'top') {
				return isOptionRanked && answerRank <= triggerOption.rank;
			}
			if (triggerOption?.position === 'bottom') {
				return isOptionRanked && answerSet.value.length - answerRank < triggerOption.rank;
			}
		}
		if (triggerOption.attributeId)
			return (
				triggerOption?.id &&
				answerSet?.value?.includes(triggerOption.id.toString()) &&
				answerSet?.attributeId === triggerOption?.attributeId
			);
		return (
			triggerOption.id && answerSet && answerSet.value && answerSet.value.includes(triggerOption.id.toString())
		);
	};

	const getPreLogicAnswerSet = (triggerOption, triggerItemId) => {
		let answerSet = null;
		console.log('triggerOption: ', triggerOption);
		console.log('triggerItemId: ', triggerItemId);
		if (triggerOption.attributeId && answers[`${triggerItemId}-${triggerOption.attributeId}`]) {
			// Current section with attribute ID trigger
			answerSet = answers[`${triggerItemId}-${triggerOption.attributeId}`];
		} else if (triggerOption.attributeId && allAnswers[`${triggerItemId}-${triggerOption.attributeId}`]) {
			// previous section answers with attribute id trigger
			answerSet = allAnswers[`${triggerItemId}-${triggerOption.attributeId}`];
		} else if (answers[triggerItemId]) {
			// Check current section answers first
			answerSet = answers[triggerItemId];
		} else {
			// Previous section questions will be here.
			answerSet = allAnswers[triggerItemId];
		}

		return answerSet;
	};

	const doPreLogic = () => {
		const question = questionsWithLabels[questionIndex];
		console.log('pre logic question (custom): ', question);
		const { id: questionId, logic } = question;
		// Get all items related to this question - aka post-question logic
		const preLogic = logic.filter(
			(logicItem) =>
				logicItem.triggerItemId !== questionId &&
				logicItem.actionType === 'skip' &&
				logicItem?.triggerOptions?.length > 0,
		);
		if (preLogic.length) {
			// using classic for-loop here so we can short-circuit as soon as a condition is met.
			for (let i = 0; i < preLogic.length; i++) {
				const logicItem = preLogic[i];
				const { triggerOptions, triggerType, itemType, operand, operator, triggerItemId } = logicItem;
				let logicConditionsMatched = false;
				const cleanedTriggerOptions = triggerOptions.filter((triggerOption) => {
					if (triggerOption && Object.keys(triggerOption).length) {
						return triggerOption;
					}
					return false;
				});
				if (triggerType === 'questions' || itemType === 'question') {
					console.log('triggerItemId: ', triggerItemId);
					console.log('Do pre-logic allAnswers: ', allAnswers);
					console.log('Do pre-logic answers: ', answers);
					// console.log("Do pre-logic Answer set: ", answerSet);
					if (operand === 'and') {
						if (operator === 'is') {
							logicConditionsMatched = cleanedTriggerOptions.every((triggerOption) =>
								checkForLogicConditionMatch(
									triggerOption,
									getPreLogicAnswerSet(triggerOption, triggerItemId),
								),
							);
						} else if (operator === 'is_not') {
							logicConditionsMatched = !cleanedTriggerOptions.every((triggerOption) =>
								checkForLogicConditionMatch(
									triggerOption,
									getPreLogicAnswerSet(triggerOption, triggerItemId),
								),
							);
						}
					} else if (operand === 'or') {
						if (operator === 'is') {
							logicConditionsMatched = cleanedTriggerOptions.some((triggerOption) =>
								checkForLogicConditionMatch(
									triggerOption,
									getPreLogicAnswerSet(triggerOption, triggerItemId),
								),
							);
						} else if (operator === 'is_not') {
							logicConditionsMatched = !cleanedTriggerOptions.some((triggerOption) =>
								checkForLogicConditionMatch(
									triggerOption,
									getPreLogicAnswerSet(triggerOption, triggerItemId),
								),
							);
						}
					}
				} else if (triggerType === 'swipe') {
					const { interests } = swipeResults;
					const productInterest = interests.find((interest) => interest.localProductId === triggerItemId);
					if (productInterest) {
						// TODO - should only support `is`
						if (operator === 'is') {
							logicConditionsMatched = cleanedTriggerOptions.some(
								(triggerOption) => triggerOption && productInterest.interest == triggerOption.value,
							);
						} else if (operator === 'is_not') {
							logicConditionsMatched = cleanedTriggerOptions.every(
								(triggerOption) => triggerOption && productInterest.interest != triggerOption.value,
							);
						}
					}
				}
				// If this logic is matching, send it
				if (logicConditionsMatched) {
					return true;
				}
			}
		}
		return false;
	};

	useEffect(() => {
		// Do pre-logic before we render a question
		if (preQuestion.current) {
			preQuestion.current = false;
			const skipQuestion = doPreLogic();
			if (skipQuestion) {
				preQuestion.current = true;
				const endOfQuestions = questionIndex + 1 === questionsWithLabels.length;
				if (endOfQuestions) {
					// If it is the end of the questions then set the currentSectionIndex to the survey index
					// const surveyIndex = sections.findIndex(section => section === 'survey');
					setQuestionIndex(0);
					setSectionAnswers(answers);
					setNextSection();
				} else {
					setQuestionIndex(questionIndex + 1);
				}
			}
		}
	}, [
		answers,
		doPreLogic,
		preQuestion,
		questionIndex,
		questionsWithLabels,
		setNextSection,
		setQuestionIndex,
		setSectionAnswers,
	]);

	const getPostLogicAnswerSet = (triggerOption, questionId) => {
		let answerSet = null;

		if (triggerOption.attributeId && answers[`${questionId}-${triggerOption.attributeId}`])
			// Current section with attribute ID trigger
			answerSet = answers[`${questionId}-${triggerOption.attributeId}`];
		// Check current section answers first
		else answerSet = answers[questionId];

		return answerSet;
	};

	const doPostLogic = () => {
		const question = questionsWithLabels[questionIndex];
		console.log('post logic question: ', question);

		const { id: questionId, logic } = question;
		// Get all items related to this question - aka post-question logic
		const postLogic = logic.filter(
			(logicItem) => logicItem.triggerItemId === questionId && logicItem?.triggerOptions?.length,
		);
		console.log('postLogic: ', postLogic);
		if (postLogic.length) {
			// using classic for-loop here so we can short-circuit as soon as a condition is met.
			for (let i = 0; i < postLogic.length; i++) {
				const logicItem = postLogic[i];
				const { triggerOptions, operand, operator, actionType, actionSectionId, actionItemId } = logicItem;
				let logicConditionsMatched = false;
				const cleanedTriggerOptions = triggerOptions.filter((triggerOption) => {
					if (triggerOption && Object.keys(triggerOption).length) {
						return triggerOption;
					}
					return false;
				});

				console.log('triggerOptions: ', triggerOptions);
				console.log('answers: ', answers);

				// const answerSet = answers[questionId];
				console.log('questionId: ', questionId);
				console.log('triggerOptions.attributeId: ', triggerOptions[0].attributeId);
				// console.log("Do post-logic Answer set: ", answerSet)

				if (operand === 'and') {
					if (operator === 'is') {
						logicConditionsMatched = cleanedTriggerOptions.every((triggerOption) =>
							checkForLogicConditionMatch(
								triggerOption,
								getPostLogicAnswerSet(triggerOption, questionId),
							),
						);
					} else if (operator === 'is_not') {
						logicConditionsMatched = !cleanedTriggerOptions.every((triggerOption) =>
							checkForLogicConditionMatch(
								triggerOption,
								getPostLogicAnswerSet(triggerOption, questionId),
							),
						);
					}
				} else if (operand === 'or') {
					if (operator === 'is') {
						logicConditionsMatched = cleanedTriggerOptions.some((triggerOption) =>
							checkForLogicConditionMatch(
								triggerOption,
								getPostLogicAnswerSet(triggerOption, questionId),
							),
						);
					} else if (operator === 'is_not') {
						logicConditionsMatched = !cleanedTriggerOptions.some((triggerOption) =>
							checkForLogicConditionMatch(
								triggerOption,
								getPostLogicAnswerSet(triggerOption, questionId),
							),
						);
					}
				}
				// TODO - if actionType is terminate - kill em
				// If this logic is matching, send it
				if (logicConditionsMatched) {
					if (actionType === 'terminate') {
						return [false, false, true];
					}
					return evaluatePostLogicReturn([actionSectionId, actionItemId, false]);
				}
			}
		}
		return [false, false, false];
	};

	const evaluatePostLogicReturn = (returnArray) => {
		const [sectionId, questionId, terminate] = returnArray;
		const currentQuestion = questionsWithLabels[questionIndex];
		const foundSection = sections?.find(
			(section) => section?.id && section?.id === sectionId && section?.order >= currentSection?.order,
		);
		const foundQuestion = questionsWithLabels?.find(
			(question) =>
				question?.id && question?.id === questionId && question.sortOrder >= currentQuestion.sortOrder,
		);

		if (
			(foundSection && questionId && !foundQuestion && !terminate) ||
			(!foundSection && !foundQuestion && !terminate)
		) {
			return [false, false, false];
		}
		return returnArray;
	};

	const handleResults = async () => {
		// Reset the button active state to disabled after answering a question
		if (handleResultsLoading.current) return;
		handleResultsLoading.current = true;

		setButtonEnabled(false);
		const [sectionId, questionId, terminate] = doPostLogic();
		if (sectionId || questionId || terminate) {
			if (terminate) {
				const testMode = !!study.previewUuid;
				if (testMode) {
					setStep('terminated');
				} else {
					setDisqualified(answers, audienceUuid);
				}
			} else if (questionId) {
				preQuestion.current = true;
				jumpToQuestion(questionId, sectionId);
			} else if (sectionId !== currentSection.id) {
				setSectionAnswers(answers);
				setQuestionIndex(0);
				jumpToSection(sectionId);
			}
		} else {
			// Check if all the questions have been asked
			const endOfQuestions = questionIndex + 1 === questionsWithLabels.length;
			if (endOfQuestions) {
				// If it is the end of the questions then set the currentSectionIndex to the survey index
				// const surveyIndex = sections.findIndex(section => section === 'survey');
				const quotaAnswerObject = [];

				const rankedQuestionAnswerRankByIds = {};

				Object?.keys(answers || {}).forEach((questionId) => {
					if (answers[questionId]?.type === 'ranked') {
						rankedQuestionAnswerRankByIds[questionId] = 0;
					}
				});

				Object.keys(answers).forEach((questionId) => {
					const selectedAnswers = Array.isArray(answers[questionId]?.value)
						? answers[questionId]?.value
						: [answers[questionId]?.value];
					const splitQuestionId = questionId.split('-')[0];
					for (const answer of selectedAnswers) {
						if (answers[questionId]?.type === 'ranked') {
							rankedQuestionAnswerRankByIds[questionId] += 1;
						}
						quotaAnswerObject.push({
							questionId: Number(splitQuestionId),
							type: answers[questionId]?.type,
							...(answers[questionId]?.type === 'ranked'
								? { ranking: rankedQuestionAnswerRankByIds[questionId] }
								: {}),
							value: Number(answer),
							attributeId: answers[questionId].attributeId,
						});
					}
				});

				const urlParams = misc.getAllUrlParams();
				const qualifiers =
					(urlParams && urlParams.q)?.map((qualifier) => ({
						questionId: Number(qualifier.name),
						value: qualifier.value,
					})) || [];

				const testMode = !!study.previewUuid;
				if (testMode) {
					setQuestionIndex(0);
					setSectionAnswers(answers);
					return setNextSection();
				}

				if (study?.hasQuotas && study?.enforceSampleLimit) {
					responseLoading.current = true;
					const response = await api.validateQuestionsQuota(
						study,
						audienceUuid,
						quotaAnswerObject,
						RID,
						qualifiers,
					);
					responseLoading.current = false;

					if (response?.data?.isOverQuota == true) {
						setDisqualifiedWithReason([], 'quota-reached', audienceUuid, true);
						setStep('terminated');
					} else {
						setQuestionIndex(0);
						if (!useServerlessSurvey) {
							setSectionAnswers([]);
							setQualifiers([]);
						} else {
							setSectionAnswers(answers);
						}
						setNextSection();
					}
				} else {
					setQuestionIndex(0);
					setSectionAnswers(answers);
					setNextSection();
				}
			} else {
				// Otherwise set the questionIndex and and stay on the same currentSectionIndex (questions)
				preQuestion.current = true;
				setQuestionIndex(questionIndex + 1);
			}
		}

		handleResultsLoading.current = false;
	};

	const handleReset = (question) => {
		setAnswers({
			...answers,
			[question.id]: {
				...answers[question.id],
				value: [],
			},
		});
		const scrollContainer = document.querySelector(`[class*='${scrollContainerClass}']`);
		scrollContainer.scrollTo(0, 0, { behavior: 'smooth' });
		setResetRankedOptions(true);
		setTimeout(() => {
			setResetRankedOptions(false);
		}, 50);
	};

	const handleChange =
		(question) =>
		(value, type, otherValue = '', otherOptionId = 0, noneOfTheAboveSelected = false) => {
			// if waiting for response... exit
			if (responseLoading.current) {
				return;
			}

			// Store the answers in a local state variable
			setAnswers({
				...answers,
				[question.id]: {
					value,
					type,
					otherValue,
					otherOptionId,
					sectionId: currentSection.id,
				},
			});

			const hasValidUserInputAnswer = misc.checkForValidInputs(
				question,
				value,
				question.style,
				noneOfTheAboveSelected,
				otherValue,
			);

			debounceCallback(() => setButtonEnabled(hasValidUserInputAnswer));
		};

	return (
		<div
			className={className}
			ref={elementRef}
			style={{
				height: `${containerHeight}px`,
				maxHeight: `${containerHeight}px`,
				minHeight: `${containerHeight}px`,
				justifyContent: `${justifyContent ? 'flex-start' : 'center'}`,
			}}
		>
			{/* <h3 className={el('heading')}>{t('get-started-with-questions')}</h3> */}
			<div className={el('questions')}>
				<div key={questionsWithLabels[questionIndex].id}>
					<Question
						language={study.language}
						question={questionsWithLabels[questionIndex]}
						value={
							_.size(answers) > 0 && answers[questionsWithLabels[questionIndex].id]
								? answers[questionsWithLabels[questionIndex].id].value
								: 0
						}
						onChange={handleChange(questionsWithLabels[questionIndex])}
						answers={answers}
						setAnswers={setAnswers}
						currentSection={currentSection}
						handleResults={handleResults}
						onImageLoad={updateContainerHeight}
						resetRankedOptions={resetRankedOptions}
					/>
				</div>
				{!isGridQuestion && (
					<div className={el('inline-button')}>
						<Button
							disabled={!buttonEnabled}
							label={t('continue')}
							onClick={handleResults}
							tabindex={!buttonEnabled ? '-1' : 0}
							dataTestId="continue-button"
						/>
					</div>
				)}
				{isRankedQuestion && isMobile && (
					<RankedResetButton
						handleReset={handleReset}
						disabled={answers[questionsWithLabels[questionIndex]?.id]?.value?.length < 1}
						question={questionsWithLabels[questionIndex]}
					/>
				)}
				<GlobalScrollIndicator show={contentOverflow} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	study: selectors.getStudy(state),
	sections: selectors.getSections(state),
	currentSection: selectors.getCurrentSection(state),
	questionIndex: selectors.getQuestionIndex(state),
	allAnswers: selectors.getAnswers(state),
	swipeResults: selectors.getResults(state),
	RID: selectors.getResponseRID(state),
});

const mapDispatchToProps = (dispatch) => ({
	setSectionAnswers: (answers) => dispatch(actions.setAnswers(answers)),
	setQualifiers: (qualifiers) => dispatch(actions.setQualifiers(qualifiers)),
	setStep: (step) => dispatch(actions.setStep(step)),
	setNextSection: () => dispatch(actions.setNextSection()),
	setQuestionIndex: (index) => dispatch(actions.setQuestionIndex(index)),
	jumpToQuestion: (questionId, sectionId) => dispatch(actions.jumpToQuestion(questionId, sectionId)),
	jumpToSection: (sectionId) => dispatch(actions.jumpToSection(sectionId)),
	setDisqualified: (answers, audienceUuid) => dispatch(actions.setDisqualified(answers, audienceUuid)),
	setDisqualifiedWithReason: (answers, reason, audienceUuid, setDisqualifiedWithReason) =>
		dispatch(actions.setDisqualified(answers, audienceUuid, reason, setDisqualifiedWithReason)),
});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(CustomQuestion));
