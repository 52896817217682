import axios from './axios';

const API_PATH = `${process.env.researchDefenderBaseUrl}`;
const ACTIVITY_API_PATH = `${process.env.researchDefenderActivityUrl}`;
const PUBLISHABLE_KEY = `${process.env.researchDefenderPublishableKey}`;
const X_API_KEY = `${process.env.researchDefenderXAPIKey}`;

const timeout = 5000;

export const activity = (audienceUuid, rnid) =>
	axios.get(`${ACTIVITY_API_PATH}`, {
		params: {
			range: 24,
			session_id: rnid,
			survey_number: audienceUuid,
		},
		headers: {
			'x-api-key': X_API_KEY,
		},
		timeout,
		transitional: {
			clarifyTimeoutError: true,
		},
	});

export const getToken = (numberOfTokens = 1) =>
	axios.get(`${API_PATH}/get_token/${PUBLISHABLE_KEY}?tokens=${numberOfTokens}`, {
		timeout,
		transitional: {
			clarifyTimeoutError: true,
		},
	});
