// React & Redux
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Animation
import { motion } from 'framer-motion';

import * as actions from 'src/domains/main/actions';

// Containers
import Loader from 'src/components/elements/Loader';
import Start from '../Start';
import Welcome from '../Welcome';
import CustomQuestions from '../CustomQuestions';
import Questions from '../Questions';
import MonadicSplit from '../MonadicSplit';
import Survey from '../Survey';
import TextAI from '../TextAI';
import End from '../End';
import Terminated from '../Terminated';
import OpenEndedPledge from '../OpenEndedPledge';
import RedHerring from '../RedHerring';
import LinkRouting from '../LinkRouting';
import * as selectors from '../../selectors';

// Components

import './styles.scss';

const loaderWrap = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
	},
};

// Main Container
const Main = ({ ...props }) => {
	const { step, currentSectionIndex, sections, validateRespondentLoading } = useSelector((state) =>
		selectors.getMainState(state),
	);
	const currentSection = sections[currentSectionIndex];
	const [pose, setPose] = useState('start');
	const dispatch = useDispatch();

	const loading = (
		<div className="main__loader">
			<motion.div animate={pose} variants={loaderWrap} initial="start">
				<Loader />
			</motion.div>
		</div>
	);
	useEffect(() => {
		setPose('completed');
	}, []);
	if (step === 'start') {
		return <Start {...props} />;
	}
	if (step === 'loading') loading;
	if (step === 'sections' && validateRespondentLoading && currentSection.type !== 'red-herring') {
		return loading;
	}

	if (step === 'sections') {
		const text = currentSection?.statements?.[0]?.text;
		switch (currentSection.type) {
			case 'red-herring':
				return <RedHerring {...props} />;
			case 'open-ended-pledge':
				return <OpenEndedPledge {...props} />;
			case 'monadic_split':
				return <MonadicSplit {...props} />;
			case 'custom':
				return <CustomQuestions {...props} />;
			case 'statement':
				if (text && text !== '<p><br></p>') {
					return <Welcome {...props} />;
				}
				return dispatch(actions.setNextSection());
			case 'questions':
				return <Questions {...props} />;
			case 'swipe':
				return <Survey {...props} />;
			case 'text-ai':
				return <TextAI {...props} />;
			case 'link_routing':
				if (
					currentSection.linkRouting &&
					currentSection.linkRouting[0]?.loi &&
					currentSection.linkRouting[0]?.url
				) {
					return <LinkRouting {...props} />;
				}
				dispatch(actions.setNextSection());

			default:
				return null;
		}
	} else if (step === 'terminated') {
		return <Terminated {...props} />;
	} else if (step === 'end') {
		return <End {...props} />;
	} else {
		return null;
	}
};

export default Main;
