import React from "react";
import PropTypes from "prop-types";
import { motion } from 'framer-motion';
import cn from "src/utilities/bem-cn";
import "./styles.scss";

const className = "authenticate-message";
const el = name => cn(className, name);

const postedMessage = {
	complete: {
		opacity: 1,
		transition: {
			delayChildren: 200,
			staggerChildren: 100
		}
	},
	start: { 
		opacity: 0
	}
}
  
const posedMessageChild = {
	complete: { 
		y: 0, 
		opacity: 1 
	},
	start: { 
		y: 50, 
		opacity: 0 
	}
}

class Message extends React.Component {

	static propTypes = {
		image: PropTypes.string,
		title: PropTypes.string,
		summary: PropTypes.string
	}

	// Initiate pose animations 
	state = { isReady: false };
	componentDidMount() {
		setTimeout(this.setState({ isReady: !this.state.isReady }), 200);
	}

    render() {
        const { image, title, summary } = this.props;
		const { isReady } = this.state;

		return (
			<motion.div className={className} variants={postedMessage} initial="start" animate={isReady ? 'complete' : 'start'}>
				<motion.div variants={posedMessageChild} initial="start" animate={isReady ? 'complete' : 'start'}>
					<img src={image} className={el("icon")} alt={title} />
				</motion.div>
				<motion.div variants={posedMessageChild} initial="start" animate={isReady ? 'complete' : 'start'}>
					<h3 className={el("title")}>
						{title}
					</h3>
				</motion.div>
				<motion.div variants={posedMessageChild} initial="start" animate={isReady ? 'complete' : 'start'}>
					<p className={el("summary")}>
						{summary}
					</p>
				</motion.div>
			</motion.div>
		);
	}
}

export default Message;