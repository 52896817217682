export default {
	'this-survey-does-not-exist': '죄송합니다. 이 설문조사는 존재하지 않습니다.',
	'survey-closed': '죄송합니다. 이 설문조사는 현재 종료되었습니다.',
	'survey-no-products': '이 설문조사에는 제품이 없습니다.',
	'survey-no-products-summary': '이 설문조사의 소유자이신 경우 대시보드에서 제품을 생성하여 진행하십시오.',
	'survey-completed': '귀하는 이 설문조사를 이미 작성하셨습니다.',
	'summary-placeholder': '오류라고 생각하시면 이 링크를 보낸 사람에게 연락하십시오.',
	'gone-wrong': '문제가 있는 것 같습니다.',
	'try-again': '다시 시도하거나 나중에 다시 확인하십시오.',
	'thank-you': '감사합니다',
	'thank-you-summary': '설문조사가 완료되고 결과가 제출되었습니다.',
	'thank-you-summary-redirect': '몇 초 후에 리디렉션됩니다.',
	'page-not-found': '문제가 있습니다',
	'page-not-found-placeholder': '죄송합니다만 링크가 잘못되었습니다',
	'tap-your-favorite': '가장 마음에 드시는 항목에 체크 표시를 탭하십시오',
	or: '또는',
	'get-started-with-questions': '몇 가지 시작 질문',
	continue: '계속',
	retry: '다시 시도',
	'tell-us-how-you-feel-about-this-product': '이 제품에 대한 느낌을 말씀해 주십시오',
	begin: '시작하기',
	'how-would-you-describe-this-product-to-a-friend': '이 제품을 친구에게 어떻게 설명하시겠습니까?',
	'type-your-response-here': '여기에 답변을 입력하십시오.',
	'sum-of-total-characters': '${SUM}/${TOTAL}자',
	edit: '편집',
	remove: '제거',
	'how-do-you-feel-about-this-part': '이 부분에 대해 어떻게 생각하십니까?',
	'tap-on-an-area-and-choose-an-emoji': '영역을 탭하고 이모티콘 선택',
	'im-finished': '완료',
	maintenance: '유지보수',
	'maintenance-mode': 'Upsiide에서 현재 유지보수를 진행하고 있습니다. 잠시 후에 다시 확인해 주십시오.',
	'please-specify': '구체적으로 기재',
	'monadic-split-statement': '다음 화면에서 아이디어가 나옵니다. 이에 대해 질문이 있으므로 꼼꼼하게 검토해 주십시오.',
	disapproval: '못마땅하다',
	anger: '화가 나다',
	sad: '슬프다',
	joy: '기쁘다',
	love: '사랑이 넘친다',
	trust: '신뢰한다',
	hope: '희망차다',
	proud: '자랑스럽다',
	amazed: '어안이 벙벙하다',
	surprised: '놀랍다',
	guilt: '죄책감에 시달린다',
	curious: '호기심이 많다',
	jealous: '질투가 많다',
	anticipation: '기대감에 들떠 있다',
	optimistic: '낙관적이다',
	anxious: '불안하다',
	fear: '두렵다',
	despair: '절망적이다',
	disbelief: '불신한다',
	cynicism: '냉소적이다',
	regret: '후회스럽다',
	disgust: '역겹다',
	annoyed: '짜증이 난다',
	aggressive: '공격적이다',
	shocked: '충격적이다',
	choose: '선택하십시오 {{amount}}',
	'up-to': '까지 선택하십시오 {{amount}}',
	unlimited: '원하는 만큼 선택하십시오',
	'single-select': '하나를 고르시 오',
	number: '숫자를 입력하십시오',
	'number-between-min-max': '{{min}} 및 {{max}} 사이의 숫자를 입력하십시오',
	'number-min': '{{min}} 이상의 숫자를 입력하십시오',
	'number-max': '{{max}} 이하의 숫자를 입력하십시오',
	'open-ended-pledge':
		'본 설문조사에는 본인의 의견으로 답변해야 하는 질문이 있습니다. 귀하의 응답은 당사의 품질 요구 사항을 충족하는지 자동으로 확인됩니다. 계속하시겠습니까? ',
	'open-ended-pledge-accept': '예, 동의합니다',
	'open-ended-pledge-decline': '아니요, 동의하지 않습니다',
	'rh-1-label': '다음 중 스포츠가 아닌 것은 무엇입니까?',
	'rh-1-o-1': '농구',
	'rh-1-o-2': '테니스',
	'rh-1-o-3': '쿠키',
	'rh-1-o-4': '하키',
	'rh-2-label': '2+3은 무엇입니까?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': '다음 중 과일은 무엇입니까?',
	'rh-3-o-1': '우유',
	'rh-3-o-2': '초콜릿',
	'rh-3-o-3': '달걀',
	'rh-3-o-4': '사과',
	'open-end-max-length': '죄송합니다. 입력한 답변이 너무 깁니다.',
	'heatmap-click-image': `의견을 추가하려면 이미지를 클릭하십시오`,
	'heatmap-click-image-no-comments': `이미지 아무데나 클릭하십시오`,
	'heatmap-click-image-product-card': `의견을 추가하려면 카드를 클릭하십시오`,
	'heatmap-click-image-product-card--no-comment': `카드 아무데나 클릭하십시오`,
	'heatmap-type-comment': `의견을 입력하십시오`,
	cancel: `취소`,
	skip: '건너뛰다',
	ranked: '순위',
	top: '상위',
	'tap-or-drag': '탭하거나 드래그 앤 드롭하여 순위를 지정하세요',
	unranked: '순위 없음',
	reset: '초기화',
	'no-more-options': '더 이상 순위를 매길 수 있는 옵션이 없습니다',
	'rank-list-full': '순위 목록이 가득 찼습니다. 선택 항목 중 하나의 순위를 해제하세요.',
};
