import { createStore, applyMiddleware, compose } from "redux";

import effects from "src/domains/effects";
import reducer from "src/domains/reducer";

const composeEnhancers = (!process?.env?.production && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const effectsMiddlewares = effects.map(effect => store => next => action => {
	next(action);
	effect(store, action);
});

export default createStore(reducer, composeEnhancers(applyMiddleware(...effectsMiddlewares)));
