// React & Redux
import React, {useEffect} from 'react';
import {connect} from 'react-redux';

// Localization
import { withTranslation } from 'react-i18next';


// Styles
import './styles.scss';

// Components
import Message from 'src/components/shared/Message';

// Images
import imgAbout from 'public/images/icon-rocket.png';
import imgUnknown from 'public/images/icon-thinking-unknown.png';

// Actions, Selectors, Misc
import * as actions from '../../actions';
import * as selectors from '../../selectors';



const Terminated = ({ t, state }) => {

	useEffect(() => {
		document.body.classList.add('pull-to-refresh');
	}, []);
	
	if(state === "error") {
		return (
			<Message title={t('gone-wrong')} image={imgUnknown} summary={t('summary-placeholder')} />
		);
	}

	else {
		return (
			<Message 
			title={t('thank-you')} 
			image={imgAbout} 
			summary={t('thank-you-summary')} />
		);
	}
};

const mapStateToProps = state => ({
	study: selectors.getStudy(state),
	state: selectors.getState(state)
});

const mapDispatchToProps = dispatch => ({
	pushData: (force, audienceUuid) => dispatch(actions.pushData(force, audienceUuid))
});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(Terminated));
