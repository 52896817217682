import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { motion } from 'framer-motion';

import Message from "src/components/shared/Message";

import imgUnknown from "public/images/icon-thinking-unknown.png";

const wrap = {
	start: {
		y: 50,
		opacity: 0
	},
	completed: {
		y: 0,
		opacity: 1
	}
}

const Main = ({ t }) => {
	const [pose, setPose] = useState('start');
	useEffect(() => setPose('completed'), []);

	return (
		<motion.div animate={pose} variants={wrap} initial="start">
			<div>
				<Message title={t("page-not-found")} image={imgUnknown} summary={t("page-not-found-placeholder")} />
			</div>
		</motion.div>
	);
};


export default withTranslation('main')(Main);
