import React from "react";
import PropTypes from "prop-types";
import cn from "src/utilities/bem-cn";
import "./styles.scss";

const className = "loader-0";
const el = name => cn(className, name);

const CenteredLoader = () => (
	<div className={el("centered")}>
		<div className={className} />
	</div>
);

const Loader = ({ centered }) =>
	centered ? <CenteredLoader /> : <div className={className} />;

Loader.propTypes = {
	/** Whether to center the loader or not. */
	centered: PropTypes.bool
};
Loader.defaultProps = {
	centered: false
};

export default Loader;
