import React from 'react';

export default () => (
	<svg
		version="1.1"
		id="Capa_1"
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 512 512"
	>
		<circle fill="#FFE17D" cx="256" cy="256" r="256" />
		<path
			fill="#FFD164"
			d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.375-161.463
	C36.864,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.625-94.538
	C410.573,453.316,354.389,474.839,293.161,474.839z"
		/>
		<g>
			<path
				fill="#AA7346"
				d="M206.46,233.29c-3.024,0-6.016-1.319-8.056-3.855c-6.556-8.165-15.435-12.661-24.984-12.661
		s-18.427,4.496-24.984,12.661c-3.573,4.435-10.073,5.149-14.516,1.577c-4.444-3.569-5.153-10.069-1.581-14.512
		c10.411-12.948,25.379-20.371,41.081-20.371s30.669,7.423,41.081,20.371c3.573,4.444,2.863,10.944-1.581,14.512
		C211.016,232.544,208.726,233.29,206.46,233.29z"
			/>
			<path
				fill="#AA7346"
				d="M115.613,192.004c-4.097,0-7.968-2.456-9.581-6.492c-2.121-5.294,0.452-11.302,5.75-13.419
		l82.581-33.032c5.234-2.113,11.29,0.448,13.411,5.75c2.121,5.294-0.452,11.302-5.75,13.419l-82.581,33.032
		C118.194,191.766,116.895,192.004,115.613,192.004z"
			/>
			<path
				fill="#AA7346"
				d="M305.54,233.29c-2.266,0-4.548-0.746-6.46-2.274c-4.444-3.573-5.153-10.069-1.581-14.512
		c10.403-12.948,25.379-20.375,41.081-20.375s30.669,7.423,41.081,20.371c3.573,4.444,2.863,10.944-1.581,14.512
		c-4.444,3.573-10.952,2.859-14.516-1.577c-6.556-8.165-15.435-12.661-24.984-12.661c-9.556,0-18.427,4.496-24.984,12.657
		C311.556,231.972,308.565,233.29,305.54,233.29z"
			/>
			<path
				fill="#AA7346"
				d="M396.387,192.004c-1.282,0-2.573-0.238-3.831-0.742l-82.581-33.032
		c-5.298-2.117-7.871-8.125-5.75-13.419c2.121-5.298,8.129-7.859,13.411-5.75l82.581,33.032c5.298,2.117,7.871,8.125,5.75,13.419
		C404.355,189.548,400.484,192.004,396.387,192.004z"
			/>
		</g>
		<path
			fill="#9C6846"
			d="M256,305.305c-49.323,0-96.405-5.727-139.598-16.079c-5.595-1.341-10.417,4.504-8.691,10.596
	c19.92,70.324,78.718,121.34,148.289,121.34s128.369-51.016,148.289-121.34c1.726-6.092-3.096-11.937-8.691-10.596
	C352.405,299.578,305.323,305.305,256,305.305z"
		/>
		<path
			fill="#7D5046"
			d="M395.599,289.226c-43.194,10.352-90.275,16.079-139.599,16.079s-96.405-5.726-139.598-16.079
	c-5.595-1.341-10.417,4.504-8.691,10.596c5.214,18.408,13.148,35.443,23.186,50.637c41.04,7.796,82.792,12.085,125.121,12.085
	c42.315,0,84.051-4.286,125.079-12.076c10.041-15.196,17.978-32.233,23.193-50.645C406.015,293.729,401.194,287.885,395.599,289.226
	z"
		/>
		<path
			fill="#FFFFFF"
			d="M367.048,317.428c4.464-7.649,8.199-15.845,11.331-24.377
	c-38.384,7.921-79.532,12.253-122.379,12.253c-42.873,0-84.044-4.336-122.448-12.267c3.148,8.573,6.944,16.784,11.438,24.465
	c5.195,8.88,13.99,14.56,23.558,15.724c28.649,3.485,57.847,5.354,87.452,5.354c29.599,0,58.79-1.868,87.433-5.352
	C353.033,332.061,361.845,326.344,367.048,317.428z"
		/>
		<path
			fill="#A5CDFF"
			d="M14.513,365.359c-19.35-19.35-19.35-50.722,0-70.072c28.881-28.881,89.504-41.89,109.913-45.57
	c3.386-0.611,6.339,2.342,5.728,5.728c-3.68,20.41-16.689,81.033-45.57,109.913C65.235,384.708,33.862,384.708,14.513,365.359z"
		/>
		<path
			fill="#8CB4EB"
			d="M41.181,363.464c-19.35-19.35-19.35-50.722,0-70.072c14.9-14.9,41.788-29.107,67.776-40.527
	c-26.802,6.04-71.04,19.017-94.444,42.421c-19.35,19.35-19.35,50.722,0,70.072c13.955,13.955,34.123,17.685,51.566,11.511
	C56.959,374.97,48.259,370.542,41.181,363.464z"
		/>
		<path
			fill="#A5CDFF"
			d="M497.487,365.359c19.35-19.35,19.35-50.722,0-70.072c-28.881-28.881-89.504-41.89-109.913-45.57
	c-3.386-0.611-6.339,2.342-5.729,5.728c3.68,20.41,16.689,81.033,45.57,109.913C446.765,384.708,478.138,384.708,497.487,365.359z"
		/>
		<path
			fill="#8CB4EB"
			d="M450.296,355.206c-21.218-21.217-44.065-69.751-58.796-104.755c-1.421-0.273-2.756-0.524-3.925-0.734
	c-3.387-0.611-6.34,2.342-5.729,5.728c3.679,20.41,16.689,81.033,45.57,109.913c17.971,17.971,46.193,19.029,65.641,3.62
	C477.94,371.355,461.946,366.855,450.296,355.206z"
		/>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
	</svg>
);
