import React from 'react';
import Main from '../../containers/Main';
import { useParams } from 'react-router';

const getUrlParameter = name => {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
	var results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default () => {
	let { studyId, rnid, uuid, viewtype, variant } = useParams();

	if (!rnid) {
		rnid = getUrlParameter('rid');
	}
	return <Main studyId={studyId} rnid={rnid} uuid={uuid} />;
};
