export default {
	'this-survey-does-not-exist': 'Litujeme, ale tento průzkum neexistuje.',
	'survey-closed': 'Litujeme, ale tento průzkum je nyní uzavřen.',
	'survey-no-products': 'Tento průzkum neobsahuje žádné produkty.',
	'survey-no-products-summary':
		'Pokud jste vlastníkem tohoto průzkumu, vytvořte produkty na řídicím panelu a pokračujte.',
	'survey-completed': 'Tento průzkum jste již dokončili.',
	'summary-placeholder': 'Pokud si myslíte, že se jedná o chybu, kontaktujte osobu, která vám tento odkaz poslala.',
	'gone-wrong': 'Jejda, něco není v pořádku.',
	'try-again': 'Zkuste to znovu nebo se později vraťte zpět.',
	'thank-you': 'Děkujeme vám',
	'thank-you-summary': 'Průzkum je dokončen a výsledky byly odeslány.',
	'thank-you-summary-redirect': 'Za několik sekund budete přesměrováni.',
	'page-not-found': 'Něco není v pořádku.',
	'page-not-found-placeholder': 'Jejda, poškozený odkaz',
	'tap-your-favorite': 'Klepněte na zaškrtávací pole u své oblíbené položky',
	or: 'nebo',
	'get-started-with-questions': 'Několik otázek, které nám pomohou začít',
	continue: 'Pokračovat',
	retry: 'Opakovat',
	'tell-us-how-you-feel-about-this-product': 'Povězte nám, jaké pocity ve vás vzbuzuje tento produkt',
	begin: 'Začít',
	'how-would-you-describe-this-product-to-a-friend': 'Jak byste popsali tento produkt svým přátelům?',
	'type-your-response-here': 'Sem napište svou odpověď',
	'sum-of-total-characters': '${SUM} z ${TOTAL} znaků',
	edit: 'Upravit',
	remove: 'Odstranit',
	'how-do-you-feel-about-this-part': 'Jaké jsou vaše pocity z této části? ',
	'tap-on-an-area-and-choose-an-emoji': 'Klepněte na oblast a vyberte emoji',
	'im-finished': 'Skončil/a jsem',
	maintenance: 'Údržba',
	'maintenance-mode': 'Vraťte se prosím za chvíli.',
	'please-specify': 'Upřesněte',
	'monadic-split-statement':
		'Na další obrazovce vám ukážeme jeden nápad. Pečlivě si jej prohlédněte, protože k němu budeme mít otázky.',
	disapproval: 'Nesouhlas',
	anger: 'Vztek',
	sad: 'Smutek',
	joy: 'Radost',
	love: 'Láska',
	trust: 'Důvěra',
	hope: 'Naděje',
	proud: 'Hrdost',
	amazed: 'Ohromení',
	surprised: 'Překvapení',
	guilt: 'Vina',
	curious: 'Zvědavost',
	jealous: 'Žárlivost',
	anticipation: 'Očekávání',
	optimistic: 'Optimismus',
	anxious: 'Úzkost',
	fear: 'Strach',
	despair: 'Zoufalství',
	disbelief: 'Nedůvěra',
	cynicism: 'Cynismus',
	regret: 'Lítost',
	disgust: 'Znechucení',
	annoyed: 'Mrzutost',
	aggressive: 'Agresivita',
	shocked: 'Šok',
	choose: 'Zvolte {{amount}}',
	'up-to': 'Zvolte až {{amount}}',
	unlimited: 'Zvolte si, kolik chcete',
	'single-select': 'Vyber jeden',
	number: 'Zadejte číslo',
	'number-between-min-max': 'Zadejte číslo mezi {{min}} a {{max}}',
	'number-min': 'Zadejte číslo {{min}} nebo vyšší',
	'number-max': 'Zadejte číslo {{max}} nebo nižší',
	'open-ended-pledge':
		'Tento průzkum obsahuje otázky, na které budete muset odpovědět vlastními slovy. Vaše odpovědi projdou automatickou kontrolou, zda splňují naše požadavky na kvalitu. Souhlasíte s pokračováním? ',
	'open-ended-pledge-accept': 'Ano, souhlasím',
	'open-ended-pledge-decline': 'Ne, nesouhlasím',
	'rh-1-label': 'Co z následujícího nepatří mezi sporty?',
	'rh-1-o-1': 'Basketbal',
	'rh-1-o-2': 'Tenis',
	'rh-1-o-3': 'Sušenka',
	'rh-1-o-4': 'Hokej',
	'rh-2-label': 'Kolik je 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Co z následujících je ovoce?',
	'rh-3-o-1': 'Mléko',
	'rh-3-o-2': 'Čokoláda',
	'rh-3-o-3': 'Vejce',
	'rh-3-o-4': 'Jablko',
	'open-end-max-length': 'Omlouváme se, zadaná odpověď je příliš dlouhá',
	'heatmap-click-image': `Kliknutím na obrázek přidáte komentář“`,
	'heatmap-click-image-no-comments': `Klikněte kamkoli na obrázek“`,
	'heatmap-click-image-product-card': `Kliknutím na kartu přidáte komentář“`,
	'heatmap-click-image-product-card--no-comment': `Klikněte kamkoli na kartu`,
	'heatmap-type-comment': `Napište komentář`,
	cancel: `Zrušit`,
	skip: 'Přeskočit',
	ranked: 'Zařazeno',
	top: 'Horní',
	'tap-or-drag': 'Hodnocení provedete klepnutím nebo přetažením',
	unranked: 'Nezařazeno',
	reset: 'Resetovat',
	'no-more-options': 'Žádné další možnosti hodnocení',
	'rank-list-full': 'Seznam hodnocení je plný, zrušte hodnocení jedné ze svých možností',
};
