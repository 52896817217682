import React from 'react';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'video-player';
const el = (name) => cn(className, name);

const VideoPlayer = ({
	url, // can be youtube, vimeo or mp4 link
	controls = true,
	...props // ReactPlayer props
}) => {
	if (url) {
		return (
			<div className={className}>
				<ReactPlayer {...props} controls={controls} url={url} playsinline />
			</div>
		);
	}
	return null;
};

VideoPlayer.propTypes = {
	url: PropTypes.string,
	controls: PropTypes.bool,
	props: PropTypes.any,
};

export default VideoPlayer;
