export default {
	'this-survey-does-not-exist': 'نأسف، هذا الاستبيان غير موجود.',
	'survey-closed': 'نأسف، هذا الاستبيان مغلق الآن.',
	'survey-no-products': 'هذا الاستبيان لا يحوي أي منتجات.',
	'survey-no-products-summary': 'إذا كنت مالك هذا الاستبيان، أضف منتجات للوحة المعلومات للمتابعة.',
	'survey-completed': 'لقد أكملت هذا الاستبيان بالفعل.',
	'summary-placeholder': 'إذا كنت تعتقد أن هذا خطأ، يُرجى التواصل مع الشخص الذي أرسل لك هذا الرابط.',
	'gone-wrong': 'امم، هناك شيء غير صحيح.',
	'try-again': 'حاول مرة أخرى أو عُد لاحقًا.',
	'thank-you': 'شكرًا لك',
	'thank-you-summary': 'هذا الاستبيان مكتمل، وأُرسلت نتائجك.',
	'thank-you-summary-redirect': 'سيُعاد توجيهك في غضون بضع ثوانٍ.',
	'page-not-found': 'شيء ما غير صحيح',
	'page-not-found-placeholder': 'أوه، رابط معطل',
	'tap-your-favorite': 'اضغط على علامة الاختيار على ما تفضله',
	or: 'أو',
	'get-started-with-questions': 'بضع أسئلة لنبدأ',
	continue: 'متابعة',
	retry: 'إعادة المحاولة',
	'tell-us-how-you-feel-about-this-product': 'أخبرنا عن شعورك تجاه هذا المنتج',
	begin: 'بدأ',
	'how-would-you-describe-this-product-to-a-friend': 'كيف تصف هذا المنتج لصديق؟',
	'type-your-response-here': 'أكتب إجابتك هناâ€¦',
	'sum-of-total-characters': '${SUM} من ${TOTAL} رمز',
	edit: 'تعديل',
	remove: 'حذف',
	'how-do-you-feel-about-this-part': 'ما هو شعورك تجاه هذا الجزء؟',
	'tap-on-an-area-and-choose-an-emoji': 'اضغط على منطقة ما واختر رمزًا تعبيريًا',
	'im-finished': 'انتهيت',
	maintenance: 'صيانة',
	'maintenance-mode': 'تخضع أبسايد Upsiide حاليًا للصيانة يُرجى العودة للتأكد لاحقًا.',
	'please-specify': 'يُرجى التحديد',
	'monadic-split-statement': 'في الشاشة التالية، سنعرض لك فكرة. يرجى مراجعتها بعناية حيث سنطرح عليك أسئلة حولها.',
	disapproval: 'رفض',
	anger: 'غضب',
	sad: 'حزين',
	joy: 'بهجة',
	love: 'حب',
	trust: 'ثقة',
	hope: 'أمل',
	proud: 'فخر',
	amazed: 'مذهول',
	surprised: 'متفاجئ',
	guilt: 'شعور بالإثم',
	curious: 'فضولي',
	jealous: 'غيور',
	anticipation: 'حدس',
	optimistic: 'متفائل',
	anxious: 'قلِق',
	fear: 'خوف',
	despair: 'يأس',
	disbelief: 'إنكار',
	cynicism: 'سخرية',
	regret: 'ندم',
	disgust: 'اشمئزاز',
	annoyed: 'منزعج',
	aggressive: 'عدواني',
	shocked: 'مصدوم',
	choose: 'اختر {{amount}}',
	'up-to': 'اختر ما يصل إلى {{amount}}',
	unlimited: 'اختر أي عدد كما تحب',
	'single-select': 'اختر واحدة',
	number: 'أدخل رقمًا',
	'number-between-min-max': 'أدخل رقمًا بين {{min}} و{{max}}',
	'number-min': 'أدخل رقمًا {{min}} أو أكثر',
	'number-max': 'أدخل رقمًا {{max}} أو أقل',
	'open-ended-pledge':
		'يحتوي هذا الاستبيان على أسئلة مطلوب الإجابة عنها بأسلوبك الخاص، وستخضع إجاباتك للفحص تلقائيًّا؛ للتأكد من استيفائها لمتطلبات الجودة لدينا، فهل توافق على المتابعة؟ ',
	'open-ended-pledge-accept': 'نعم، أوافق',
	'open-ended-pledge-decline': 'لا، لا أوافق',
	'rh-1-label': 'أي مما يلي ليس رياضة؟',
	'rh-1-o-1': 'كرة السلة',
	'rh-1-o-2': 'التنس',
	'rh-1-o-3': 'الكوكيز',
	'rh-1-o-4': 'الهوكي',
	'rh-2-label': 'كم حاصل 2+3؟',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'أي مما يلي فاكهة؟',
	'rh-3-o-1': 'الحليب',
	'rh-3-o-2': 'الشوكولاتة',
	'rh-3-o-3': 'البيض',
	'rh-3-o-4': 'التفاح',
	'open-end-max-length': 'عذرا ، الجواب المدخل طويل جدا',
	'heatmap-click-image': `انقر في أي مكان على الصورة`,
	'heatmap-click-image-no-comments': `انقر على البطاقة لإضافة تعليق`,
	'heatmap-click-image-product-card': `انقر في أي مكان على البطاقة`,
	'heatmap-click-image-product-card--no-comment': `اكتب تعليقًا`,
	'heatmap-type-comment': `إلغاء`,
	cancel: `حذف'`,
	skip: 'يتخطى',
	ranked: 'مرتبة',
	top: 'قمة',
	'tap-or-drag': 'اضغط أو اسحب وأفلت للتصنيف',
	unranked: 'غير مصنف',
	reset: 'إعادة ضبط',
	'no-more-options': 'لا مزيد من الخيارات للترتيب',
	'rank-list-full': 'قائمة التصنيف ممتلئة، قم بإلغاء تصنيف أحد اختياراتك',
};
