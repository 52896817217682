import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'ranked-question-container';
const el = (name, mod) => cn(className, name, mod);

const RankedQuestionContainer = ({ id, itemsLength, maxItemLength, children, productId }) => {
	const { t } = useTranslation('main');
	// attributes and listeners here can be used to make the containers movable in the future
	const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
		id,
		data: { type: 'ranked-question-container' },
	});

	const isMobile = useIsMobile();

	return (
		<div
			className={`${className} ${!isMobile ? el('desktop') : ''} ${
				productId && !isMobile ? el('idea-splits') : ''
			}`}
			ref={setNodeRef}
		>
			<div className={`${el('title')} ${!isMobile ? el('title-desktop') : ''}`}>
				{id === 'rankedOptions' ? `${t('ranked')} (${t('top')} ${maxItemLength})` : t('unranked')}
				{id === 'rankedOptions' && (
					<div className={el('rank-count')}>{`${itemsLength} / ${maxItemLength}`}</div>
				)}
			</div>
			<div className={`${el('options')} ${itemsLength === 0 ? el('no-items') : null}`}>
				{itemsLength === 0 && <p>{`${id === 'rankedOptions' ? t('tap-or-drag') : t('no-more-options')}`}</p>}
				{children}
			</div>
		</div>
	);
};

RankedQuestionContainer.propTypes = {
	id: PropTypes.string,
	children: PropTypes.node,
	itemsLength: PropTypes.number,
	maxItemLength: PropTypes.number,
	productId: PropTypes.number,
};

export default RankedQuestionContainer;
