import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Battleground from '../../components/Battleground';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

const Survey = ({
	t,
	study = {},
	sections,
	currentSection,
	setResults,
	setNextSection,
	jumpToQuestion,
	jumpToSection,
}) => {
	const { products, productOrder } = currentSection;

	// TODO - get text-AI going again
	// const testMode = !!getUrlParameter('previewUuid') || !!getUrlParameter('test');
	// const endIndex = sections.findIndex(section => section === 'end');
	// const textAiIndex = sections.findIndex(section => section === 'text-ai');
	// const nextStep = testMode ? endIndex : textAiIndex;

	const doProductLogic = (swipeResults) => {
		const { logic } = currentSection;
		if (logic.length) {
			// using classic for-loop here so we can short-circuit as soon as a condition is met.
			for (let i = 0; i < logic.length; i++) {
				const logicItem = logic[i];
				const {
					triggerOptions,
					triggerType,
					operand,
					operator,
					actionSectionId,
					actionItemId,
					actionType,
					triggerItemId,
				} = logicItem;
				let logicConditionsMatched = false;
				const { interests } = swipeResults;
				const cleanedTriggerOptions = triggerOptions.filter((triggerOption) => {
					if (triggerOption && Object.keys(triggerOption).length) {
						return triggerOption;
					}
					return false;
				});
				// Compare to localProductId
				const productInterest = interests.find((interest) => interest.localProductId === triggerItemId);
				if (productInterest) {
					// TODO - should only support `is`
					if (operator === 'is') {
						logicConditionsMatched = cleanedTriggerOptions.some(
							(triggerOption) => triggerOption && productInterest.interest == triggerOption.value,
						);
					} else if (operator === 'is_not') {
						logicConditionsMatched = cleanedTriggerOptions.every(
							(triggerOption) => triggerOption && productInterest.interest != triggerOption.value,
						);
					}
				}
				// TODO - if actionType is terminate - kill em
				// If this logic is matching, send it
				if (logicConditionsMatched) {
					if (actionType === 'terminate') {
						// TODO - terminate the user in our system once API available
						if (study.disqualifiedUrl) {
							window.location.replace(study.disqualifiedUrl);
						} else {
							// send to in-app terminated screen.
							setStep('terminated');
						}
						return [false, false];
					}
					return [actionSectionId, actionItemId];
				}
			}
		}
		return [false, false];
	};

	const getIncludeCommitments = () => {
		const settingsIndex = currentSection.settings.findIndex((item) => item.label === 'include_commitment');
		if (settingsIndex > -1) {
			return currentSection.settings[settingsIndex].value === 'true';
		}
		return true;
	};

	const getCommitmentLayout = () => {
		const settingsIndex = currentSection.settings.findIndex((item) => item.label === 'commitment_layout');
		if (settingsIndex > -1) {
			return currentSection.settings[settingsIndex].value;
		}
		return 'field-one';
	};

	const handleResults = (results) => {
		setResults(results);
		const [sectionId, questionId] = doProductLogic(results);
		if (sectionId || questionId) {
			if (questionId) {
				jumpToQuestion(questionId, sectionId);
			} else if (sectionId !== currentSection.id) {
				jumpToSection(sectionId);
			}
		} else {
			setNextSection();
		}
	};

	const getProductMissingTranslation = (product) => {
		// TODO: Uncomment / Update this as part of second hotfix
		// if (product.fieldOneType === "asset" && product.fieldOne) return true;
		// if (product.fieldTwoType === "asset" && product.fieldTwo) return true;
		// if (product.fieldThreeType === "asset" && product.fieldThreeType) return true;

		//	Check if product has a study translation
		if (product.fieldOneType === 'html' && !product.fieldOne && !product.studyTranslationId) return;
		if (product.fieldTwoType === 'html' && !product.fieldTwo && !product.studyTranslationId) return;
		if (product.fieldThreeType === 'html' && !product.fieldThree && !product.studyTranslationId) return;
		return true;
	};

	const orderedProducts = productOrder
		.map((id) => products.find((p) => p.id === id))
		.filter((p) => {
			const missingTranslation = getProductMissingTranslation(p);

			return missingTranslation; // Filter out products without translation
		});

	const includeCommitments = getIncludeCommitments();
	const commitmentLayout = getCommitmentLayout();
	const { settings } = currentSection;

	// Idea screen layout
	const ideaScreenLayoutSetting = settings ? settings.find((s) => s.label === 'idea_screen_layout') : { value: '' };
	const ideaScreenLayout =
		ideaScreenLayoutSetting && ideaScreenLayoutSetting.value ? ideaScreenLayoutSetting.value : 'invalid_layout';
	// Idea image size
	const imageSizeSetting = settings ? settings.find((s) => s.label === 'image_size') : { value: '' };
	const imageSize = imageSizeSetting && imageSizeSetting.value ? `size-${imageSizeSetting.value}` : 'medium';
	// Idea image cropping
	const imageCroppingSetting = settings ? settings.find((s) => s.label === 'image_cropping') : { value: '' };
	const imageCropping =
		imageCroppingSetting && imageCroppingSetting.value ? `image-${imageCroppingSetting.value}` : 'fit';

	console.log('SOURCE OF TRUTH: All data starts from here: ', currentSection);

	useEffect(() => {
		!orderedProducts?.length && setNextSection(true);
	}, [orderedProducts, setNextSection]);

	return (
		<Battleground
			currentSection={currentSection}
			showCommitments={includeCommitments}
			products={orderedProducts}
			onResults={handleResults}
			commitmentLayout={commitmentLayout}
			study={study}
			// * Ideas revamp
			ideaScreenLayout={ideaScreenLayout}
			imageSize={imageSize}
			imageCropping={imageCropping}
		/>
	);
};

const mapStateToProps = (state) => ({
	study: selectors.getStudy(state),
	sections: selectors.getSections(state),
	currentSection: selectors.getCurrentSection(state),
});

const mapDispatchToProps = (dispatch) => ({
	setResults: (results) => dispatch(actions.setResults(results)),
	setNextSection: () => dispatch(actions.setNextSection()),
	jumpToQuestion: (questionId, sectionId) => dispatch(actions.jumpToQuestion(questionId, sectionId)),
	jumpToSection: (sectionId) => dispatch(actions.jumpToSection(sectionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
