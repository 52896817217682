import React from 'react';
import PropTypes from 'prop-types';

const RedHerringQuestion = ({
	questionLabel,
	children,
}) => {
	return (
		<div className={"question"}>
			<label>
				<div className="question-label">
					<p>{ questionLabel }</p>
				</div>
				{ children }
			</label>
		</div>
	);
};

RedHerringQuestion.propTypes = {
	questionLabel: PropTypes.string,
	children: PropTypes.any,
};

export default RedHerringQuestion;
