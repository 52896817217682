import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import { motion } from 'framer-motion';

import FeedbackSelect from '../FeedbackSelect';

import * as actions from '../../../actions';
import * as selectors from '../../../selectors';

import './styles.scss';

import iconSurveyTapArea from 'public/images/icon-survey-tap-area.svg';

const className = 'text-ai-product-feedback';
const el = (name, mod) => cn(className, name, mod);

const animation = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
		transition: {
			staggerChildren: 100,
		}
	},
}

class TextAIProductFeedback extends React.Component {
	state = {
		pose: 'start',
		clickPoints: [],
		queuedPoint: {},
		height: 0,
		width: 0,
		orientation: 0,
	};

	componentDidMount() {
		const orientation = isNaN(window.orientation)
			? 0
			: !isNaN(window.orientation)
			? window.orientation
			: window.screen.orientation.angle;
		this.setState({ ...this.state, pose: 'completed', orientation }, () => {
			window.addEventListener('resize', () => this.onOrientationChange(), false);

			window.addEventListener(
				'orientationchange',
				function() {
					// Generate a resize event if the device doesn't do it
					window.dispatchEvent(new Event('resize'));
				},
				false,
			);
		});

		window.addEventListener('click', () => {
			const { clickPoints, queuedPoint } = this.state;
			const currentOpenIndex = clickPoints.findIndex(point => point.open === true);
			// if any saved node is open close it
			if (currentOpenIndex > -1) {
				this.openOrCloseNode(currentOpenIndex);
			}
			// if a new node that is still not saved active, close it too
			if (Object.keys(queuedPoint).length > 0) {
				this.setState({ ...this.state, queuedPoint: {} });
			}
		});
	}

	onOrientationChange = () => {
		// on orientationchange the components is yet to be re-rendered, get the coordinate after it finishes
		const afterOrientationChange = () => {
			this.getImagePosition();
			window.removeEventListener('resize', afterOrientationChange, false);
			if (this.cardRef) {
				this.cardRef.addEventListener('resize', this.getImagePosition(), {
					capture: false,
					once: true,
				});
			}
		};

		window.addEventListener('resize', afterOrientationChange(), false);
	};

	componentWillUnmount() {
		this.cardRef.removeEventListener('resize', this.getImagePosition(), {
			capture: false,
			once: true,
		});
		window.removeEventListener('orientationchange', this.onOrientationChange, { capture: false, once: true });
	}

	getImagePosition = () => {
		const cardRect = this.cardRef ? this.cardRef.getBoundingClientRect() : null;
		if (cardRect) {
			const { width, height } = cardRect;
			const orientation = isNaN(window.orientation)
				? 0
				: !isNaN(window.orientation)
				? window.orientation
				: window.screen.orientation.angle;
			this.setState({ ...this.state, width, height, orientation });
		}
	};

	assignCardRef = node => (this.cardRef = node);

	assignImgRef = node => (this.cardRef = node);

	addClickPoint = newPoint =>
		this.setState(prevState => ({ ...prevState, clickPoints: [...prevState.clickPoints, newPoint] }));

	removeClickPoint = index => {
		const { clickPoints } = this.state;
		clickPoints.splice(index, 1);
		this.setState({ ...this.state, clickPoints });
	};

	removeQueuePoint = () => {
		this.setState(prevState => ({ ...prevState, queuedPoint: {} }));
	};

	setQueuedPoint = point => {
		this.setState({ ...this.state, queuedPoint: point });
	};

	openOrCloseNode = index => {
		if (index > -1) {
			const { clickPoints } = this.state;
			clickPoints[index].open = !clickPoints[index].open;
			this.setState({ ...this.state, clickPoints });
		}
	};

	// handle next page button click
	handleClick = productData => {
		const { onNext, setSentimentTags } = this.props;
		const { clickPoints } = this.state;
		const fieldOneData = productData.fieldOne[0];

		// get the variation id
		let variationId = null;
		if (fieldOneData.url) {
			variationId = fieldOneData.variations.find(v => v.url === fieldOneData.url).id;
		}

		// add product data to the clickpoints
		clickPoints.forEach(point => {
			point.x = parseFloat(point.x)
				.toFixed(2)
				.toString();
			point.y = parseFloat(point.y)
				.toFixed(2)
				.toString();
			point.productId = productData ? productData.id : null;
			point.assetVariationId = variationId;
			delete point.open;
		});

		setSentimentTags(clickPoints);
		onNext();
	};

	// handle clicks on the image area
	clickImg = async event => {
		event.stopPropagation();
		const imageRect = event.target.getBoundingClientRect();
		const { width, height, x, y } = imageRect;
		const { queuedPoint, clickPoints } = this.state;
		const currentOpenIndex = clickPoints.findIndex(point => point.open === true);

		const horClick = ((event.clientX - x) / width) * 100;
		const vertClick = ((event.clientY - y) / height) * 100;

		// find if there are any open queued point, if yes close it
		if (Object.keys(queuedPoint).length > 0) {
			await new Promise(resolve => this.setState({ ...this.state, queuedPoint: {} }, resolve));
		} else {
			this.setState(prevState => ({
				...prevState,
				queuedPoint: { x: horClick, y: vertClick, open: true, emoji: '' },
			}));
		}

		if (currentOpenIndex > -1) {
			event.stopPropagation();
			this.openOrCloseNode(currentOpenIndex);
		}
	};

	// handle clicks on the click points / nodes
	clickNode = async i => {
		event.stopPropagation();
		const { clickPoints, queuedPoint } = this.state;
		const currentOpenIndex = clickPoints.findIndex(point => point.open === true);

		if (i !== currentOpenIndex) {
			this.openOrCloseNode(currentOpenIndex);
		}

		if (Object.keys(queuedPoint).length > 0) {
			await new Promise(resolve => this.setState({ ...this.state, queuedPoint: {} }, resolve));
		}

		this.openOrCloseNode(i);
	};

	selectResponse = (index, response) => {
		const { clickPoints } = this.state;
		clickPoints[index].emoji = response;
		this.setState({ ...this.state, clickPoints });
	};

	selectResponseForQueued = (index, response) => {
		const { queuedPoint } = this.state;
		queuedPoint.emoji = response;
		this.setState({ ...this.state, queuedPoint });
	};

	submitClick = index => {
		const { queuedPoint } = this.state;
		if (index) {
			this.openOrCloseNode(index);
		} else {
			queuedPoint.open = false;
			this.setState(prevState => ({
				...prevState,
				queuedPoint: {},
				clickPoints: [...prevState.clickPoints, queuedPoint],
			}));
		}
	};

	renderClickPoints = (points, isQueue) => {
		const { width, height, clickPoints, queuedPoint } = this.state;
		const { t } = this.props;
		const cardRect = this.cardRef ? this.cardRef.getBoundingClientRect() : null;
		const allClosed =
			clickPoints.findIndex(point => point.open === true) === -1 && Object.keys(queuedPoint).length < 1;

		return (
			cardRect &&
			points.map((coordinate, i) => {
				if (Object.keys(coordinate).length > 0)
					return (
						<div
							key={i}
							className={el('click-point')}
							style={{
								left: (width * coordinate.x) / 100 - 27,
								top: (height * coordinate.y) / 100 - 27,
							}}
						>
							<div
								className={`${el('click-node')} ${
									coordinate.open || isQueue || allClosed ? 'selected' : ''
								}`}
								onClick={() => (isQueue ? this.removeQueuePoint() : this.clickNode(i))}
							/>
							<div className={el('tooltip')}>
								<FeedbackSelect
									selectResponse={isQueue ? this.selectResponseForQueued : this.selectResponse}
									open={coordinate.open || false}
									selected={coordinate.emoji}
									nodeIndex={i}
									removeClickPoint={() => {
										isQueue ? this.removeQueuePoint() : this.removeClickPoint(i);
									}}
									onSubmit={this.submitClick}
									t={t}
									isQueue={isQueue}
								/>
							</div>
						</div>
					);
			})
		);
	};

	render() {
		const { t, textAiProduct, imgUrl } = this.props;
		const { pose, clickPoints, queuedPoint, orientation } = this.state;

		const productTitle = t('tell-us-how-you-feel-about-this-product');
		const productPrompt = t('tap-on-an-area-and-choose-an-emoji');
		const buttonLabel = t('im-finished');

		const landscape = orientation === 0 ? '' : 'landscape';

		return (
			<motion.div animate={pose} variants={animation} initial="start" className={className}>
				<div className={`${el('content')} ${landscape}`}>
					<div className={`${el('card')} ${landscape}`} ref={this.assignCardRef}>
						<img
							className={`${el('image')} ${landscape}`}
							src={imgUrl}
							alt={productTitle}
							onClick={this.clickImg}
							onLoad={() => this.getImagePosition()}
							ref={this.assignImgRef}
						/>
						<div className={el('click-points')}>{this.renderClickPoints(clickPoints, false)}</div>
						<div className={el('queued-point')}>{this.renderClickPoints([queuedPoint], true)}</div>
					</div>
				</div>
				<div className={el('footer')}>
					<h3 className={el('title')}>{productTitle}</h3>
					<img className={el('icon')} src={iconSurveyTapArea} alt={productPrompt} draggable="false" />
					<div className={el('footer-cta')}>{productPrompt}</div>
					<button
						className={`basic-button ${el('footer-button')}`}
						onClick={() => this.handleClick(textAiProduct)}
					>
						{t(`${buttonLabel} →`)}
					</button>
				</div>
			</motion.div>
		);
	}
}

const mapStateToProps = state => ({
	study: selectors.getStudy(state),
	textAIProductId: selectors.getTextAIProductId(state),
});

const mapDispatchToProps = dispatch => ({
	proceed: step => dispatch(actions.proceed(step)),
	setSentimentTags: tags => dispatch(actions.setSentimentTags(tags)),
});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(TextAIProductFeedback));
