import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { motion } from 'framer-motion';
import Construction from 'public/images/construction.svg';
import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'maintenance-component';
const el = (name, modifier) => cn(className, name, modifier);

const wrap = {
	start: {
		y: 50,
		opacity: 0
	},
	completed: {
		y: 0,
		opacity: 1
	}
}

const Main = ({ t }) => {
	const [pose, setPose] = useState('start');
	const statusPath = 'https://status.upsiide.com/';
	const altText = 'construction';
	const titleText = "We're working on something special";
	const maintenanceText = "We're undergoing scheduled maintenance to bring you the best possible experience. Check back soon to see the latest update.";
	const statusText = 'Check Status Page';

	useEffect(() => setPose('completed'), []);

	return (
		<motion.div animate={pose} variants={wrap} initial="start">
			<div className={className}>
				<div className={el('content')}>
					<img className={el('image')} src={Construction} alt={altText} />
					<div className={el('title')}>{titleText}</div>
					<div className={el('subtitle')}>{maintenanceText}</div>
					<div className={el('footer')}>
						<a
							className={el('status-button')}
							href={statusPath}
							target="_blank"
							rel="noopener noreferrer"
						>
							{statusText}
						</a>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default withTranslation('main')(Main);
