import React from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';

import './styles.scss';

const className = 'progress-bar';
const el = name => cn(className, name);


const ProgressBar = ({
    activeItem,
    totalItemsCount,
    label,
}) => (
    <div className={el('container')}>
        <div className={el('indicator')}>
            <div
                className={el('bar')}
                style={{
                    width: `${((activeItem) / totalItemsCount) * 100}%`,
                    borderTopRightRadius:
                        ((activeItem) / totalItemsCount) * 100 >= 97 ? '10px' : '0',
                    borderBottomRightRadius:
                        ((activeItem) / totalItemsCount) * 100 >= 97 ? '10px' : '0',
                }}
            ></div>
        </div>
        <span className={el('bar-percent')}>
            {label} {`${(activeItem + 1).toLocaleString()} / ${(totalItemsCount).toLocaleString()}`}
        </span>
    </div>
);

ProgressBar.propTypes = {
    activeItem: PropTypes.number,
    totalItemsCount: PropTypes.number,
    label: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default ProgressBar;
