const calculateWpm = (noOfChars, totalTypingTime) => {
	const wpm = Math.ceil(noOfChars / 5) / (totalTypingTime / 60);
	return Math.ceil(wpm);
};

const getTotalTypingTime = (startTime, endTime) => (endTime - startTime) / 1000;

export const getWpmData = ({ answer, focusStartTime, focusEndTime }) => {
	const totalTypingTime = getTotalTypingTime(focusStartTime.getTime(), focusEndTime.getTime());
	const noOfChars = answer.value.split('').length;

	const wpm = calculateWpm(noOfChars, totalTypingTime);

	return {
		totalTypingTime,
		noOfChars,
		wpm,
	};
};
