import React from 'react';
import { Tooltip } from 'react-tippy';
import Proptypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import SmileEmoji from 'src/components/icons/SmileEmoji';
import LoveEmoji from 'src/components/icons/LoveEmoji';
import LaughEmoji from 'src/components/icons/LaughEmoji';
import SadEmoji from 'src/components/icons/SadEmoji';
import ThinkingEmoji from 'src/components/icons/ThinkingEmoji';
import SickEmoji from 'src/components/icons/SickEmoji';

import 'react-tippy/dist/tippy.css';
import './styles.scss';

const className = 'feedback-select';
const el = (name, mod) => cn(className, name, mod);

const emojiOptions = [
	{ type: 'smile', label: SmileEmoji },
	{ type: 'heart_eyes', label: LoveEmoji },
	{ type: 'thinking_face', label: ThinkingEmoji },
	{ type: 'funny', label: LaughEmoji },
	{ type: 'confounded', label: SadEmoji },
	{ type: 'sick', label: SickEmoji },
];

const FeedbackSelect = ({ t, selectResponse, open, selected, nodeIndex, removeClickPoint, onSubmit }) => {
	
	const removePoint = () => {
		if (open) {
			removeClickPoint();
		}
	};

	const renderEmoji = Type => <Type />;

	const renderHeader = () => (
		<div className={el('header')}>
			<span className={el('header-text')}>{t('how-do-you-feel-about-this-part')}</span>
			<div>
				<span className={el('header-remove')} onClick={removePoint}>
					{t('remove')}
				</span>
				<span className={el('header-close')} onClick={removePoint}>
					X
				</span>
			</div>
		</div>
	);

	const renderSubmitButton = () => (
		<span className={`option submit ${selected.length > 0 ? 'active' : ''}`} onClick={() => {
			if (selected.length > 0) {
				onSubmit();
			}
		}}>{'→'}</span>
	);

	const renderAllEmojis = () => (
		<div className={el('options')}>
			{emojiOptions.map((option, optionIndex) => (
				<div
					key={optionIndex}
					className={`option ${option.type === selected ? 'selected' : ''}`}
					label={option.type}
					onClick={() => selectResponse(nodeIndex, option.type)}
				>
					{renderEmoji(option.label)}
				</div>
			))}
			{renderSubmitButton()}
		</div>
	);

	return (
		<Tooltip
			animation="shift"
			animationFill={false}
			arrow
			interactive
			style={{ top: '-15px', left: '-7px' }}
			open={open}
			theme="light"
			position="top"
			trigger="mouseenter focus"
			html={
				<div className={className} onClick={ev => ev.stopPropagation()}>
					{renderHeader()}
					{renderAllEmojis()}
				</div>
			}
		/>
	);
};

FeedbackSelect.propTypes = {
	selectResponse: Proptypes.func,
	open: Proptypes.bool,
	selected: Proptypes.string,
	nodeIndex: Proptypes.number,
};

export default FeedbackSelect;
