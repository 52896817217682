export default {
	'this-survey-does-not-exist': 'Sorry, this survey does not exist.',
	'survey-closed': 'Sorry, this survey is now closed.',
	'survey-no-products': 'This survey has no products.',
	'survey-no-products-summary': 'If you are the owner of this survey, create products in dashboard to proceed.',
	'survey-completed': 'You have already completed this survey.',
	'summary-placeholder': 'If you believe this is an error, please reach out to the person who sent you this link.',
	'gone-wrong': "Hmmm, something's not right.",
	'try-again': 'Try again or come back later.',
	'thank-you': 'Thank you',
	'thank-you-summary': 'Your survey is complete and results have been submitted',
	'thank-you-summary-redirect': 'You will be redirected in a few seconds.',
	'page-not-found': "Something's not right",
	'page-not-found-placeholder': 'Oops, broken link',
	'tap-your-favorite': 'Tap the checkmark on your favorite',
	or: 'or',
	'get-started-with-questions': 'A few questions to get us started',
	continue: 'Continue',
	retry: 'Retry',
	'tell-us-how-you-feel-about-this-product': 'Tell us how you feel about this product',
	begin: 'Begin',
	'how-would-you-describe-this-product-to-a-friend': 'How would you describe this product to a friend?',
	'type-your-response-here': 'Type your response here…',
	'sum-of-total-characters': '${SUM} of ${TOTAL} characters',
	edit: 'Edit',
	remove: 'Remove',
	'how-do-you-feel-about-this-part': 'How do you feel about this part?',
	'tap-on-an-area-and-choose-an-emoji': 'Tap on an area and choose an emoji',
	'im-finished': "I'm finished",
	maintenance: 'Maintenance',
	'maintenance-mode': 'Upsiide is currently undergoing maintenance. Please check back soon.',
	'please-specify': 'Please specify',
	'monadic-split-statement':
		'On the next screen, we will show you an idea. Please review it carefully as we will have questions about it.',
	disapproval: 'Disapproval',
	anger: 'Anger',
	sad: 'Sad',
	joy: 'Joy',
	love: 'Love',
	trust: 'Trust',
	hope: 'Hope',
	proud: 'Proud',
	amazed: 'Amazed',
	surprised: 'Surprised',
	guilt: 'Guilt',
	curious: 'Curious',
	jealous: 'Jealous',
	anticipation: 'Anticipation',
	optimistic: 'Optimistic',
	anxious: 'Anxious',
	fear: 'Fear',
	despair: 'Despair',
	disbelief: 'Disbelief',
	cynicism: 'Cynicism',
	regret: 'Regret',
	disgust: 'Disgust',
	annoyed: 'Annoyed',
	aggressive: 'Aggressive',
	shocked: 'Shocked',
	choose: 'Choose {{amount}}',
	'up-to': 'Choose up to {{amount}}',
	unlimited: "Choose as many as you'd like",
	'single-select': 'Choose one',
	number: 'Enter a number',
	'number-between-min-max': 'Enter a number between {{min}} and {{max}}',
	'number-min': 'Enter a number {{min}} or greater',
	'number-max': 'Enter a number {{max}} or less',
	'open-ended-pledge':
		'This survey contains questions where you will be asked to answer in your own words. Your responses will be automatically checked to make sure they meet our quality requirements. Do you agree to continue? ',
	'open-ended-pledge-accept': 'Yes, I agree',
	'open-ended-pledge-decline': 'No, I disagree',
	'rh-1-label': 'Which of the following is not a sport?',
	'rh-1-o-1': 'Basketball',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Cookie',
	'rh-1-o-4': 'Hockey',
	'rh-2-label': 'What is 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Which of the following is a fruit?',
	'rh-3-o-1': 'Milk',
	'rh-3-o-2': 'Chocolate',
	'rh-3-o-3': 'Egg',
	'rh-3-o-4': 'Apple',
	'open-end-max-length': 'Sorry, entered answer is too long',
	'heatmap-click-image': 'Click on the image to add a comment',
	'heatmap-click-image-no-comments': 'Click anywhere on the image',
	'heatmap-click-image-product-card': "Click where you'd like to leave a comment",
	'heatmap-click-image-product-card--no-comment': 'Click anywhere on the card',
	'heatmap-type-comment': 'Type a comment',
	cancel: 'Cancel',
	skip: 'Skip',
	ranked: 'Ranked',
	top: 'Top',
	'tap-or-drag': 'Tap or drag & drop to rank',
	unranked: 'Unranked',
	reset: 'Reset',
	'no-more-options': 'No more options to rank',
	'rank-list-full': 'Rank list is full, unrank one of your choices',
};
