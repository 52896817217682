import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";

import "./styles.scss";

const FooterContext = React.createContext();

const RenderTo = ({ container, children }) => ReactDOM.createPortal(children, container);

export default ({ children }) => (
	<FooterContext.Consumer>
		{(value) => value ? (<RenderTo container={value}>{children}</RenderTo>) : null}
	</FooterContext.Consumer>
);

export const FooterProvider = ({ children }) => {
	const footerRef = useRef(null);
	const forceUpdate = useState(1)[1];
	useEffect(() => forceUpdate(), []);

	return (
		<React.Fragment>
			<FooterContext.Provider value={footerRef && footerRef.current}>
				{children}
			</FooterContext.Provider>

			<div ref={footerRef} className="footer-helper" />
		</React.Fragment>
	);
};
